/* globals VXConfig */

'use strict';

/*
 * !!! DO NOT CHANGE !!!
 * This file is being generated by GenerateRoutesApp.php
 */

import {urlWithQuery} from './CommonUtils';

const Routes = (function() {
	const language    = VXConfig.language;
	const cachingTag  = VXConfig.cachingTag;

	const Names = {
		ASSET_LESS_BUILDER_FILE: 'AssetLessBuilderFile',
		ASSET_LEGAL_TEXT_DOWNLOAD: 'AssetLegalTextDownload',
		VX_PAY_ON_LOGIN_SUCCESS: 'VXPayOnLoginSuccess',
		VX_PAY_LOGOUT: 'VXPayLogout',
		ACTOR_TV_LIVESTREAM_DATA_BY_ID: 'ActorTVLivestreamDataById',
		ACTOR_DATA_BY_ID_OR_NAME: 'ActorDataByIdOrName',
		ACTOR_STATE_BY_ID: 'ActorStateById',
		ACTOR_NOTE_BY_ID: 'ActorNoteById',
		ACTOR_NOTE_STORE: 'ActorNoteStore',
		ACTOR_PREVIEW_IMAGE: 'ActorPreviewImage',
		ACTOR_SEARCH_INIT: 'ActorSearchInit',
		ACTOR_SEARCH_DO_SEARCH: 'ActorSearchDoSearch',
		ACTOR_SEARCH_DELETE_FILTER: 'ActorSearchDeleteFilter',
		GUEST_PROFILE_GET: 'GuestProfileGet',
		GUEST_PROFILE_UPDATE: 'GuestProfileUpdate',
		GUEST_PROFILE_PREVIEW_UPDATE: 'GuestProfilePreviewUpdate',
		GUEST_REDEEM_BONUS: 'GuestRedeemBonus',
		GUEST_ACCEPT_CANCELLATION_OFFER: 'GuestAcceptCancellationOffer',
		GUEST_NOTIFICATIONS_GET_SETTINGS: 'GuestNotificationsGetSettings',
		NEWSLETTER_SUBSCRIBE: 'NewsletterSubscribe',
		NEWSLETTER_UNSUBSCRIBE: 'NewsletterUnsubscribe',
		GUEST_AUTO_RECHARGE_TEASER_STATE: 'GuestAutoRechargeTeaserState',
		GUEST_CLOSE_DATA_PROTECTION: 'GuestCloseDataProtection',
		GUEST_CLOSE_AGB_AND_DATA_PROTECTION: 'GuestCloseAgbAndDataProtection',
		GUEST_GET_BALANCE: 'GuestGetBalance',
		GUEST_SEND_VIDEO_SHARE_EMAIL: 'GuestSendVideoShareEmail',
		GUEST_FAVORITE_ACTOR_IDS: 'GuestFavoriteActorIds',
		GUEST_CONVERSION_CHAT: 'GuestConversionChat',
		GUEST_SAVE_PREFERENCES: 'GuestSavePreferences',
		MESSENGER_GET_CHANNEL: 'MessengerGetChannel',
		MESSENGER_GET_CHANNEL_BY_ACTOR: 'MessengerGetChannelByActor',
		MESSENGER_GET_RECOMMENDATIONS: 'MessengerGetRecommendations',
		MESSENGER_GET_ACTOR: 'MessengerGetActor',
		ALBUM_BUY: 'AlbumBuy',
		GALLERY: 'Gallery',
		GALLERY_GET_PROMOTED_VIDEO: 'GalleryGetPromotedVideo',
		GALLERY_GET_SEDCARDS_BY_FSK: 'GalleryGetSedcardsByFsk',
		GALLERY_GET_SEDCARDS: 'GalleryGetSedcards',
		GALLERY_SET_SEDCARDS_GUEST_RATING: 'GallerySetSedcardsGuestRating',
		GALLERY_PRIVATE_SHOP_GET_COMMENTS: 'GalleryPrivateShopGetComments',
		GALLERY_PRIVATE_SHOP_POST_COMMENT: 'GalleryPrivateShopPostComment',
		GALLERY_PRIVATE_SHOP_SET_GUEST_RATING: 'GalleryPrivateShopSetGuestRating',
		GALLERY_SET_PICTURE_GUEST_RATING: 'GallerySetPictureGuestRating',
		GALLERY_GET_PINNED_PHOTOS: 'GalleryGetPinnedPhotos',
		GALLERY_GET_TOP_VIDEOS: 'GalleryGetTopVideos',
		GALLERY_GET_PREVIEW: 'GalleryGetPreview',
		SEARCH_RESULT_SEARCH: 'SearchResultSearch',
		PHOENIX_PROFILE_PREVIEW: 'PhoenixProfilePreview',
		SALES_ACTOR_GET: 'SalesActorGet',
		INTERNAL_INC_AUTO_INCREMENTOR: 'InternalIncAutoIncrementor',
		INTERNAL_CLEAR_DI_CACHE: 'InternalClearDiCache',
		INTERNAL_ACTOR_IMPORT_FULL: 'InternalActorImportFull',
		INTERNAL_ALBUM_IMPORT: 'InternalAlbumImport',
		GRANT_MESSENGER_GIFTS: 'GrantMessengerGifts',
		INTERNAL_ONLINE_GUESTS: 'InternalOnlineGuests',
		TILE_GRID: 'TileGrid',
		TILE_GRID_GET_VIDEO_PICTURES: 'TileGridGetVideoPictures',
		TILE_GRID_STORE_TILE_GRID_SETTINGS: 'TileGridStoreTileGridSettings',
		CONVERSION_GET_BUY_IMAGE_ALBUM_INCENTIVES: 'ConversionGetBuyImageAlbumIncentives',
		CONVERSION_GET_INCENTIVES_BY_EVENT: 'ConversionGetIncentivesByEvent',
		CONVERSION_GET_PAGE_LOAD_INCENTIVES: 'ConversionGetPageLoadIncentives',
		CONVERSION_GET_VIDEO_PREVIEW_END_INCENTIVES: 'ConversionGetVideoPreviewEndIncentives',
		CONVERSION_SET_GUEST_INFO: 'ConversionSetGuestInfo',
		CONVERSION_SET_REFILL_WINDOW_HIDDEN: 'ConversionSetRefillWindowHidden',
		CONVERSION_CLOSE_INCENTIVE: 'ConversionCloseIncentive',
		TEASER: 'Teaser',
		SURVEY_FEEDBACK_DATA: 'SurveyFeedbackData',
		SURVEY_FEEDBACK: 'SurveyFeedback',
		SURVEY_CHAT_EXIT: 'SurveyChatExit',
		LOGGER_BROWSER_ONLINE: 'LoggerBrowserOnline',
		LOGGER_WEBSOCKET_CONNECTION_ERROR: 'LoggerWebsocketConnectionError',
		LOGGER_VX_MESSENGER_ERROR: 'LoggerVXMessengerError',
		TRACKER_ACTOR_PROFILE_VISIT: 'TrackerActorProfileVisit',
		TRACKER_TRACK: 'TrackerTrack',
		WATCH_LIST: 'WatchList',
		SLOT_MACHINE_GET_REWARD: 'SlotMachineGetReward',
		HALLOWEEN_GET_REWARD: 'HalloweenGetReward',
		REWARD_BOX_GET_REWARD: 'RewardBoxGetReward',
		WIESN_SHOOTING_GET_REWARD: 'WiesnShootingGetReward',
		WET_AND_WILD_GET_REWARD: 'WetAndWildGetReward',
		WIESN_BUDE_GET_REWARD: 'WiesnBudeGetReward',
		KICK_IT_GET_REWARD: 'KickItGetReward',
		SPECIALS_EVENTS: 'SpecialsEvents',
		ADVENT_CALENDAR: 'AdventCalendar',
		MAGAZINE_MAGAZINES_OVERVIEW: 'MagazineMagazinesOverview',
		MAGAZINE_MAGAZINES_DETAILS: 'MagazineMagazinesDetails',
		EASTER_COLLECT_EGG: 'EasterCollectEgg',
		SPECIAL_EVENT_DATA: 'SpecialEventData',
		TV_UPDATE_MEDIATHEK: 'TVUpdateMediathek',
		TV_FORMAT_DATA: 'TVFormatData',
		TV_POST_DATA: 'TVPostData',
		TV_PROGRAM_DATA: 'TVProgramData',
		TV_STREAM_DATA: 'TVStreamData',
		TV_VIDEO_DATA: 'TVVideoData',
		TV_VIDEO_SUGGEST: 'TVVideoSuggest',
		CONTESTS_ADD: 'ContestsAdd',
		CONTESTS_DELETE: 'ContestsDelete',
		CONTESTS_GET_CONTEST: 'ContestsGetContest',
		CONTESTS_GET_MEDIA_DETAILS: 'ContestsGetMediaDetails',
		CONTESTS_GET_TOP_LIST: 'ContestsGetTopList',
		CONTESTS_GET_CONTEST_HEAD: 'ContestsGetContestHead',
		CONTESTS_GET_CONTEST_GRID_ITEMS: 'ContestsGetContestGridItems',
		MONITORING_ACTORS: 'MonitoringActors',
		BLOG_PM: 'BlogPm',
		BLOG_ARCHIVES: 'BlogArchives',
		BLOG: 'Blog',
		BLOG_ACTORS: 'BlogActors',
		WEB_PUSH_KEY: 'WebPushKey',
		WEB_PUSH_STATUS: 'WebPushStatus',
		WEB_PUSH_SUBSCRIBE: 'WebPushSubscribe',
		WEB_PUSH_RE_SUBSCRIBE: 'WebPushReSubscribe',
		WEB_PUSH_UNSUBSCRIBE: 'WebPushUnsubscribe',
		WEB_PUSH_SET_GUEST_ID: 'WebPushSetGuestId',
		BREADCRUMB: 'Breadcrumb',
		UTILITY_GET_GEO_LOCATION_BY_ZIP_CODE: 'UtilityGetGeoLocationByZipCode',
		ADD_RELATION: 'addRelation',
		HAS_RELATION: 'hasRelation',
		JUMBOTRON_CONFIG: 'JumbotronConfig',
		JUMBOTRON_CONFIG_HOMEPAGE: 'JumbotronConfigHomepage',
		PROMO_HAS_NOT_REDEEMED_PROMO_CODE_INSTANCE: 'PromoHasNotRedeemedPromoCodeInstance',
		TEST_LEAPWORK_RESULT: 'TestLeapworkResult',
		TEST_LEAPWORK_VIDEO: 'TestLeapworkVideo',
		TEST_REQUEST_OFFICE_IP: 'TestRequestOfficeIp',
		TEST_REQUEST_SECURE_REMOTE_IP: 'TestRequestSecureRemoteIp',
		TEST_PLA: 'TestPLA',
		ALIVE: 'Alive',
		ACTOR_SEO_CONTENT: 'ActorSeoContent'
,
	};

	const Urls = {
		AssetLessBuilderFile: '/rest/v1/asset/less/file/:cachingTag/:filename',
		AssetLegalTextDownload: '/rest/v1/asset/legal-text/download/:legalTextId',
		VXPayOnLoginSuccess: '/rest/v1/vxpay/onsuccess',
		VXPayLogout: '/rest/v1/vxpay/logout',
		ActorTVLivestreamDataById: '/rest/v1/actor/getActorTVLivestreamDataById/:actorId',
		ActorDataByIdOrName: '/rest/v1/actor/getActorDataByIdOrName/:actorIdOrName',
		ActorStateById: '/rest/v1/actor/getActorStateById/:actorId',
		ActorNoteById: '/rest/v1/actor/getActorNoteById/:actorId',
		ActorNoteStore: '/rest/v1/actor/storeActorNote',
		ActorPreviewImage: '/rest/v1/actor/getActorPreviewImage/:actorIdOrName',
		ActorSearchInit: '/rest/v1/actorsearch/init',
		ActorSearchDoSearch: '/rest/v1/actorsearch/search',
		ActorSearchDeleteFilter: '/rest/v1/actorsearch/filter/delete',
		GuestProfileGet: '/rest/v1/guest/profile/get',
		GuestProfileUpdate: '/rest/v1/guest/profile/update',
		GuestProfilePreviewUpdate: '/rest/v1/guest/profile/preview/update',
		GuestRedeemBonus: '/rest/v1/guest/redeemBonus',
		GuestAcceptCancellationOffer: '/rest/v1/guest/acceptCancellationOffer',
		GuestNotificationsGetSettings: '/rest/v1/guest/getNotificationsSettings',
		NewsletterSubscribe: '/rest/v1/guest/newsletter/subscribe',
		NewsletterUnsubscribe: '/rest/v1/guest/newsletter/unsubscribe',
		GuestAutoRechargeTeaserState: '/rest/v1/guest/autoRechargeTeaserState',
		GuestCloseDataProtection: '/rest/v1/guest/closeDataProtection',
		GuestCloseAgbAndDataProtection: '/rest/v1/guest/closeAgbAndDataProtection',
		GuestGetBalance: '/rest/v1/guest/getGuestBalance',
		GuestSendVideoShareEmail: '/rest/v1/guest/sendVideoShareEmail',
		GuestFavoriteActorIds: '/rest/v1/guest/favorite/actors',
		GuestConversionChat: '/rest/v1/guest/conversionChat',
		GuestSavePreferences: '/rest/v1/guest/savePreferences',
		MessengerGetChannel: '/rest/v1/messenger/channel/:channelId',
		MessengerGetChannelByActor: '/rest/v1/messenger/channel/actor/:actorId',
		MessengerGetRecommendations: '/rest/v1/messenger/recommendations',
		MessengerGetActor: '/rest/v1/messenger/actor/:actorId',
		AlbumBuy: '/rest/v1/album/:albumId/buy',
		Gallery: '/rest/v1/gallery/open',
		GalleryGetPromotedVideo: '/rest/v1/gallery/getpromotedvideo/:actorId',
		GalleryGetSedcardsByFsk: '/rest/v1/gallery/getsedcards/:actorIdOrName/:payload',
		GalleryGetSedcards: '/rest/v1/gallery/getsedcards/:actorIdOrName',
		GallerySetSedcardsGuestRating: '/rest/v1/gallery/setSedcardsGuestRating/:actorId',
		GalleryPrivateShopGetComments: '/rest/v1/gallery/getComments',
		GalleryPrivateShopPostComment: '/rest/v1/gallery/postComment',
		GalleryPrivateShopSetGuestRating: '/rest/v1/gallery/setGuestRating',
		GallerySetPictureGuestRating: '/rest/v1/gallery/setPictureGuestRating',
		GalleryGetPinnedPhotos: '/rest/v1/gallery/getPinnedPhotos',
		GalleryGetTopVideos: '/rest/v1/gallery/getTopVideos/:actorIdOrName',
		GalleryGetPreview: '/rest/v1/gallery/getPreview',
		SearchResultSearch: '/rest/v1/searchResult/search/:language/:searchPattern',
		PhoenixProfilePreview: '/rest/v1/phoenix/get_profile_preview/:actorId',
		SalesActorGet: '/rest/v1/sales/actor/:actorId',
		InternalIncAutoIncrementor: '/rest/v1/internal/regenerate_auto_incrementor',
		InternalClearDiCache: '/rest/v1/internal/clear_di_cache',
		InternalActorImportFull: '/rest/v1/internal/actor_import_full',
		InternalAlbumImport: '/rest/v1/internal/album_import',
		GrantMessengerGifts: '/rest/v1/internal/grantMessengerGifts',
		InternalOnlineGuests: '/rest/v1/internal/onlineGuests',
		TileGrid: '/rest/v1/tileGrid/get/:type/:payload',
		TileGridGetVideoPictures: '/rest/v1/tileGrid/getVideoPictures/:albumId',
		TileGridStoreTileGridSettings: '/rest/v1/tileGrid/storeTileGridSettings',
		ConversionGetBuyImageAlbumIncentives: '/rest/v1/conversion/getBuyImageAlbumIncentives/:albumId',
		ConversionGetIncentivesByEvent: '/rest/v1/conversion/getIncentivesByEvent',
		ConversionGetPageLoadIncentives: '/rest/v1/conversion/getPageLoadIncentives',
		ConversionGetVideoPreviewEndIncentives: '/rest/v1/conversion/getVideoPreviewEndIncentives/:albumId',
		ConversionSetGuestInfo: '/rest/v1/conversion/setGuestInfo',
		ConversionSetRefillWindowHidden: '/rest/v1/conversion/setRefillWindowHidden',
		ConversionCloseIncentive: '/rest/v1/conversion/closeIncentive',
		Teaser: '/rest/v1/teaser/getTeasers',
		SurveyFeedbackData: '/rest/v1/survey/feedbackdata',
		SurveyFeedback: '/rest/v1/survey/feedback',
		SurveyChatExit: '/rest/v1/survey/chatexit',
		LoggerBrowserOnline: '/rest/v1/logger/browserOnline',
		LoggerWebsocketConnectionError: '/rest/v1/logger/websocketConnectionError',
		LoggerVXMessengerError: '/rest/v1/logger/vxmessengerError',
		TrackerActorProfileVisit: '/rest/v1/tracker/trackActorProfileVisit',
		TrackerTrack: '/rest/v1/tracker/track',
		WatchList: '/rest/v1/watch/start',
		SlotMachineGetReward: '/rest/v1/special/slotmachine/getReward/:eventId',
		HalloweenGetReward: '/rest/v1/special/halloween/getReward/:eventId',
		RewardBoxGetReward: '/rest/v1/special/reward-box/getReward/:eventId',
		WiesnShootingGetReward: '/rest/v1/special/wiesn-shooting/getReward/:eventId',
		WetAndWildGetReward: '/rest/v1/special/wet-and-wild/getReward/:eventId',
		WiesnBudeGetReward: '/rest/v1/special/wiesn-bude/getReward/:eventId',
		KickItGetReward: '/rest/v1/special/kickIt/getReward/:eventId',
		SpecialsEvents: '/rest/v1/special/get/events',
		AdventCalendar: '/rest/v1/special/adventcalendar',
		MagazineMagazinesOverview: '/rest/v1/special/magazines/:page',
		MagazineMagazinesDetails: '/rest/v1/special/magazines/random/:excludeId',
		EasterCollectEgg: '/rest/v1/special/easter/collectEgg',
		SpecialEventData: '/rest/v1/special/getData/:eventType',
		TVUpdateMediathek: '/rest/v1/tv/updateMediathek',
		TVFormatData: '/rest/v1/tv/format/:key',
		TVPostData: '/rest/v1/tv/article/:slug',
		TVProgramData: '/rest/v1/tv/program/:type',
		TVStreamData: '/rest/v1/tv/stream/:type',
		TVVideoData: '/rest/v1/tv/video/:videoId',
		TVVideoSuggest: '/rest/v1/tv/suggest/:hashId',
		ContestsAdd: '/rest/v1/contests/addContestMediaVoting',
		ContestsDelete: '/rest/v1/contests/deleteContestMediaVoting',
		ContestsGetContest: '/rest/v1/contests/:contestId',
		ContestsGetMediaDetails: '/rest/v1/contests/:contestId/actor/:actorId',
		ContestsGetTopList: '/rest/v1/contests/:contestId/topList',
		ContestsGetContestHead: '/rest/v1/contests/:contestId/getHeader',
		ContestsGetContestGridItems: '/rest/v1/contests/getContestGridItems/:contestId/:contestSub/:limit/:offset',
		MonitoringActors: '/rest/v1/monitoringActors/onlineActorsAmount',
		BlogPm: '/rest/v1/blog/pm',
		BlogArchives: '/rest/v1/blog/archives/:category',
		Blog: '/rest/v1/blog/posts/:categoryId/:payload',
		BlogActors: '/rest/v1/blog/actors/:payload',
		WebPushKey: '/rest/v1/web-push/key',
		WebPushStatus: '/rest/v1/web-push/status/:payload',
		WebPushSubscribe: '/rest/v1/web-push/subscribe',
		WebPushReSubscribe: '/rest/v1/web-push/resubscribe/:token',
		WebPushUnsubscribe: '/rest/v1/web-push/unsubscribe/:payload',
		WebPushSetGuestId: '/rest/v1/web-push/update/:payload',
		Breadcrumb: '/rest/v1/breadcrumb/:breadcrumbType',
		UtilityGetGeoLocationByZipCode: '/rest/v1/utility/getGeoLocationByZip/:zipCode',
		addRelation: '/rest/v1/utility/relation/addRelation',
		hasRelation: '/rest/v1/utility/relation/hasRelation',
		JumbotronConfig: '/rest/v1/video/jumbotron/config',
		JumbotronConfigHomepage: '/rest/v1/video/jumbotron/homepage',
		PromoHasNotRedeemedPromoCodeInstance: '/rest/v1/promo/hasGuestNotRedeemedOnTopCodeInstance/:promoCode',
		TestLeapworkResult: '/rest/v1/test/leapwork/result',
		TestLeapworkVideo: '/rest/v1/test/leapwork/video/:runItemId',
		TestRequestOfficeIp: '/rest/v1/test/request/isOfficeIp',
		TestRequestSecureRemoteIp: '/rest/v1/test/request/isSecureRemoteIp',
		TestPLA: '/rest/v1/test/testPLA/:action',
		Alive: '/rest/v1/alive',
		ActorSeoContent: '/rest/v1/seo/getActorSeoContent/:actorId/:tagGroup'
,
	};

	/**
	 * @param name      Name of the route.
	 * @param routeArgs Object containing all route arguments.
	 * @param params    Object containing other url parameters to append.
	 *
	 * @return string
	 */
	function getRoute(name, routeArgs, params) {
		let url = Urls[name];

		params            = params || {};
		params.language   = language;
		params.ver        = cachingTag;

		if (VXConfig.accessToken && VXConfig.accessToken.length > 0) {
			params.access_token = VXConfig.accessToken;
		}

		if (url) {
			if (routeArgs) {
				for (const arg in routeArgs) {
					if (routeArgs.hasOwnProperty(arg)) {
						url = url.replace(':' + arg, routeArgs[arg]);
					}
				}
			}

			url = urlWithQuery(url, params);
		}

		return url;
	}

	return {
		Names,
		getRoute,
	};
})();

export default Routes;