'use strict';
/* globals VXConfig, VXPay */

import Flux             from '../Flux';
import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import SockJS           from "sockjs-client";
import {trackPurchase}  from '../../utils/DataLayerHelper';
import EnumGA4Events    from '../../utils/EnumGA4Events';

const ActionTypes = Constants.ActionTypes.Notification;
const ApiTypes    = Constants.ApiTypes.Notification;

const ChangeEvents = {
	notification:         'notification',
	navbarNotifications:  'navbarNotifications',
	floatingNotifications: 'floatingNotifications',
	favorite:             'favorite',
	clashOfCamsChallenge: 'clashOfCamsChallenge',
//	liveReaction:            'liveReaction',
	messsengerContentChange: 'messsengerContentChange',
};

let socket                  = null;
let connectionAttempts      = 0;
let lastLoadChannelsRequest = 0;
let pingInterval            = null;
const pingIntervalTime      = 50000; // ping every 50 seconds to keep alive connection cache which expires after 60 seconds
const queue                 = [];
let that                    = null;
let socketUrl               = null;
let pongExpected            = false;

/*
 * Navbar Notifications
 */

const navbarNotificationsData = {
	initialized:   false, // lazy loading
	notifications: {},
};

function requestNavbarNotifications() {
	navbarNotificationsData.initialized = true;
	if (Flux.Guest.isLoggedIn()) {
		NotificationStore.send(
			ApiTypes.Outgoing.REQUEST_NAVBAR_NOTIFICATIONS,
			{
				newest: 5,
			}
		);
	}
}

/**
 * @param notifications
 * @param removedIds
 * @param markedAsReadIds
 */
function updateNavbarNotifications(notifications, removedIds, markedAsReadIds) {
	let i;
	const list  = navbarNotificationsData.notifications;
	let changed = false;

	// add new notifications unsorted but duplicates removed
	for (i = 0; i < notifications.length; i++) {
		changed       = true;
		const item    = notifications[i];
		list[item.id] = item;
	}

	// remove notifications
	for (i = 0; i < removedIds.length; i++) {
		changed = true;
		delete list[removedIds[i]];
	}

	// mark notifications as read
	for (i = 0; i < markedAsReadIds.length; i++) {
		const found = markNavbarNotificationAsRead(markedAsReadIds[i]);
		if (found) {
			changed = true;
		}
	}

	if (changed) {
		navbarNotificationsData.notifications = list;
		NotificationStore.emit(ChangeEvents.navbarNotifications);
	}
}

function markNavbarNotificationAsRead(notificationId) {
	let found = false;

	if (Object.hasOwn(navbarNotificationsData.notifications, notificationId) && navbarNotificationsData.notifications[notificationId].unread) {
		found = true;

		// change notifications
		navbarNotificationsData.notifications[notificationId].unread = false;
	}

	return found;
}

function markNavbarNotificationAsSeen(notificationId) {
	let found = false;

	if (Object.hasOwn(navbarNotificationsData.notifications, notificationId) && navbarNotificationsData.notifications[notificationId].unseen) {
		found = true;

		// change notifications
		navbarNotificationsData.notifications[notificationId].unseen = false;
	}

	return found;
}

function markAllNavbarNotificationsAsSeen() {
	const notificationsIds = [];

	for (const notificationId in navbarNotificationsData.notifications) {
		if (Object.hasOwn(navbarNotificationsData.notifications, notificationId) && navbarNotificationsData.notifications[notificationId].unseen) {
			notificationsIds.push(notificationId);
			navbarNotificationsData.notifications[notificationId].unseen = false;
		}
	}

	return notificationsIds;
}

function markAllNavbarNotificationsAsRead() {
	const notificationsIds = [];

	for (const notificationId in navbarNotificationsData.notifications) {
		if (Object.hasOwn(navbarNotificationsData.notifications, notificationId) && navbarNotificationsData.notifications[notificationId].unread) {
			notificationsIds.push(notificationId);
			navbarNotificationsData.notifications[notificationId].unread = false;
		}
	}

	return notificationsIds;
}


/*
 * Floating Notifications
 */

const floatingNotificationsData = {
	initialized:   false, // lazy loading
	notifications: {},
};

function requestFloatingNotifications() {
	floatingNotificationsData.initialized = true;
	if (Flux.Guest.isLoggedIn()) {
		NotificationStore.send(
			ApiTypes.Outgoing.REQUEST_FLOATING_NOTIFICATIONS,
			{
				newest: 5,
			}
		);
	}
}

/**
 * @param notifications
 * @param removedIds
 * @param markedAsReadIds
 */
function updateFloatingNotifications(notifications, removedIds, markedAsReadIds) {
	let i;
	const list  = floatingNotificationsData.notifications;
	let changed = false;

	// add new notifications unsorted but duplicates removed
	for (i = 0; i < notifications.length; i++) {
		changed       = true;
		const item    = notifications[i];
		list[item.id] = item;
	}

	// remove notifications
	for (i = 0; i < removedIds.length; i++) {
		changed = true;
		delete list[removedIds[i]];
	}

	// mark notifications as read
	for (i = 0; i < markedAsReadIds.length; i++) {
		const found = markFloatingNotificationAsRead(markedAsReadIds[i]);
		if (found) {
			changed = true;
		}
	}

	if (changed) {
		floatingNotificationsData.notifications = list;
		NotificationStore.emit(ChangeEvents.floatingNotifications);
	}
}

function markFloatingNotificationAsRead(notificationId) {
	let found = false;

	if (Object.hasOwn(floatingNotificationsData.notifications, notificationId) && floatingNotificationsData.notifications[notificationId].unread) {
		found = true;

		// change notifications
		floatingNotificationsData.notifications[notificationId].unread = false;
	}

	return found;
}

function markFloatingNotificationAsSeen(notificationId) {
	let found = false;

	if (Object.hasOwn(floatingNotificationsData.notifications, notificationId) && floatingNotificationsData.notifications[notificationId].unseen) {
		found = true;

		// change notifications
		floatingNotificationsData.notifications[notificationId].unseen = false;
	}

	return found;
}

/*
 * Store
 */

const NotificationStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * init
	 */
	init: function(url) {
		that      = this;
		socketUrl = url;

		if (url) {
			this.connect();
		}
	},

	addNotificationChangeListener: function(callback) {
		this.on(ChangeEvents.notification, callback);
	},

	removeNotificationChangeListener: function(callback) {
		this.removeListener(ChangeEvents.notification, callback);
	},

	addClashOfCamsChallengeListener: function(callback) {
		this.on(ChangeEvents.clashOfCamsChallenge, callback);
	},

	removeClashOfCamsChallengeListener: function(callback) {
		this.removeListener(ChangeEvents.clashOfCamsChallenge, callback);
	},

	addMessengerContentChangeListener: function(callback) {
		this.on(ChangeEvents.messsengerContentChange, callback);
	},

	removeMessengerContentChangeListener: function(callback) {
		this.removeListener(ChangeEvents.messsengerContentChange, callback);
	},

	connect: function() {
		connectionAttempts++;

		socket           = new SockJS(socketUrl);
		socket.onopen    = that.onSocketOpen;
		socket.onmessage = that.onSocketData;
		socket.onclose   = that.onSocketClose;
	},

	onSocketOpen: function() {
		that.send(ApiTypes.Outgoing.REQUEST_SESSION, {
			language: VXConfig.language,
		});

		pingInterval = setInterval(that.onSendPing, pingIntervalTime);
	},

	onSocketData: function(event) {
		const data = JSON.parse(event.data);
		let payload;
		switch (data.type) {
			case ApiTypes.Incoming.SESSION_ACKNOWLEDGE :
				payload = null;
				while ((payload = queue.shift()) !== undefined) {
					socket.send(payload);
				}
				break;
			case ApiTypes.Incoming.VOICECALL_START :
				if (Flux.Guest.isLoggedIn()) {
					Flux.Guest.setIsVoicecallActive(true);
				}
				if (data.forceReload) {
					window.location.reload();
				}
				break;
			case ApiTypes.Incoming.VOICECALL_STOP :
				if (Flux.Guest.isLoggedIn()) {
					Flux.Guest.setIsVoicecallActive(false);
				}
				break;
			case ApiTypes.Incoming.WELCOME_BONUS_REDEEMED :
				if (data.bonusRedeemed) {
					Flux.Guest.setHasWelcomeBonusRedeemed();
				}
				break;
			case ApiTypes.Incoming.DATA_PROTECTION :
				Flux.Guest.setShowDataProtection(data.showDataProtection);
				break;
			case ApiTypes.Incoming.GUEST_BALANCE :
				Flux.Guest.setBalanceFormatted(data.guestBalance, data.guestBalanceFormatted, data.guestIsPayingCustomer);
				break;
			case ApiTypes.Incoming.GUEST_VOUCHERS :
				Flux.Guest.setVouchers(data.vouchers);
				break;
			case ApiTypes.Incoming.AVS_REVOKED :
				Flux.Guest.setHasAvs(false);
				Flux.Guest.setAvsStatus(Constants.AvsStatus.UNKNOWN);
				break;
			case ApiTypes.Incoming.AVS_SET :
				Flux.Guest.setHasAvs(true);
				Flux.Guest.setAvsStatus(Constants.AvsStatus.ACCEPTED);
				break;
			case ApiTypes.Incoming.AVS_PENDING :
				Flux.Guest.setAvsStatus(Constants.AvsStatus.PENDING);
				break;
			case ApiTypes.Incoming.AVS_REJECTED :
				Flux.Guest.setAvsStatus(Constants.AvsStatus.REJECTED);
				Flux.Guest.setAvsRejectMessage(data.message);
				break;
			case ApiTypes.Incoming.GUEST_LOCKED :
				Flux.Guest.setIsLocked(true);
				break;
			case ApiTypes.Incoming.VIP_ABO_UPDATE :
				Flux.Guest.setIsVip(data.vipAboStatus);
				Flux.Guest.setIsVXTVAboSubscriber(data.vxtvAboStatus);

				if (data.isStarted) {
					trackPurchase({
						[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: Flux.Guest.getId(),
						[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          data.price,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [
							{
								[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:     EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_ABO,
								[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY]: EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_VIP,
							},
						],
					});
				}
				break;
			case ApiTypes.Incoming.VXTV_ABO_UPDATE :
				Flux.Guest.setIsVXTVAboSubscriber(data.vxtvAboStatus);

				if (data.isStarted) {
					trackPurchase({
						[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: Flux.Guest.getId(),
						[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          data.price,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [
							{
								[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:     EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_ABO,
								[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY]: EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_TV,
							},
						],
					});
				}
				break;
			case ApiTypes.Incoming.CAN_ONECLICK_RESULT:
				Flux.Guest.setCanOneClick(
					data.result ? data.config : null,
					data.result ? data.amounts : [],
					data.result && data.autoRechargeAllowed ? data.autoRechargeConfig : null,
					data.result && data.autoRechargeAllowed ? data.autoRechargeAmount : 0,
					data.isPaying
				);
				break;
			case ApiTypes.Incoming.NAVBAR_NOTIFICATIONS :
				updateNavbarNotifications(data.notifications, data.removedIds, data.markedAsReadIds);
				break;
			case ApiTypes.Incoming.FLOATING_NOTIFICATIONS :
				updateFloatingNotifications(data.notifications, data.removedIds, data.markedAsReadIds);
				break;
			case ApiTypes.Incoming.SESSION_EXPIRED :
				if (!VXPay.waitForFeedback) {
					window.location.reload(true);
				}
				break;
			case ApiTypes.Incoming.FAVORITE_ACTOR_PINNED:
			case ApiTypes.Incoming.FAVORITE_ACTOR_UNPINNED:
			case ApiTypes.Incoming.FAVORITE_PICTURE_PINNED:
			case ApiTypes.Incoming.FAVORITE_PICTURE_UNPINNED:
			case ApiTypes.Incoming.FAVORITE_ALBUM_PINNED:
			case ApiTypes.Incoming.FAVORITE_ALBUM_UNPINNED:
			case ApiTypes.Incoming.FAVORITE_SEDCARDS_ALBUM_PINNED:
			case ApiTypes.Incoming.FAVORITE_SEDCARDS_ALBUM_UNPINNED:
			case ApiTypes.Incoming.FAVORITE_ACTORS:
				that.emit(ChangeEvents.favorite, data);
				break;
			case ApiTypes.Incoming.ACTOR_OFFLINE:
			case ApiTypes.Incoming.ACTOR_ONLINE:
				that.emit(ChangeEvents.favorite, data);
				Flux.ProSearch.setActorOnlineState(data.actorId, data.type === ApiTypes.Incoming.ACTOR_ONLINE);
				Flux.Special.setActorOnlineState(data.actorId, data.type === ApiTypes.Incoming.ACTOR_ONLINE);
				Flux.Actor.emitActorOnlineStateChange(data.actorId, data.type === ApiTypes.Incoming.ACTOR_ONLINE);
				break;
			case ApiTypes.Incoming.ALL_CHANNELS_SEEN:
				Flux.Messenger.setAllMessagesRead();
				break;
			case ApiTypes.Incoming.PONG:
				pongExpected = false;
				Flux.Browser.updateExpireStamp(data.ttl);
				break;
			case ApiTypes.Incoming.PAGE_RELOAD:
				location.reload();
				break;
			case ApiTypes.Incoming.ON_GUEST_EMAIL_VERIFIED:
				const route = VXConfig.routeName;
				if (VXConfig.isVXMobilePlatform !== true &&
					route.indexOf('TV/Livestream') === -1 &&
					route.indexOf('TV/Premium/Livestream') === -1) {
					location.reload();
				}
				break;
			case ApiTypes.Incoming.MESSENGER_BOUGHT_GIFTS_UPDATE:
				Flux.Guest.updateBoughtGiftsForGuest();
				break;
			case ApiTypes.Incoming.FREE_CHAT_DURATION_CHANGED:
				Flux.Guest.clearFreeChatDurations();
				break;
			case ApiTypes.Incoming.CLASH_OF_CAMS_VOTING:
				that.emit(ChangeEvents.clashOfCamsChallenge, data);
				break;
			case ApiTypes.Incoming.MESSENGER_CONTENT_CONTENT_CHANGE:
				that.emit(ChangeEvents.messsengerContentChange, data);
				break;
			case ApiTypes.Incoming.LIVE_REACTION:
				Flux.LiveReactions.handleLiveReaction(data);
				break;
			case ApiTypes.Incoming.PRIVATE_TICKET_SHOW_INVITATION:
				Flux.NewMessenger.handlePrivateTicketShowInvitation(data.actorName, data.actorProfilePictureUrl, data.actorId);
				break;
			case ApiTypes.Incoming.CHAT_END:
				let itemCategory = EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_LIVE_CHAT;

				switch (true) {
					case data.isVoyeur:
						itemCategory = EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_VOYEUR_CAM;
						break;
					case data.isToyControl:
						itemCategory = EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_TOY_CONTROL;
						break;
				}

				trackPurchase({
					[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: data.chatId,
					[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          data.price,
					[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [
						{
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_ID]:       data.actorId,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_BRAND]:    data.actorName,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:     EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_LIVE_CHAT,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY]: itemCategory
						}
					]
				});
				break;
			case ApiTypes.Incoming.LOYALTY_POINTS_CHANGED:
				Flux.Guest.updateLoyaltyPoints(data.points);
				break;
			default:
		}

		that.emit(ChangeEvents.notification, data);
	},

	onSocketClose: function(e) {

		// clean up
		clearInterval(pingInterval);
		socket = null;

		// code 1002: "Cannot connect to server" -> do NOT retry when it occurs on the first attempt
		if (!(e.code === Flux.Constants.Websocket.ErrorCodes.PROTOCOL_ERROR && connectionAttempts === 1)) {

			// log error
			Flux.Logger.logWebsocketConnectionError(
				Flux.Constants.Websocket.ErrorTypes.CONNECTION_CLOSED,
				Flux.Constants.Websocket.Types.BOOMER,
				e.code,
				e.reason,
				connectionAttempts
			);

			// retry connection
			setTimeout(function() {
				that.connect();
			}, 1000);

		}

	},

	send: function(type, payload) {
		payload             = payload || {};
		payload.type        = type;
		payload.accessToken = VXConfig.accessToken;
		payload.guestId     = VXConfig.guestId || '';
		payload             = JSON.stringify(payload);

		if (socket && socket.readyState === SockJS.OPEN) {
			socket.send(payload);
		} else {
			queue.push(payload);
		}
	},

	onSendPing: function() {

		// check pong
		if (pongExpected) {

			// pong is missing
			Flux.Logger.logWebsocketConnectionError(
				Flux.Constants.Websocket.ErrorTypes.PONG_IS_MISSING,
				Flux.Constants.Websocket.Types.BOOMER,
				Flux.Constants.Websocket.ErrorCodes.PONG_IS_MISSING,
				"Pong in Missing",
				connectionAttempts
			);

		} else {
			pongExpected = true;
		}

		that.send(ApiTypes.Outgoing.PING, {});
	},

	Favorite: {

		requestActors: function() {
			NotificationStore.send(ApiTypes.Outgoing.FAVORITE_REQUEST_ACTORS);
		},

		addCommandListener: function(callback) {
			NotificationStore.on(ChangeEvents.favorite, callback);
		},

		removeCommandListener: function(callback) {
			NotificationStore.removeListener(ChangeEvents.favorite, callback);
		},

	},

	/*
	 * Navbar Notifications
	 */

	addNavbarNotificationsChangeListener: function(callback) {
		this.on(ChangeEvents.navbarNotifications, callback);
		if (!navbarNotificationsData.initialized) {
			requestNavbarNotifications(); // lazy loading
		}
	},

	removeNavbarNotificationsChangeListener: function(callback) {
		this.removeListener(ChangeEvents.navbarNotifications, callback);
	},

	getNavbarNotifications: function() {
		const result = [];
		const keys   = [];
		let key;

		for (key in navbarNotificationsData.notifications) {
			if (Object.hasOwn(navbarNotificationsData.notifications, key)) {
				keys.push(key);
			}
		}
		keys.sort(function(a, b) {
			return b - a; // reverse order
		});
		keys.map(function(key) {
			result.push(navbarNotificationsData.notifications[key]);
		});

		return result;
	},

	markNavbarNotificationAsRead: function(notificationId) {
		const found = markNavbarNotificationAsRead(notificationId);

		if (found) {
			NotificationStore.emit(ChangeEvents.navbarNotifications);
		}

		return found;
	},

	markNavbarNotificationAsSeen: function(notificationId) {
		const found = markNavbarNotificationAsSeen(notificationId);

		if (found) {
			NotificationStore.emit(ChangeEvents.navbarNotifications);
		}

		return found;
	},

	markAllNavbarNotificationsAsSeen: function() {
		const notificationsIds = markAllNavbarNotificationsAsSeen();

		if (notificationsIds.length) {
			NotificationStore.emit(ChangeEvents.navbarNotifications);
		}

		return notificationsIds;
	},

	markAllNavbarNotificationsAsRead: function() {
		const notificationsIds = markAllNavbarNotificationsAsRead();

		if (notificationsIds.length) {
			NotificationStore.emit(ChangeEvents.navbarNotifications);
		}

		return notificationsIds;
	},

	/*
	 * Floating Notifications
	 */
	addFloatingNotificationsChangeListener: function(callback) {
		this.on(ChangeEvents.floatingNotifications, callback);
		if (!floatingNotificationsData.initialized) {
			requestFloatingNotifications(); // lazy loading
		}
	},

	removeFloatingNotificationsChangeListener: function(callback) {
		this.removeListener(ChangeEvents.floatingNotifications, callback);
	},

	getFloatingNotifications: function() {
		const result = [];
		const keys   = [];
		let key;

		for (key in floatingNotificationsData.notifications) {
			if (Object.hasOwn(floatingNotificationsData.notifications, key)) {
				keys.push(key);
			}
		}
		keys.sort(function(a, b) {
			return b - a; // reverse order
		});
		keys.map(function(key) {
			result.push(floatingNotificationsData.notifications[key]);
		});

		return result;
	},

	markFloatingNotificationAsRead: function(notificationId) {
		const found = markFloatingNotificationAsRead(notificationId);

		if (found) {
			NotificationStore.emit(ChangeEvents.floatingNotifications);
		}

		return found;
	},

	markFloatingNotificationAsSeen: function(notificationId) {
		const found = markFloatingNotificationAsSeen(notificationId);

		if (found) {
			NotificationStore.emit(ChangeEvents.floatingNotifications);
		}

		return found;
	},
});

function sendMessage(channelId, senderType, messageType, isVideochat, isFreechat, data, includedGifts, chatId) {
	NotificationStore.send(ApiTypes.Outgoing.SEND_MESSAGE, {
		channelId:     channelId,
		messageType:   messageType,
		senderType:    senderType,
		isVideochat:   isVideochat,
		isFreechat:    isFreechat,
		data:          data,
		includedGifts: includedGifts,
		chatId:        chatId,
	});
}

NotificationStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.SEND_MESSAGE :
			sendMessage(action.channelId, action.senderType, action.messageType, action.isVideochat, action.isFreechat, action.data, action.includedGifts, action.chatId);
			break;
		case ActionTypes.SET_ALL_CHANNELS_SEEN :
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.SET_ALL_CHANNELS_SEEN);
			}
			break;
		case ActionTypes.CAN_ONECLICK :
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.CAN_ONECLICK);
			}
			break;
		case ActionTypes.CHARGE_ONECLICK :
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.CHARGE_ONECLICK, {
					amount:    action.amount,
					channelId: action.channelId,
				});
			}
			break;
		case ActionTypes.MARK_NAVBAR_NOTIFICATION_AS_READ:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(
					ApiTypes.Outgoing.MARK_NAVBAR_NOTIFICATION_AS_READ,
					{
						notificationId: action.notificationId,
					}
				);
			}
			break;
		case ActionTypes.MARK_NAVBAR_NOTIFICATION_AS_SEEN:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(
					ApiTypes.Outgoing.MARK_NAVBAR_NOTIFICATION_AS_SEEN,
					{
						notificationId: action.notificationId,
					}
				);
			}
			break;
		case ActionTypes.MARK_FLOATING_NOTIFICATION_AS_READ:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(
					ApiTypes.Outgoing.MARK_FLOATING_NOTIFICATION_AS_READ,
					{
						notificationId: action.notificationId,
					}
				);
			}
			break;
		case ActionTypes.MARK_FLOATING_NOTIFICATION_AS_SEEN:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(
					ApiTypes.Outgoing.MARK_FLOATING_NOTIFICATION_AS_SEEN,
					{
						notificationId: action.notificationId,
					}
				);
			}
			break;
		case ActionTypes.REFRESH_EMAIL_PROPERTIES:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.REFRESH_EMAIL_PROPERTIES, {});
			}
			break;
		case ActionTypes.PASSWORD_CHANGED:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.PASSWORD_CHANGED, {});
			}
			break;
		case ActionTypes.SEND_SIGNUP_EMAIL:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.SEND_SIGNUP_EMAIL, {email: action.email});
			}
			break;
		case ActionTypes.FAVORITE_PIN_ACTOR:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_PIN_ACTOR, {actorId: action.actorId});
			}
			break;
		case ActionTypes.FAVORITE_UNPIN_ACTOR:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_UNPIN_ACTOR, {actorId: action.actorId});
			}
			break;
		case ActionTypes.FAVORITE_SET_PINNED_ACTOR_EMAIL_NOTIFICATION:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_SET_PINNED_ACTOR_EMAIL_NOTIFICATION, {
					actorId: action.actorId,
					enabled: action.enabled,
				});
			}
			break;
		case ActionTypes.FAVORITE_SET_PINNED_ACTOR_SMS_NOTIFICATION:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_SET_PINNED_ACTOR_SMS_NOTIFICATION, {
					actorId: action.actorId,
					enabled: action.enabled,
				});
			}
			break;
		case ActionTypes.FAVORITE_PIN_ALBUM:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_PIN_ALBUM, {albumId: action.albumId});
			}
			break;
		case ActionTypes.FAVORITE_UNPIN_ALBUM:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_UNPIN_ALBUM, {albumId: action.albumId});
			}
			break;
		case ActionTypes.FAVORITE_PIN_SEDCARDS:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_PIN_SEDCARDS, {actorId: action.actorId});
			}
			break;
		case ActionTypes.FAVORITE_UNPIN_SEDCARDS:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_UNPIN_SEDCARDS, {actorId: action.actorId});
			}
			break;
		case ActionTypes.FAVORITE_PIN_PICTURE:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_PIN_PICTURE, {pictureId: action.pictureId});
			}
			break;
		case ActionTypes.FAVORITE_UNPIN_PICTURE:
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FAVORITE_UNPIN_PICTURE, {pictureId: action.pictureId});
			}
			break;
		case ActionTypes.LOAD_MORE_CHANNELS :
			if (Flux.Guest.isLoggedIn() && lastLoadChannelsRequest < new Date().getTime() - 500) {
				lastLoadChannelsRequest = new Date().getTime();
				NotificationStore.send(
					ApiTypes.Outgoing.LOAD_CHANNELS,
					{
						offset: action.offset,
						limit:  action.limit,
					}
				);
			}
			break;
		case ActionTypes.LOAD_ONLINE_CHANNELS :
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.LOAD_ONLINE_CHANNELS, {
					online: action.online,
				});
			}
			break;
		case ActionTypes.FILTER_CHANNELS :
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.FILTER_CHANNELS, {
					filterType: action.filterType,
				});
			}
			break;
		case ActionTypes.ARCHIVE_CHANNEL :
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.ARCHIVE_CHANNEL, {
					channelId: action.channelId,
					status:    action.status,
				});
			}
			break;
		case ActionTypes.REMOVE_STATUS_BAR :
			if (Flux.Guest.isLoggedIn()) {
				NotificationStore.send(ApiTypes.Outgoing.REMOVE_STATUS_BAR);
			}
			break;
		default:
	}
});

export default NotificationStore;
