/*globals VXConfig*/

'use strict';

import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import ActionCreator    from "../actions/ActionCreator";
import Flux             from "../Flux";
import Dispatcher       from "../Dispatcher";
import Constants        from "../Constants";
import EnumGA4Events    from '../../utils/EnumGA4Events';
import {trackPurchase}  from '../../utils/DataLayerHelper';

const ActionTypes = Constants.ActionTypes;

const ChangeEvents = {
	show:                        'show',
	hide:                        'hide',
	chatExit:                    'chatExit',
	buyTicket:                   'buyTicket',
	privateTicketShowInvitation: 'privateTicketShowInvitation',
};

let _isChatExitOpen = false;


function setIsChatExitOpen(isOpen) {
	_isChatExitOpen = isOpen;

	MessengerStore.emitChatExitEvent(isOpen);
}

function buyChatTicket(ticket, creationType = 'C') {
	if (ticket) {
		Flux.Vxql.buyChatTicket(ticket, creationType, (result) => {

			if (result.success) {
				const {show, ticket} = result;

				trackPurchase({
					[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: ticket.id + ":" + show.id, // required, pseudo transaction id
					[EnumGA4Events.DEFAULT_PARAMETER_CURRENCY]:       show.price.currency,
					[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          show.price.value,
					[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [{
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_ID]:    ticket.id,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_BRAND]: show.actorName,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:  EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_CAM_SHOW_TICKET,
					}]
				});
			}

			MessengerStore.emitBuyTicketEvent(result);
		});
	}
}

function removeChatTicket(ticket) {
	if (ticket) {
		Flux.Vxql.removeChatTicket(ticket, (result) => {
			MessengerStore.emitBuyTicketEvent(result);
		});
	}
}

const MessengerStore = assign({}, FluxEventEmitter.prototype, {

	startChat:                   function(chatType, actorId) {
		const config = {
			chatType,
			actorId,
			mode: Flux.Constants.VXMessenger.Modes.WEBTOKEN,
		};

		// select channel to update sidebar
		ActionCreator.Messenger.selectChannelByActorId(actorId, true, false, false);

		this.emit(ChangeEvents.show, config);
	},
	startAnonymousVoicecallChat: function(actorId) {
		const onCreate = (result) => {
			const serverInfo = result.data.messenger.startAnonymousVoicecallChat;
			if (serverInfo && serverInfo.resultCode === 2000) {
				const config = {
					chatType:   Flux.Constants.VXMessenger.ChatTypes.LIVECHAT,
					actorId:    actorId,
					mode:       Flux.Constants.VXMessenger.Modes.SERVER,
					serverInfo: {server: serverInfo.server, clientId: serverInfo.clientId},
				};

				this.emit(ChangeEvents.show, config);
			} else if (serverInfo.resultCode) {
				this.emit(ChangeEvents.show, {
					errorCode: serverInfo.resultCode, actorId: actorId,
				});
			}
		};

		Flux.Vxql.startAnonymousVoicecallChat(onCreate, actorId, VXConfig.accessToken);
	},
	touchChannel:                function(actorId) {
		Flux.Vxql.touchChannel(actorId);
	},
	isChatExitOpen:              function() {
		return _isChatExitOpen;
	},
	addStartChatListener:        function(callback) {
		this.on(ChangeEvents.show, callback);
	},
	removeStartChatListener:     function(callback) {
		this.removeListener(ChangeEvents.show, callback);
	},

	stopChat:                                  function() {
		this.emit(ChangeEvents.hide, false);
	},
	addStopChatListener:                       function(callback) {
		this.on(ChangeEvents.hide, callback);
	},
	removeStopChatListener:                    function(callback) {
		this.removeListener(ChangeEvents.hide, callback);
	},
	addChatExitChangeListener:                 function(callback) {
		this.on(ChangeEvents.chatExit, callback);
	},
	removeChatExitChangeListener:              function(callback) {
		this.removeListener(ChangeEvents.chatExit, callback);
	},
	emitChatExitEvent:                         function(isOpen) {
		this.emit(ChangeEvents.chatExit, isOpen);
	},
	addChatTicketChangeListener:               function(callback) {
		this.on(ChangeEvents.buyTicket, callback);
	},
	removeChatTicketChangeListener:            function(callback) {
		this.removeListener(ChangeEvents.buyTicket, callback);
	},
	emitBuyTicketEvent:                        function(result) {
		this.emit(ChangeEvents.buyTicket, result);
	},
	addPrivateTicketShowInvitationListener:    function(callback) {
		this.on(ChangeEvents.privateTicketShowInvitation, callback);
	},
	removePrivateTicketShowInvitationListener: function(callback) {
		this.removeListener(ChangeEvents.privateTicketShowInvitation, callback);
	},
	handlePrivateTicketShowInvitation:         function(actorName, actorProfilePictureUrl, actorId) {
		this.emit(ChangeEvents.privateTicketShowInvitation, {actorName, actorProfilePictureUrl, actorId});
	}
});

MessengerStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.Messenger.SET_IS_CHAT_EXIT_OPEN:
			setIsChatExitOpen(action.isOpen);
			break;
		case ActionTypes.Messenger.BUY_CHAT_TICKET:
			buyChatTicket(action.ticket, action.creationType);
			break;
		case ActionTypes.Messenger.REMOVE_CHAT_TICKET:
			removeChatTicket(action.ticket);
			break;
		default:
			break;
	}
});


export default MessengerStore;
