/* global VXConfig */
import Constants        from '../Constants';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';

const CHANGE_EVENTS      = {
	ButtonData:         'buttonDataChange',
};

const BUTTON_CONFIG_TEMPLATE = {
    buttonSubline: null,
    buttonText: null,
    useStandaloneIcon: true,
    useLoader: true,
    isActorOnline: false,
    voucherChatMinutes: 0,
    cssModifier: '',
    actorId: null,
    actorLink: '',
    freeChat: false,
    isBannedCountryCode: false,
    isActorAvailableForConversionChat: false,
    isActorNotAvailableForConversionChat: false,
    isOnlineMobileVideocall: false,
    isCurrentlyTicketShow: false,
    isLivePreviewEnabled: false,
    isVoicecallActive: VXConfig.isVoicecallActive,
    isVoyeurChatAvailable: false,
    isVoyeurMode: false,
    guestHasTicket: false,
    loaderType: Constants.Loader.Types.LOGO,
    isVoyeurinSingleMode: false,
    multiChatPrice: 0,
    singleC2CChatPrice: 0,
    prices: {
        freeMessages: 0,
        message: 0,
        single: 0,
        singlec2c: 0,
        video: 0,
        voucherChatMinutes: 0,
        voyeur: 0,
    }
};

const _buttonData        = {};

const VideoChatbuttonStore = assign({}, FluxEventEmitter.prototype, {
    setButtonConfig: function(configs) {
        configs.map(config => {
            if (_buttonData[config.actorId]) {
                _buttonData[config.actorId] = {..._buttonData[config.actorId], ...config};
            } else {
                _buttonData[config.actorId] = {...BUTTON_CONFIG_TEMPLATE, ...config};
            }
        });
        this.emit(CHANGE_EVENTS.ButtonData);
    },
    getButtonConfigByActorId: function(actorId) {
        return _buttonData[actorId] || null;
    },
	addButtonChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ButtonData, callback);
	},
	removeButtonChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ButtonData, callback);
	},
});

export default VideoChatbuttonStore;

export {
    BUTTON_CONFIG_TEMPLATE
};
