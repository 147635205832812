import FluxEventEmitter from "../FluxEventEmitter";
import assign           from "object-assign";
import Flux             from "../Flux";

const data = {
	toyControls:                           [],
};

const ChangeEvents = {
	toyControlStoreInitialized:        'toyControlStoreInitialized',
};

const MessengerToyControlStore = assign({}, FluxEventEmitter.prototype, {
	init: function() {
		Flux.Vxql.getMessengerToyControls(function(response) {
			data.toyControls = response.data.messenger.toyControls;
			MessengerToyControlStore.emit(ChangeEvents.toyControlStoreInitialized);
		});
	},

	getToyControls: function() {
		return data.toyControls;
	},

	addToyControlStoreInitializedChangeListener: function(callback) {
		this.addListener(ChangeEvents.toyControlStoreInitialized, callback);
	},

	removeToyControlStoreInitializedChangeListener: function(callback) {
		this.removeListener(ChangeEvents.toyControlStoreInitialized, callback);
	},
});


export default MessengerToyControlStore;