import Dispatcher               from '../Dispatcher';
import FluxEventEmitter         from '../FluxEventEmitter';
import assign                   from 'object-assign';
import Flux                     from "../Flux";
import Constants                from '../Constants';



const ActorsActionTypes = Constants.ActionTypes.Actors;
const CHANGE_EVENTS      = {
	ActorsData:         'actorsDataChange',
	ActorsDataLoad:     'actorsDataLoad',
};

const _defaultFilter     = {gender: []};
const _actorsData        = {};
const _actorsDataLoading = {};

const ActorsStore = assign({}, FluxEventEmitter.prototype, {
	getActors: function(filter, order, preset) {
		let actors = null;
		const key  = getKey(filter, order, preset);
		if (_actorsData[key]) {
			actors = _actorsData[key]['items'] ? [..._actorsData[key]['items']] : null;
		}
		return actors;
	},
	getActorsTotalCount: function(filter, order, preset) {
		let total = null;
		const key = getKey(filter, order, preset);
		if (_actorsData[key]) {
			total = _actorsData[key]['total'] || null;
		}
		return total;
	},
	isActorsDataLoading: function(filter, order, preset) {
		const key = getKey(filter, order, preset);

		return _actorsDataLoading[key] || false;
	},
	addActorsDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorsData, callback);
	},
	removeActorsDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorsData, callback);
	},
	addActorsDataLoadListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorsDataLoad, callback);
	},
	removeActorsDataLoadListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorsDataLoad, callback);
	},
});

function getKey(filter, order, preset) {
	return JSON.stringify({...getShapedFilter(filter), order, preset}); //@TODO: hash the stringified object?
}

function getShapedFilter(filter) {
	// add missing filter values and sets defined order of key(so objects can be compared using stringify)
	return {..._defaultFilter, ...filter};
}

function loadActors(first, last, offset, order, filter, preset, id, isV3, onlineFilter, excludedIds = []) {
	filter    = isV3 ? JSON.parse(JSON.stringify(filter)) : getShapedFilter(filter);
	const key = getKey(filter, order, preset);
    if (isV3 && excludedIds.length > 0) {
        if (filter.and) {
            filter.and.push({not : {modelIds: {in: excludedIds}}});
        } else {
            filter.not = {modelIds: {in: excludedIds}};
        }
    }

	_actorsDataLoading[key] = true;
	Flux.Vxql.getModels(first, last, offset, order, filter, preset, isV3, onlineFilter).then(({data}) => {
		const newActorsData = isV3 ? data.models_v3 : data.models_v2;
		if (isV3 && onlineFilter && data.onlineTotal) {
			const onlineKey = getKey(onlineFilter, order, preset);
			_actorsData[onlineKey] = data.onlineTotal;
		}

		if (newActorsData) {
			let actorsData = _actorsData[key];

			if (!actorsData) {
				actorsData = {};
			}

			actorsData.total = newActorsData.total;

			let actors = actorsData.items;

			if (!actors) {
				actors          = [];
				actorsData.items = actors;
			}

			for (const [index, actor] of newActorsData.items.entries()) {
				actors[offset + index] = actor;
			}

			_actorsData[key] = {...actorsData};

			_actorsDataLoading[key] = false;
			ActorsStore.emit(CHANGE_EVENTS.ActorsDataLoad);
			ActorsStore.emit(CHANGE_EVENTS.ActorsData, id);
		}
	});
}

ActorsStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActorsActionTypes.LOAD_ACTORS:
			const {first, last, offset, order, filter, preset, id, isV3, onlineFilter, excludedIds} = action;
			loadActors(first, last, offset, order, filter, preset, id, isV3, onlineFilter, excludedIds);
			break;
	}
});

export default ActorsStore;
