'use strict';

import Routes           from '../../utils/Routes';
import Flux             from '../Flux';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import {doFetch}        from "../../utils/CommonUtils";



const ChangeEvents = {
	actor: 'actor',
};

let actor = {};

const options = {
	language: '',
};

function updateActor(actorId) {
	doFetch(Routes.getRoute(Routes.Names.MESSENGER_GET_ACTOR, {actorId: actorId}, {language: options.language}), null, Flux.Constants.HttpMethods.GET, true, {cache: 'no-cache'})
		.then(function(response) {
			actor = response.data;

			MessengerActorStore.emitActorChange();
		});
}

const MessengerActorStore = assign({}, FluxEventEmitter.prototype, {

	init: function(language) {
		options.language = language;

		Flux.Notification.addNotificationChangeListener(this.onNotificationChanged);
		Flux.Messenger.addChannelSelectedListener(this.onChannelSelected);

		// get potential preselected channel before initialization of store instance
		this.onChannelSelected();
	},

	addActorChangeListener: function(callback) {
		this.on(ChangeEvents.actor, callback);
	},

	removeActorChangeListener: function(callback) {
		this.removeListener(ChangeEvents.actor, callback);
	},

	emitActorChange: function() {
		this.emit(ChangeEvents.actor);
	},

	getActor: function() {
		return actor;
	},

	updateActor: function(actorId) {
		updateActor(actorId);
	},

	onNotificationChanged: function(notification) {
		const NotificationTypes = Flux.Constants.ApiTypes.Notification.Incoming;

		switch (notification.type) {
			case NotificationTypes.FAVORITE_ACTOR_PINNED :
				if (actor && actor.id === notification.actorId) {
					actor.isFavoriteActorOfGuest = true;
				}
				break;
			case NotificationTypes.FAVORITE_ACTOR_UNPINNED :
				if (actor && actor.id === notification.actorId) {
					actor.isFavoriteActorOfGuest = false;
				}
				break;
			case NotificationTypes.ACTOR_ONLINE:
				if (actor && actor.id === notification.actorId) {
					actor.isVoyeurInSingleChatAllowed = notification.isVoyeurInSingleChatAllowed;
					actor.isDildocontrolAvailable     = notification.isDildocontrolAvailable;
					MessengerActorStore.emitActorChange();
				}
			// no default
		}
	},

	onChannelSelected: function() {
		const channel = Flux.Messenger.getSelectedChannel();

		if (channel) {
			updateActor(channel.actorId);
		}
	},

});

export default MessengerActorStore;
