import assign           from 'object-assign';
import FluxEventEmitter from './../FluxEventEmitter';
import Routes           from './../../utils/Routes';
import Constants        from "../Constants";
import {doFetch}        from "../../utils/CommonUtils";

/** @global Promise */

const actors = [],
	fetching = [];

const BlogActorsStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @param {Number[]} ids
	 * @return {Object[]}
	 * @private
	 */
	_getActorsByIds: ids => actors.filter((a, idx) => ids.indexOf(idx) !== -1),

	/**
	 * @param {Number[]} ids
	 * @return {Promise<Array>}
	 */
	getActorsByIds: ids => {
		const missing = [];

		// collect missing ones
		Array.prototype.forEach.call(ids, id => {
			if (!actors[id]) {
				missing.push(id);
			}
		});

		// no need for request
		if (missing.length === 0) {
			return new Promise(resolve => {
				resolve(BlogActorsStore._getActorsByIds(ids));
			});
		}

		// fetch from server
		const route = Routes.getRoute(Routes.Names.BLOG_ACTORS, {payload: JSON.stringify({ids: missing})});

		// check actor by id for being already fetched
		for (let c = 0; c < missing.length; c++) {
			if (typeof fetching[missing[c]] !== "undefined") {
				return fetching[missing[c]]
					.then(() => BlogActorsStore._getActorsByIds(ids));
			}
		}

		// fetch
		const alreadyFetching = doFetch(route, null, Constants.HttpMethods.GET)
			// call in any case - OK or ERROR
			.then(fetched => Array.prototype.forEach.call(fetched.data, actor => actors[actor.id] = actor))
			// return the requested set
			.then(() => BlogActorsStore._getActorsByIds(ids))
			// remove lock
			.then(set => {
				for (let k = 0; k < set.length; k++) {
					delete fetching[k.id];
				}
				return set;
			});

		// remember which ID is fetched in witch promise
		for (let j = 0; j < missing.length; j++) {
			fetching[missing[j]] = alreadyFetching;
		}

		return alreadyFetching;
	},
});

export default BlogActorsStore;
