'use strict';

import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import {doFetch}        from "../../utils/CommonUtils";
import assign           from 'object-assign';
import Flux             from '../Flux';

const startTime = new Date();
const logQueue = [];

function doFetchWrapper(restUrl, payload) {
	const curDate = new Date();

	if (payload === null || typeof payload !== 'object') {
		payload = {};
	}

	payload.jsDiff = (curDate - startTime) / 1000;
	payload.jsTime = curDate.toISOString();

	const initObject = {
		method:      Flux.Constants.HttpMethods.POST,
		credentials: 'include',
		cache:       'no-cache',
		body:        payload,
		headers:     new Headers({
			'Content-Type': 'application/json',
		}),
	};

	if (Flux.Browser.isOnline()) {
		doFetch(restUrl, payload, Flux.Constants.HttpMethods.POST, true);
	} else {
		logQueue.push({url: restUrl, init: initObject});
	}
}

function logOnlineEvent() {
	doFetchWrapper(Routes.getRoute(Routes.Names.LOGGER_BROWSER_ONLINE), {
		status: Flux.Browser.isOnline(),
	});
}

function onOnlineChange() {
	if (Flux.Browser.isOnline()) {
		processLogQueue();
	} else {
		logOnlineEvent();
	}
}

function processLogQueue() {
	while (logQueue.length && Flux.Browser.isOnline()) {
		const logEntry = logQueue.shift();
		doFetch(logEntry.url, logEntry.init.body, logEntry.init.method, true, logEntry.init);
	}

	logOnlineEvent();
}

// directly access Store without ActionCreator to avoid dispatch in middle of dispatch
const LoggerStore = assign({}, FluxEventEmitter.prototype, {
	init: function() {
		Flux.Browser.addOnlineChangeListener(onOnlineChange);
	},

	logWebsocketConnectionError: function(error, websocketType, errorCode, errorReason, connectionAttempt) {
		doFetchWrapper(Routes.getRoute(Routes.Names.LOGGER_WEBSOCKET_CONNECTION_ERROR), {
			connectionAttempt: connectionAttempt,
			error:             error,
			errorCode:         errorCode,
			errorReason:       errorReason,
			websocketType:     websocketType,
		});
	},

	logVXMessengerError: function(message) {
		doFetchWrapper(Routes.getRoute(Routes.Names.LOGGER_VXMESSENGER_ERROR), {
			message
		});
	},
});

export default LoggerStore;
