/* global VXConfig */
'use strict';

import Flux from '../Flux';

class FooterLinksStore {
	static getSocialLinks() {
		const facebookLink  = 'https://www.facebook.com/visitxtv';
		const twitterLink   = 'https://twitter.com/VISITX_net/';
		const instagramLink = 'https://www.instagram.com/visitxworld';
		const snapchatLink  = 'https://www.snapchat.com/add/visitx.tv';
		const links         = {
			facebook:  facebookLink,
			twitter:   twitterLink,
			instagram: instagramLink,
			snapchat:  snapchatLink,
		};
		return links;
	}

	static getZendeskLink(language) {
		let zendesk = 'https://visitxbv.zendesk.com/hc/';
		switch (language.toUpperCase()) {
			case Flux.Constants.Languages.DE.toUpperCase():
			  zendesk += 'de';
			  break;
			case Flux.Constants.Languages.ES.toUpperCase():
			  zendesk += 'es';
			  break;
			default:
				zendesk += 'en-us';
		  }
		zendesk += Flux.Guest.isLoggedIn() ? '?name=' + encodeURIComponent(Flux.Guest.getName()) : '';
		return zendesk;
	}

	static getVxModelsLink(language) {
		const VxModels = `https://www.vxmodels.com/${language.toUpperCase() !== Flux.Constants.Languages.DE.toUpperCase() ? 'en/' : '' }?pfmr=1502`;
		return VxModels;
	}

	static getBecomeAVisitXModelLink(language) {
		return Flux.Browser.isSexole() ?  'https://infowebcam.com/' : FooterLinksStore.getVxModelsLink(language);
	}

	static getVxCashLink() {
		const VxCash = 'https://www.vxcash.net/?rid=0&pfm=' + Flux.VXMobile.getPlatform() + '&w=' + VXConfig.wmId + '&ws=' + VXConfig.wmSubref + '&track=Index';
		return VxCash;
	}

    static getReportContentLink(language) {
        let zendesk = '';
        switch (language.toUpperCase()) {
            case Flux.Constants.Languages.DE.toUpperCase():
                zendesk = 'https://visitxbv.zendesk.com/hc/de/articles/16118554285202-Inhalte-melden';
                break;
            default:
                zendesk = 'https://visitxbv.zendesk.com/hc/en-us/articles/16118554285202-Report-content';
        }
        zendesk += Flux.Guest.isLoggedIn() ? '?name=' + encodeURIComponent(Flux.Guest.getName()) : '';
        return zendesk;
    }
}


export default FooterLinksStore;
