import WebPushSubscriptionStore from './../flux/stores/WebPushSubscriptionStore';
import Flux                     from './../flux/Flux';

/**
 * eslint-env browser
 */

class WebPushHelper {
	/**
	 * @return {boolean}
	 */
	static hasServiceWorkerSupport() {
		return ('serviceWorker' in navigator);
	}

	/**
	 * @return {boolean}
	 */
	static hasPushManagerSupport() {
		return ('PushManager' in window);
	}

	/**
	 * @return {boolean}
	 */
	static hasNotificationSupport() {
		return ('showNotification' in ServiceWorkerRegistration.prototype);
	}

	/**
	 * @return {boolean}
	 */
	static isSupported() {
		return WebPushHelper.hasServiceWorkerSupport()
			&& WebPushHelper.hasPushManagerSupport()
			&& WebPushHelper.hasNotificationSupport();
	}

	/**
	 * @return {boolean}
	 */
	static areActionsSupported() {
		return ('actions' in Notification.prototype);
	}

	/**
	 * @param {String} workerName
	 * @return {Promise<ServiceWorkerRegistration>|Boolean}
	 */
	static registerWorker(workerName = WebPushHelper.WORKER_DESKTOP) {
		// check all goo before registering
		if (workerName.length === 0 || !WebPushHelper.hasServiceWorkerSupport()) {
			return false;
		}

		return navigator
			.serviceWorker
			.register(workerName, {scope: '/'});
	}

	/**
	 * @param {String} base64String
	 * @return {Uint8Array}
	 */
	static urlBase64ToUint8Array(base64String) {
		const padding     = '='.repeat((4 - base64String.length % 4) % 4);
		const base64      = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
		const rawData     = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}

		return outputArray;
	}

	/**
	 * @return {string}
	 */
	static getCurrentPermission() {
		if (!WebPushHelper.isSupported() || !('permission' in Notification)) {
			return Flux.Constants.WebPush.Permissions.DENIED;
		}

		return Notification.permission;
	}

	/**
	 * @return {boolean}
	 */
	static permissionsAllowedOrDefault() {
		return [
			Flux.Constants.WebPush.Permissions.DEFAULT,
			Flux.Constants.WebPush.Permissions.GRANTED,
		].indexOf(WebPushHelper.getCurrentPermission()) !== -1;
	}

	/**
	 * @return {Promise<Boolean>}
	 */
	static requestPermissions() {
		return new Promise((resolve, reject) => {
			if (!WebPushHelper.isSupported()) {
				return reject('Push messages not supported');
			}

			switch (WebPushHelper.getCurrentPermission()) {
				case Flux.Constants.WebPush.Permissions.DENIED:
					return reject('Push messages are blocked.');

				case Flux.Constants.WebPush.Permissions.GRANTED:
					return resolve(true);

				default:
					Notification.requestPermission(result => {
						if (result !== Flux.Constants.WebPush.Permissions.GRANTED) {
							// send GA event?
							reject(new Error('Bad permission result'));
						}

						// send GA event?
						resolve(true);
					});
			}
		});
	}

	/**
	 * @param {ServiceWorkerRegistration} registration
	 * @param {string} appPublicKey
	 * @return {Promise<PushSubscription>}
	 */
	static subscribeBrowser(registration, appPublicKey) {
		return registration.pushManager.subscribe({
			userVisibleOnly:      true,
			applicationServerKey: WebPushHelper.urlBase64ToUint8Array(appPublicKey.toString()),
		});
	}

	/**
	 *
	 * @param {PushSubscription} subscription
	 * @param {Object} events
	 * @return {Promise<Boolean>}
	 */
	static subscribeServer(subscription, events) {
		return WebPushSubscriptionStore.subscribe(subscription, events);
	}

	/**
	 * @return {string}
	 */
	static getSupportedEncoding() {
		return (window.PushManager.supportedContentEncodings || ['aesgcm'])[0];
	}

	/**
	 * Same as init, but not requesting permissions
	 *
	 * @param {String} worker
	 * @param {ServiceWorkerRegistration} registration
	 * @param {Object} events
	 */
	static initWithoutPermissions(
		worker = WebPushHelper.WORKER_DESKTOP,
		registration = null,
		events = WebPushSubscriptionStore.EVENTS_ALL
	) {
		let sub;

		return Promise.all([
			(null === registration) ? WebPushHelper.registerWorker(worker) : registration,
			WebPushSubscriptionStore.getKey(),
		])
			/**@param [{ServiceWorkerRegistration}, {String}] value */
			.then(value => WebPushHelper.subscribeBrowser(value[0], value[1]))
			/** @param {PushSubscription} subscription */
			.then(subscription => {
				sub = subscription;
				return WebPushSubscriptionStore.getStatus(subscription);
			})
			/** @param {Boolean} status **/
			.then(status => {
				if (false === status) {
					return WebPushHelper.subscribeServer(sub, events);
				}

				return status;
			});
	}

	/**
	 * @param {String} worker
	 * @param {Promise<ServiceWorkerRegistration>} registration - you can pass the registration if you already have one
	 * @param {Object} events
	 * @return {Promise<Boolean>}
	 *
	 * 1. Register worker
	 * 2. Request notification permissions
	 * 3. Check subscription status
	 * 4. Subscribe if not yet subscribed
	 * @note error will be suppressed
	 */
	static init(
		worker = WebPushHelper.WORKER_DESKTOP,
		registration = null,
		events = WebPushSubscriptionStore.EVENTS_ALL
	) {
		let sub;

		return Promise.all([
			(null === registration) ? WebPushHelper.registerWorker(worker) : registration,
			WebPushHelper.requestPermissions(),
			WebPushSubscriptionStore.getKey(),
		])
			/**
			 * @param {Array} subscription
			 * @example [
			 *  {ServiceWorkerRegistration} (registration),
			 *  {Boolean} (notification permissions granted),
			 *  {String} (appPublicKey)
			 * ]
			 */
			.then(value => {
				if (value[1]) {
					return WebPushHelper.subscribeBrowser(value[0], value[2]);
				}
			})
			/** @param {PushSubscription} subscription */
			.then(subscription => {
				sub = subscription;
				return WebPushSubscriptionStore.getStatus(subscription);
			})
			/** @param {Boolean} subscribed */
			.then(subscribed => {
				if (false === subscribed) {
					return WebPushSubscriptionStore.subscribe(sub, events);
				}

				return subscribed;
			});
	}
}

WebPushHelper.WORKER_DESKTOP = '/worker_desktop.min.js';
WebPushHelper.WORKER_MOBILE  = '/worker_mobile.min.js';

export default WebPushHelper;
