'use strict';

import Dispatcher from './../Dispatcher';
import Constants  from './../Constants';

const ActionTypes = Constants.ActionTypes.VXMobile;

const VXMobileActionCreator = {
	addContainerScrollListener:    function(containerId, contentId, callback) {
		Dispatcher.dispatch({
			type:        ActionTypes.ADD_CONTAINER_SCROLL_LISTENER,
			callback:    callback,
			containerId: containerId,
			contentId:   contentId,
		});
	},
	clickBeforeInstallPrompt:      function(install) {
		Dispatcher.dispatch({
			type:    ActionTypes.CLICK_BEFORE_INSTALL_PROMPT,
			install: install,
		});
	},
	closeMenu:                     function(menuId) {
		Dispatcher.dispatch({
			type:   ActionTypes.CLOSE_MENU,
			menuId: menuId,
		});
	},
	closeSubMenu:                  function(menuId, subMenuId) {
		Dispatcher.dispatch({
			type:      ActionTypes.CLOSE_SUBMENU,
			menuId:    menuId,
			subMenuId: subMenuId,
		});
	},
	disableBodyScroll:             function(triggerName) {
		Dispatcher.dispatch({
			type:        ActionTypes.DISABLE_BODY_SCROLL,
			triggerName: triggerName,
		});
	},
	enableBodyScroll:              function(triggerName) {
		Dispatcher.dispatch({
			type:        ActionTypes.ENABLE_BODY_SCROLL,
			triggerName: triggerName,
		});
	},
	hideNavbar:                    function(triggerName) {
		Dispatcher.dispatch({
			type:        ActionTypes.HIDE_NAVBAR,
			triggerName: triggerName,
		});
	},
	setContentScroll:               function(scrollPosition) {
		Dispatcher.dispatch({
			type:           ActionTypes.CONTENT_SCROLL,
			scrollPosition: scrollPosition,
		});
	},
	ignoreNextScroll:              function(containerId) {
		Dispatcher.dispatch({
			type:        ActionTypes.IGNORE_NEXT_SCROLL,
			containerId: containerId,
		});
	},
	removeContainerScrollListener: function(containerId, contentId) {
		Dispatcher.dispatch({
			type:        ActionTypes.REMOVE_CONTAINER_SCROLL_LISTENER,
			containerId: containerId,
			contentId:   contentId,
		});
	},
	restoreNavbarState:            function(path) {
		Dispatcher.dispatch({
			type: ActionTypes.RESTORE_NAVBAR_STATE,
			path: path,
		});
	},
	scrollPageTo:                  function(position) {
		Dispatcher.dispatch({
			type:     ActionTypes.SCROLL_PAGE_TO,
			position: position,
		});
	},
	setRestoreNavbarState:         function(restore) {
		Dispatcher.dispatch({
			type:    ActionTypes.SET_RESTORE_NAVBAR_STATE,
			restore: restore,
		});
	},
	setScrollToOnMount:            function(route, position) {
		Dispatcher.dispatch({
			type:     ActionTypes.SET_SCROLL_TO_ON_MOUNT,
			route:    route,
			position: position,
		});
	},
	showNavbar:                    function(triggerName, callback) {
		Dispatcher.dispatch({
			type:        ActionTypes.SHOW_NAVBAR,
			callback:    callback,
			triggerName: triggerName,
		});
	},
	storeNavbarState:              function(path) {
		Dispatcher.dispatch({
			type: ActionTypes.STORE_NAVBAR_STATE,
			path: path,
		});
	},
	toggleMenu:                    function(menuId) {
		Dispatcher.dispatch({
			type:   ActionTypes.TOGGLE_MENU,
			menuId: menuId,
		});
	},
	toggleSubMenu:                 function(menuId, subMenuId, isBlocking) {
		Dispatcher.dispatch({
			type:       ActionTypes.TOGGLE_SUBMENU,
			isBlocking: isBlocking,
			menuId:     menuId,
			subMenuId:  subMenuId,
		});
	},
	updateSubMenu:                 function(menuId, subMenuId, data) {
		Dispatcher.dispatch({
			type:      ActionTypes.UPDATE_SUBMENU,
			menuId:    menuId,
			subMenuId: subMenuId,
			data:      data,
		});
	},

	setRoute: function(routeName, routeArgs) {
		Dispatcher.dispatch({
			type:      ActionTypes.SET_ROUTE,
			routeName: routeName,
			routeArgs: routeArgs,
		});
	},

	Guest: {
		storeTileGridSettingsByType: function(type, settings) {
			Dispatcher.dispatch({
				type:         ActionTypes.GUEST_STORE_TILE_GRID_SETTINGS,
				tileGridType: type,
				settings:     settings,
			});
		},
	},
};

export default VXMobileActionCreator;