import assign                   from 'object-assign';
import FluxEventEmitter         from '../FluxEventEmitter';
import Dispatcher               from '../Dispatcher';
import {default as ActionTypes} from '../actions/Types/VideoJumbotronActionTypes';
import Routes                   from '../../utils/Routes';
import {doFetch}                from '../../utils/CommonUtils';
import Constants                from '../Constants';

const config = {
	[Constants.Video.Jumbotron.TYPE_HOMEPAGE]: null,
	[Constants.Video.Jumbotron.TYPE_VIDEO_STATION]: null,
};

const EVENTS = {
	JUMBOTRON_CONFIG_CHANGED: 'VideoJumbotronConfigChanged',
};

const VideoJumbotronStore = assign({}, FluxEventEmitter.prototype, {
	/**
	 * @param {Function} callback
	 */
	addConfigChangeListener:    function(callback) {
		this.on(EVENTS.JUMBOTRON_CONFIG_CHANGED, callback);
	},
	/**
	 * @param {Function} callback
	 */
	removeConfigChangeListener: function(callback) {
		this.removeListener(EVENTS.JUMBOTRON_CONFIG_CHANGED, callback);
	},
	/**
	 * @return {Object|null}
	 * @param {String} type
	 */
	getConfig:                  function(type = Constants.Video.Jumbotron.TYPE_VIDEO_STATION) {
		return config[type];
	},
	/**
	 * @param {String} type
	 */
	requestConfig:              function(type = Constants.Video.Jumbotron.TYPE_VIDEO_STATION) {
		// const route = Routes.getRoute(Routes.Names.VIDEOS);

		if (null !== config[type]) {
			return this.emit(EVENTS.JUMBOTRON_CONFIG_CHANGED, config[type], type);
		}
		const url = type === Constants.Video.Jumbotron.TYPE_HOMEPAGE ? Routes.getRoute(Routes.Names.JUMBOTRON_CONFIG_HOMEPAGE) : Routes.getRoute(Routes.Names.JUMBOTRON_CONFIG);
		doFetch(url, false, Constants.HttpMethods.GET, false, {headers: {'Cache-Control': 'max-age=60', 'Pragma': ''}})
			.then(response => {
				// save in cache
				config[type] = response;

				// trigger via event
				this.emit(EVENTS.JUMBOTRON_CONFIG_CHANGED, config[type], type);
			});
	},
});

VideoJumbotronStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.REQUEST_CONFIG:
			VideoJumbotronStore.requestConfig(action.configType);
			break;

		default:
		// ??
	}
});

export default VideoJumbotronStore;