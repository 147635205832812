import EnumGA4Events from './EnumGA4Events';

const dataLayerHelper = (...args) => {
	if (Array.isArray(window.dataLayer)) {
		window.dataLayer.push(...args);
	}
};

function trackLoginState(loginState) {
	dataLayerHelper({
		[EnumGA4Events.DEFAULT_PARAMETER_EVENT]:      EnumGA4Events.mapParamToEvent[EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE],
		[EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE]: loginState,
	});
}

function trackMoneycharge(data) {
	dataLayerHelper({
		[EnumGA4Events.DEFAULT_PARAMETER_EVENT]:      EnumGA4Events.mapParamToEvent[EnumGA4Events.CUSTOM_PARAMETER_MONEYCHARGE],
		...data
	});
}

function trackPurchase({transaction_id, currency = EnumGA4Events.DEFAULT_PARAMETER_CURRENCY__VALUE_EUR, value, items}) {
	dataLayerHelper({
		[EnumGA4Events.DEFAULT_PARAMETER_EVENT]:          EnumGA4Events.DEFAULT_EVENT_PURCHASE,
		[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: transaction_id, // required, pseudo transaction id
		[EnumGA4Events.DEFAULT_PARAMETER_CURRENCY]:       currency,
		[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          value,
		[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          items
	});
}

export {
	trackLoginState,
	trackPurchase,
	trackMoneycharge
};