import assign from "object-assign";
import FluxEventEmitter from "../FluxEventEmitter";
import Flux from "../Flux";

const ChangeEvents = {
	liveReaction: 'liveReaction',
};

const LiveReactionsStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 *
	 * @param callback
	 * @param {string} referenceType
	 * @param {number} referenceId
	 * @param {number} imageId
	 * @param {string|null} message
	 * @param {object|null} data
	 */
	addReaction: function(callback, referenceType, referenceId, imageId, message, data) {
		if (data) {
			data = JSON.stringify(data);
		}

		Flux.Vxql.addLiveReaction(callback, referenceType, referenceId, imageId, message, data);
	},

	handleLiveReaction: function(liveReaction) {
		this.emitLiveReactionChange(liveReaction);
		liveReaction.id = liveReaction.id + 1;
	},

	addLiveReactionListener: function(callback) {
		this.addListener(ChangeEvents.liveReaction, callback);
	},

	removeLiveReactionListener: function(callback) {
		this.removeListener(ChangeEvents.liveReaction, callback);
	},

	emitLiveReactionChange: function(liveReaction) {
		this.emit(ChangeEvents.liveReaction, liveReaction);
	},

});


export default LiveReactionsStore;