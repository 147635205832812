import Dispatcher               from '../Dispatcher';
import FluxEventEmitter         from '../FluxEventEmitter';
import Constants                from '../Constants';
import assign                   from 'object-assign';
import Flux                     from "../Flux";

const GalleriesActionTypes = Constants.ActionTypes.Galleries;
const CHANGE_EVENTS      = {
	GalleriesData: 'galleriesDataChange',
	GalleriesDataLoad: 'galleriesDataLoad',
};

const _galleriesData = {};
const _galleriesDataLoading = {};

const GalleriesStore = assign({}, FluxEventEmitter.prototype, {

	getGalleries: function(filter, sort) {
		let galleries = null;
		const key  = getKey(filter, sort);
		if (_galleriesData[key]) {
			galleries = _galleriesData[key]['items'] || null;
		}
		return galleries;
	},

	getGalleriesTotal: function(filter, sort) {
		let total = null;
		const key = getKey(filter, sort);
		if (_galleriesData[key]) {
			total = _galleriesData[key]['total'] || null;
		}
		return total;
	},

	isGalleriesDataLoading: function(filter, sort) {
		const key = getKey(filter, sort);
		return _galleriesDataLoading[key] || false;
	},

	addGalleriesDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.GalleriesData, callback);
	},

	removeGalleriesDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.GalleriesData, callback);
	},

	addGalleriesDataLoadListener: function(callback) {
		this.on(CHANGE_EVENTS.GalleriesDataLoad, callback);
	},

	removeGalleriesDataLoadListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.GalleriesDataLoad, callback);
	},

});

function getKey(filter, sort) {
	return JSON.stringify({filter, sort});
}

function loadGalleries(
	filter,
	sort,
	count,
	offset = 0,
    id,
	ignoreLoading = false
) {
	const key = getKey(filter, sort);

	_galleriesDataLoading[key] = true;
	!ignoreLoading && GalleriesStore.emit(CHANGE_EVENTS.GalleriesDataLoad);

    // try to load photo albums
    new Promise((resolve) => {
        Flux.Vxql.getPhotoAlbumsFromVXQL(filter, sort, count, offset).then((result) => {
            const gallery = result.data.galleries;
            if (gallery) {
                resolve(gallery);
            }
        });
    }).then((gallery) => {
        const newGalleries = gallery; //@TOOD: handle error

		if (newGalleries) {
			let galleriesData = _galleriesData[key];

			if (!galleriesData) {
				galleriesData = {};
			}


			galleriesData.total = newGalleries.total;

			let galleries = galleriesData.items;

			if (!galleries) {
				galleries          = {};
				galleriesData.items = galleries;
			}

			for (const [index, video] of newGalleries.items.entries()) {
				galleries[offset + index] = video;
			}

			_galleriesData[key] = galleriesData;

			_galleriesDataLoading[key] = false;


			!ignoreLoading && GalleriesStore.emit(CHANGE_EVENTS.GalleriesDataLoad);
			GalleriesStore.emit(CHANGE_EVENTS.GalleriesData, id);
		}
    });
}

GalleriesStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case GalleriesActionTypes.LOAD_GALLERY:
			loadGalleries(action.filter, action.sort, action.count, action.offset, action.id, action.ignoreLoading);
			break;

		default:
		// ??
	}
});

export default GalleriesStore;
