'use strict';

/**
 * Dictionary class
 * - contains an unordered list of items
 * - can return a sorted array of items
 */
class Dictionary {
	constructor() {
		this.items = {};
	}

	/**
	 * @param index
	 * @returns {string}
	 * @private
	 */
	static _key(index) {
		return `ch${index}`;
	}

	/**
	 * @returns {boolean}
	 */
	isEmpty() {
		return Object.keys(this.items).length === 0;
	}

	/**
	 * @param item
	 * @param index
	 */
	add(item, index) {
		const key       = Dictionary._key(index);
		this.items[key] = item;
	}

	/**
	 * @param index
	 * @returns {*}
	 */
	get(index) {
		const ret = this.items[Dictionary._key(index)];
		return ret ? ret : null;
	}

	/**
	 * @returns {{}|*}
	 */
	getAll() {
		return this.items;
	}

	/**
	 * @param prop
	 * @param value
	 * @returns {*}
	 */
	getBy(prop, value) {
		for (const key in this.items) {
			if (Object.hasOwn(this.items, key)
				&& this.items[key]
				&& this.items[key][prop] === value
			) {
				return this.items[key];
			}
		}

		return null;
	}

	/**
	 * @param index
	 */
	remove(index) {
		delete this.items[Dictionary._key(index)];
	}

	/**
	 * @param callback
	 */
	each(callback) {
		for (const key of Object.keys(this.items)) {
			if (this.items[key]) {
				callback(this.items[key]);
			}
		}
	}

	/**
	 * @param items
	 * @param byProperty
	 */
	populate(items, byProperty) {
		for (let i = 0; i < items.length; i++) {
			this.add(items[i], items[i][byProperty]);
		}
	}

	/**
	 * @param filter
	 * @returns {Array.<*>}
	 */
	getFilteredArray(filter) {
		return Object.keys(this.items).map((key) => {
			const item = this.items[key];
			return filter(item);
		}).filter(item => !!item);
	}

	/**
	 * @param filter
	 * @param sorter
	 * @returns {Array.<*>}
	 */
	getSortedArray(filter, sorter) {
		const arr = this.getFilteredArray(filter);
		sorter(arr);
		return arr;
	}

}

export default Dictionary;
