/* globals VXConfig */
import Constants        from "../Constants";
import FluxEventEmitter from "../FluxEventEmitter";
import assign           from "object-assign";
import Dispatcher       from "../Dispatcher";
import Flux             from "../Flux";

const ActionTypes = Constants.ActionTypes;

const data = {
	gifts:             null,
	selectedChannelId: 0,
};

let giftsInMessage          = [];
const countOfBoughtGifts    = [];
const tmpCountOfBoughtGifts = [];

const ChangeEvents = {
//	removeGiftFromMessage: 'removeGiftFromMessage',
	addGiftToMessage:      'addGiftToMessage',
	giftMessage:           'giftMessage',
	giftStoreInitialized:  'giftStoreInitialized',
};

/**
 *
 * @param giftId
 */
function addGiftToMessage(giftId) {
	giftId     = parseInt(giftId);
	const gift = getGiftById(giftId);

	if (gift) {
		removeGiftFromTmpBoughtGifts(giftId);
		giftsInMessage.push(gift);
		MessengerGiftStore.emitGiftMessageChange();
		MessengerGiftStore.emitAddGiftEvent(gift);
	}
}

/**
 * @param giftId
 */
function removeGiftFromMessage(giftId) {
	giftId     = parseInt(giftId);
	const gift = getGiftById(giftId);

	if (gift) {
		for (let i = 0; i < giftsInMessage.length; i++) {
			if (giftsInMessage[i].id === giftId) {
				giftsInMessage.splice(i, 1);
				addGiftToTmpBoughtGifts(giftId);
				MessengerGiftStore.emitGiftMessageChange();
				break;
			}
		}
	}
}

/**
 *
 * @param giftId
 */
function addGiftToTmpBoughtGifts(giftId) {
	if (tmpCountOfBoughtGifts[giftId] < countOfBoughtGifts[giftId] && getCountOfGiftsInMessage(giftId) < countOfBoughtGifts[giftId]) {
		tmpCountOfBoughtGifts[giftId]++;
	}
}

/**
 *
 * @param giftId
 */
function removeGiftFromTmpBoughtGifts(giftId) {
	if (tmpCountOfBoughtGifts[giftId] > 0) {
		tmpCountOfBoughtGifts[giftId]--;
	}
}

/**
 *
 * @param giftId
 * @returns {number}
 */
function getCountOfGiftsInMessage(giftId) {
	let result = 0;
	for (let i = 0; i < giftsInMessage.length; i++) {
		if (giftsInMessage[i].id === giftId) {
			result++;
		}
	}
	return result;
}

/**
 *
 * @returns {number}
 */
function getCurrentMessagePrice() {
	let price = 0.0;

	const boughtGifts   = Flux.Guest.getBoughtGifts();
	const includedGifts = giftsInMessage;

	// get copy to prevent altering Obj
	const boughtGiftsCopy = assign({}, boughtGifts);

	for (let i = 0; i < includedGifts.length; i++) {
		if (boughtGiftsCopy[includedGifts[i].id]) {
			boughtGiftsCopy[includedGifts[i].id]--;
			if (boughtGiftsCopy[includedGifts[i].id] === 0) {
				delete boughtGiftsCopy[includedGifts[i].id];
			}
		} else {
			price += includedGifts[i].price;
		}
	}

	return price;
}


/**
 *
 * @param gifts
 * @returns {Array}
 */
function getArrOfIdsFromGiftArr(gifts) {
	const arr = [];

	for (let i = 0; i < gifts.length; i++) {
		arr.push(gifts[i].id);
	}

	return arr;
}

/**
 *
 * @param gift
 * @returns {string}
 */
function getPlaceholderByGift(gift) {
	return gift.id + '_' + gift.name;
}

/**
 * @param id
 * @returns {*}
 */
function getGiftById(id) {
	const gifts  = data.gifts;
	const giftId = parseInt(id);

	return gifts[giftId] ? gifts[giftId] : null;
}

/**
 * @param id
 * @returns {*}
 */
function getGiftsByCategoryId(id) {
	const categoryId      = parseInt(id);
	const giftsInCategory = [];

	for (const key in data.gifts) {
		if (Object.hasOwn(data.gifts, key)) {
			const gift = data.gifts[key];

			if (gift.categoryId === categoryId) {
				giftsInCategory.push(gift);
			}
		}
	}

	return giftsInCategory;
}

/**
 *
 * @param giftId
 * @returns {number}
 */
function getTmpCountOfBoughtGifts(giftId) {
	giftId = parseInt(giftId);
	return tmpCountOfBoughtGifts[giftId];
}

/**
 *
 */
function initCountOfBoughtGifts() {
	const gifts       = data.gifts;
	const boughtGifts = Flux.Guest.getBoughtGifts();

	for (const key in gifts) {
		if (Object.hasOwn(gifts, key)) {
			const giftId = parseInt(gifts[key].id);

			if (boughtGifts[giftId]) {
				countOfBoughtGifts[giftId]    = boughtGifts[giftId];
				tmpCountOfBoughtGifts[giftId] = boughtGifts[giftId];
			} else {
				countOfBoughtGifts[giftId]    = 0;
				tmpCountOfBoughtGifts[giftId] = 0;
			}
		}
	}

}

function onChannelSelected() {
	const selectedChannelId = Flux.Messenger.getSelectedChannelId();

	if (selectedChannelId !== data.selectedChannelId) {
		data.selectedChannelId = selectedChannelId;
	}
}

const MessengerGiftStore = assign({}, FluxEventEmitter.prototype, {
	init: function() {
		Flux.Vxql.getMessengerGifts(function(response) {
			const gifts        = response.data.messenger.gifts;
			const indexedGifts = {};
			for (let i = 0; i < gifts.length; i++) {
				// fix vxql request to match old data structure
				const gift = gifts[i];
				if (typeof gift.price.value !== 'undefined') {
					gift.price = parseFloat(gift.price.value);
				}
				if (typeof gift.translations !== 'undefined') {
					for (let j = 0; j < gift.translations.length; j++) {
						const translation = gift.translations[j];
						if (translation.language === VXConfig.language) {
							gift.translations.name = translation.value;
						}
					}
				}
				indexedGifts[parseInt(gift.id)] = gift;
			}

			Flux.Messenger.addChannelSelectedListener(onChannelSelected);

			data.gifts = indexedGifts;

			MessengerGiftStore.emit(ChangeEvents.giftStoreInitialized);
		});
	},

	getCurrentMessagePrice: function() {
		return getCurrentMessagePrice();
	},

	getArrOfIdsFromGiftArr: function($gifts) {
		return getArrOfIdsFromGiftArr($gifts);
	},

	getPlaceHolderByGift: function(gift) {
		return getPlaceholderByGift(gift);
	},

	getGifts: function() {
		return data.gifts;
	},

	getGiftById: function(id) {
		return getGiftById(id);
	},

	getGiftsByCategoryId: function(id) {
		return getGiftsByCategoryId(id);
	},

	popGiftsInMessage: function() {
		const gifts    = giftsInMessage;
		giftsInMessage = [];
		initCountOfBoughtGifts();
		this.emit(ChangeEvents.giftMessage);
		return gifts;
	},

	getTmpCountOfBoughtGift: function(giftId) {
		return getTmpCountOfBoughtGifts(giftId);
	},

	addGiftStoreInitializedChangeListener: function(callback) {
		this.addListener(ChangeEvents.giftStoreInitialized, callback);
	},

	removeGiftStoreInitializedChangeListener: function(callback) {
		this.removeListener(ChangeEvents.giftStoreInitialized, callback);
	},

	addGiftMessageChangeListener: function(callback) {
		this.addListener(ChangeEvents.giftMessage, callback);
	},

	removeGiftMessageChangeListener: function(callback) {
		this.removeListener(ChangeEvents.giftMessage, callback);
	},

	emitAddGiftEvent: function(gift) {
		this.emit(ChangeEvents.addGiftToMessage, gift);
	},

	onBoughtGiftsUpdate: function() {
		initCountOfBoughtGifts();
	},

	emitGiftMessageChange: function() {
		this.emit(ChangeEvents.giftMessage);
	},
});

MessengerGiftStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.Messenger.ADD_GIFT_TO_MESSAGE :
			addGiftToMessage(action.giftId);
			break;
		case ActionTypes.Messenger.REMOVE_GIFT_FROM_MESSAGE :
			removeGiftFromMessage(action.giftId);
			break;
		default:
			break;
	}
});


export default MessengerGiftStore;