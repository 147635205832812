'use strict';

import Routes           from '../../utils/Routes';
import Dispatcher       from '../Dispatcher';
import Constants                                from '../Constants';
import {arrayUniqueById, doFetch, getUrlParams} from "../../utils/CommonUtils";
import FluxEventEmitter                         from '../FluxEventEmitter';
import assign           from 'object-assign';

const ProSearchActionTypes = Constants.ActionTypes.ProSearch;
const ProSearchTypes       = Constants.ActorSearch.SearchTypes;
const ProSearchSortTypes   = Constants.ActorSearch.SortTypes;

const CHANGE_EVENT                 = 'change';
const FIT_ZIP_BOUNDS_EVENT         = 'fitBounds';
const SEARCH_LOCATION_CHANGE_EVENT = 'searchLocationChange';
const ZIP_CHANGE_EVENT             = 'zipChange';

let lastSearchedZip   = null;
const zipGeoLocations = {};
let guestGeoLocation  = null;

const _state = {};

_state[ProSearchTypes.SEARCH_TYPE_DEFAULT] = {
	isInitialized:            false,
	activeCategory:           {},
	availableFilterList:      [],
	activeFilterList:         {},
	availableCollectionsList: [],
	// result list
	actorList:                [],
	currentPage:              1,
	maxPage:                  0,
	totalHits:                0,
	actorMapState:            null,
	// moodValue
	debounceTimer:            null,
	moodValue:                null,
	markers:                  null,
	// sort
	sortType:                 ProSearchSortTypes.SEARCH_SORT_TYPE_RANK,
	// requestId to differentiate requests
	requestId:                0,
};

_state[ProSearchTypes.SEARCH_TYPE_LOCATION] = {
	isInitialized:            false,
	activeCategory:           {},
	availableFilterList:      [],
	activeFilterList:         {},
	availableCollectionsList: [],
	// result list
	actorList:                [],
	currentPage:              1,
	maxPage:                  0,
	totalHits:                0,
	actorMapState:            null,
	// moodValue
	debounceTimer:            null,
	moodValue:                null,
	markers:                  null,
	// sort
	sortType:                 ProSearchSortTypes.SEARCH_SORT_TYPE_DISTANCE,
	// requestId to differentiate requests
	requestId:                0,
};

_state[ProSearchTypes.SEARCH_TYPE_CUSTOM] = {
	isInitialized:            false,
	activeCategory:           {},
	availableFilterList:      [],
	activeFilterList:         {},
	availableCollectionsList: [],
	// result list
	actorList:                [],
	currentPage:              1,
	maxPage:                  0,
	totalHits:                0,
	actorMapState:            null,
	// moodValue
	debounceTimer:            null,
	moodValue:                null,
	markers:                  null,
	// sort
	sortType:                 ProSearchSortTypes.SEARCH_SORT_TYPE_RANK,
	// requestId to differentiate requests
	requestId:                0,
};

_state[ProSearchTypes.SEARCH_TYPE_NEW_GIRLS] = {
	isInitialized:            false,
	activeCategory:           {},
	availableFilterList:      [],
	activeFilterList:         {},
	availableCollectionsList: [],
	// result list
	actorList:                [],
	currentPage:              1,
	maxPage:                  0,
	totalHits:                0,
	actorMapState:            null,
	// moodValue
	debounceTimer:            null,
	moodValue:                null,
	markers:                  null,
	// sort
	sortType:                 ProSearchSortTypes.SEARCH_SORT_TYPE_RANK,
	// requestId to differentiate requests
	requestId:                0,
};

const ProSearchMetaStore = assign({}, FluxEventEmitter.prototype, {

	init: function(
		activeCategory,
		activeFilterList,
		availableFilterList,
		actorList,
		currentPage,
		maxPage,
		totalHits,
		proSearchType
	) {
		const searchType = this.checkSearchType(proSearchType);

		_state[searchType].activeCategory      = activeCategory;
		if (Object.keys(_state[searchType].activeFilterList).length < 1) {
			_state[searchType].activeFilterList    = activeFilterList;
		}
		_state[searchType].availableFilterList = availableFilterList;
		if (Object.keys(_state[searchType].actorList).length < 1) {
			_state[searchType].actorList    = actorList;
		}
		_state[searchType].currentPage         = currentPage;
		_state[searchType].maxPage             = maxPage;
		_state[searchType].totalHits           = totalHits;
		_state[searchType].isInitialized       = true;

	},

	initMobile: function(proSearchType, availableCollections, availableFilters) {
		_state[proSearchType].availableCollectionsList = availableCollections;
		_state[proSearchType].availableFilterList      = availableFilters;
	},

	checkSearchType: function(value) {
		return [
			ProSearchTypes.SEARCH_TYPE_DEFAULT,
			ProSearchTypes.SEARCH_TYPE_LOCATION,
			ProSearchTypes.SEARCH_TYPE_CUSTOM,
			ProSearchTypes.SEARCH_TYPE_NEW_GIRLS,
		].indexOf(value) >= 0 ? value : ProSearchTypes.SEARCH_TYPE_DEFAULT;
	},

	addChangeListener: function(callback) {
		this.on(CHANGE_EVENT, callback);
	},

	removeChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENT, callback);
	},

	addFitZipBoundsListener: function(callback) {
		this.on(FIT_ZIP_BOUNDS_EVENT, callback);
	},

	removeFitZipBoundsListener: function(callback) {
		this.removeListener(FIT_ZIP_BOUNDS_EVENT, callback);
	},

	getActiveCategory: function(searchType) {
		return _state[this.checkSearchType(searchType)].activeCategory;
	},

	getAvailableFilterList: function(searchType) {
		return _state[this.checkSearchType(searchType)].availableFilterList;
	},

	getAvailableCollectionsList: function(searchType) {
		return _state[this.checkSearchType(searchType)].availableCollectionsList;
	},

	isCollectionListReady: function(searchType) {
		return _state[this.checkSearchType(searchType)].availableCollectionsList.length > 0;
	},

	getActiveFilterList: function(searchType) {
		return _state[this.checkSearchType(searchType)].activeFilterList;
	},

	getActiveSort: function(searchType) {
		return _state[this.checkSearchType(searchType)].sortType;
	},

	/**
	 * Return a new object to make store state immutable
	 *
	 * @param {String} searchType
	 * @return {Array}
	 */
	getActorList: function(searchType) {
		return [
			..._state[this.checkSearchType(searchType)].actorList,
		];
	},

	getMarkersList: function(searchType) {
		searchType = this.checkSearchType(searchType);
		return _state[searchType].markers ? _state[searchType].markers : [];
	},

	getCurrentPage: function(searchType) {
		return _state[this.checkSearchType(searchType)].currentPage;
	},

	getMaxPage: function(searchType) {
		return _state[this.checkSearchType(searchType)].maxPage;
	},

	getTotalHits: function(searchType) {
		return _state[this.checkSearchType(searchType)].totalHits;
	},

	getActorMapState: function(searchType) {
		return _state[this.checkSearchType(searchType)].actorMapState;
	},

	getIsInitialized: function(searchType) {
		return _state[this.checkSearchType(searchType)].isInitialized;
	},

	emitFitZipBounds: function() {
		this.emit(FIT_ZIP_BOUNDS_EVENT);
	},

	addSearchLocationChangeListener: function(callback) {
		this.on(SEARCH_LOCATION_CHANGE_EVENT, callback);
	},

	removeSearchLocationChangeListener: function(callback) {
		this.removeListener(SEARCH_LOCATION_CHANGE_EVENT, callback);
	},

	getLastSearchedZip: function() {
		return lastSearchedZip;
	},

	addZipFieldChangeListener(callback) {
		this.on(ZIP_CHANGE_EVENT, callback);
	},

	removeZipFieldChangeListener(callback) {
		this.removeListener(ZIP_CHANGE_EVENT, callback);
	},

	setSearchLocationToPrevious: function() {
		if (lastSearchedZip) {
			getGeoLocationByZip(lastSearchedZip);
		} else {
			this.emit(SEARCH_LOCATION_CHANGE_EVENT, []);
		}
	},

	parseGeoLocation: function(geoLocation) {
		return parseGeoLocation(geoLocation);
	},

});

function doFetchWrapper(restUrl, payload, method, setCredentials = false, init = {}) {
	return doFetch(restUrl, payload, method, setCredentials, init);
}

function processResult(result, append = false, isInit = false) {
	const searchType = ProSearchMetaStore.checkSearchType(result.searchType);

	if (result.actorList !== undefined) {
		if (append) {
			_state[searchType].actorList = arrayUniqueById(_state[searchType].actorList.concat(result.actorList));
		} else {
			_state[searchType].actorList = result.actorList;
		}
	}
	if (result.availableFilterList !== undefined) {
		_state[searchType].availableFilterList = result.availableFilterList;
	}
	if (result.activeFilterList !== undefined) {
		_state[searchType].activeFilterList = result.activeFilterList;
	}
	if (result.currentCollection !== undefined) {
		_state[searchType].activeCategory = result.currentCollection;
	}
	if (result.totalHits !== undefined) {
		_state[searchType].totalHits = result.totalHits;
	}
	if (result.maxPage !== undefined) {
		_state[searchType].maxPage = result.maxPage;
	}
	if (result.currentPage !== undefined) {
		_state[searchType].currentPage = result.currentPage;
	}
	if (result.availableCollectionsList !== undefined) {
		_state[searchType].availableCollectionsList = result.availableCollectionsList;
	}
	if (result.markers !== undefined) {
		_state[searchType].markers = result.markers;
	} else {
		_state[searchType].markers = null;
	}
	ProSearchMetaStore.emit(CHANGE_EVENT, isInit);
}

// private setter
function updateInternalFilterList(changedFilter, searchType) {
	// clone filter list so changes are not done to the original filter list
	const activeFilterList = JSON.parse(JSON.stringify(_state[searchType].activeFilterList));

	if (changedFilter.checked === true) {
		if (activeFilterList[changedFilter.field]) {
			activeFilterList[changedFilter.field][changedFilter.value] = changedFilter.valueTranslation;
		} else {
			activeFilterList[changedFilter.field]                      = {};
			activeFilterList[changedFilter.field][changedFilter.value] = changedFilter.valueTranslation;
		}
	} else if (typeof activeFilterList[changedFilter.field] !== 'undefined' &&
		typeof activeFilterList[changedFilter.field][changedFilter.value] !== 'undefined'
	) {
		delete activeFilterList[changedFilter.field][changedFilter.value];
	}

	_state[searchType].activeFilterList = activeFilterList;

	ProSearchMetaStore.emit(CHANGE_EVENT, false);
}

function updateActorList(currentCollectionField, changedFilter, searchType, page, append = false, doSearch = true) {
	searchType = ProSearchMetaStore.checkSearchType(searchType);
	if (changedFilter) {
		updateInternalFilterList(changedFilter, searchType);
	}

	if (!doSearch || !_state[searchType].isInitialized) {
		return;
	}

	const params = {
		moodValue:  _state[searchType].moodValue,
		searchType: searchType,
		sortType:   _state[searchType].sortType,
		requestId:  ++_state[searchType].requestId,
	};

	if (lastSearchedZip !== null) {
		params.forcedGuestLocation = zipGeoLocations[parseInt(lastSearchedZip)];
		params.forcedGuestZip      = lastSearchedZip;
	} else if (guestGeoLocation !== null) {
		params.forcedGuestLocation       = guestGeoLocation;
		params.forcedGuestZip            = '';
		params.forcedGuestLocationSource = 'html5';
	}

	if (currentCollectionField) {
		params.currentCollection = currentCollectionField;
	} else {
		params.searchConditions = _state[searchType].activeFilterList;
	}
	if (changedFilter) {
		params.changedFilter = changedFilter;
	}

	doFetchWrapper(Routes.getRoute(Routes.Names.ACTOR_SEARCH_DO_SEARCH, null, {page}),
		params,
		Constants.HttpMethods.POST, true).then((result) => {
		// only process result if it is the result from the last request
		if (result.requestId === _state[searchType].requestId) {
			processResult(result, append);
		}
	});
}

/**
 * @param {Number} category
 * @param {String} searchType
 * @param {Function} callBack
 * @param {String} sortType
 */
function init(category, searchType, callBack, sortType = ProSearchSortTypes.SEARCH_SORT_TYPE_RANK) {
	const params = {};
	searchType   = ProSearchMetaStore.checkSearchType(searchType);

	if (category) {
		params.currentCollection = category;
	}
	if (searchType) {
		params.searchType = searchType;
	}
	if (sortType) {
		params.sortType = sortType;
	}

	if (!_state[searchType].isInitialized) {

		const qmDate = typeof getUrlParams(window.location.href).qmdate !== 'undefined' ? getUrlParams(window.location.href).qmdate : null;
		let route;
		if (qmDate) {
			route = Routes.getRoute(Routes.Names.ACTOR_SEARCH_INIT, null, {qmdate: qmDate});
		} else {
			route = Routes.getRoute(Routes.Names.ACTOR_SEARCH_INIT);
		}

		doFetchWrapper(
			route,
			params,
			Constants.HttpMethods.POST,
			true
		)
			.then((result) => {
				_state[searchType].isInitialized = true;
				processResult(result, false, true);
				if (typeof callBack === 'function') {
					callBack();
				}
			});
	}
}

function setActorOnlineState(actorId, state) {
	let hasChanged = false;
	for (let i = 0; i < _state[ProSearchTypes.SEARCH_TYPE_DEFAULT].actorList.length; i++) {
		if (_state[ProSearchTypes.SEARCH_TYPE_DEFAULT].actorList[i].actorId === actorId) {
			_state[ProSearchTypes.SEARCH_TYPE_DEFAULT].actorList[i].isOnline = state;
			hasChanged                                                       = true;
		}
	}
	for (let i = 0; i < _state[ProSearchTypes.SEARCH_TYPE_LOCATION].actorList.length; i++) {
		if (_state[ProSearchTypes.SEARCH_TYPE_LOCATION].actorList[i].actorId === actorId) {
			_state[ProSearchTypes.SEARCH_TYPE_LOCATION].actorList[i].isOnline = state;
			hasChanged                                                        = true;
		}
	}

	if (hasChanged) {
		ProSearchMetaStore.emit(CHANGE_EVENT, false);
	}
}

function setActorMapState(state, searchType) {
	_state[ProSearchMetaStore.checkSearchType(searchType)].actorMapState = state;
}

function setSortType(sortType, searchType) {
	_state[ProSearchMetaStore.checkSearchType(searchType)].sortType = sortType;
}

function deleteFilter(searchType) {
	const params = {
		searchConditions: _state[ProSearchMetaStore.checkSearchType(searchType)].activeFilterList,
	};

	doFetchWrapper(Routes.getRoute(Routes.Names.ACTOR_SEARCH_DELETE_FILTER), params, Constants.HttpMethods.POST, true).then((result) => {
		processResult(result);
	});
}

function setSearchLocationByZip(zipCode) {
	if (zipCode.length === 5) {
		getGeoLocationByZip(zipCode);
	}
}

function setLastSearchedZip(zipCode) {
	if (lastSearchedZip === null) {
		lastSearchedZip = zipCode;
		ProSearchMetaStore.emit(ZIP_CHANGE_EVENT, zipCode);
	}
}

function setGuestGeoLocation(geoLocation) {
	guestGeoLocation = parseGeoLocation(geoLocation);
}

function getGeoLocationByZip(zipCode) {
	const key       = parseInt(zipCode);
	lastSearchedZip = zipCode;

	if (zipGeoLocations[key]) {
		ProSearchMetaStore.emit(SEARCH_LOCATION_CHANGE_EVENT, zipGeoLocations[key]);
		ProSearchMetaStore.emit(ZIP_CHANGE_EVENT);
		return;
	}

	const headers = new Headers({
		'Content-Type': 'application/json',
	});

	const init = {
		headers:     headers,
		method:      'GET',
		credentials: 'include',
	};

	const url = Routes.getRoute(Routes.Names.UTILITY_GET_GEO_LOCATION_BY_ZIP_CODE).replace(':zipCode', zipCode);

	doFetch(url, null, Constants.HttpMethods.GET, true, init).then(function(result) {
		if (result.success && result.data) {
			const geoLocation = JSON.parse(result.data);
			if (geoLocation) {
				const parsedLocation = parseGeoLocation(geoLocation);
				zipGeoLocations[key] = parsedLocation;
				ProSearchMetaStore.emit(SEARCH_LOCATION_CHANGE_EVENT, parsedLocation);
			}
		} else {
			throw new Error();
		}
	}).catch(function() {
		ProSearchMetaStore.emit(SEARCH_LOCATION_CHANGE_EVENT, []);
	});
}

function parseGeoLocation(geoLocation) {
	return [geoLocation.lat, geoLocation.long];
}

function debounceMoodValue(moodValue, searchType) {
	searchType = ProSearchMetaStore.checkSearchType(searchType);
	if (_state[searchType].debounceTimer) {
		window.clearTimeout(_state[searchType].debounceTimer);
	}

	_state[searchType].debounceTimer = window.setTimeout(() => {
		_state[searchType].moodValue = moodValue;
		updateActorList(undefined, undefined, searchType, _state[searchType].currentPage);
	}, 500);
}

ProSearchMetaStore.dispatchToken = Dispatcher.register((action) => {
	switch (action.type) {
		case ProSearchActionTypes.SET_CATEGORY:
			const page = action.page || _state[ProSearchMetaStore.checkSearchType(action.searchType)].currentPage;
			updateActorList(action.category, undefined, action.searchType, page);
			break;
		case ProSearchActionTypes.LOAD_PAGE:
			if (!action.append || action.page > _state[ProSearchMetaStore.checkSearchType(action.searchType)].currentPage) {
				updateActorList(undefined, undefined, action.searchType, action.page, action.append);
			}
			break;
		case ProSearchActionTypes.DELETE_FILTER:
			deleteFilter(action.searchType);
			break;
		case ProSearchActionTypes.CHANGE_SEARCH_CONDITION:
			updateActorList(undefined,
				{
					'field':            action.field,
					'value':            action.value,
					'valueTranslation': action.valueTranslation,
					'checked':          action.checked,
				},
				action.searchType,
				_state[ProSearchMetaStore.checkSearchType(action.searchType)].currentPage,
				false,
				action.doSearch
			);
			break;
		case ProSearchActionTypes.CHANGE_MOOD_VALUE:
			debounceMoodValue(action.moodValue, action.searchType);
			break;
		case ProSearchActionTypes.INIT:
			const sort = Object.hasOwn(action, 'sortType')
				? action.sortType
				: ProSearchSortTypes.SEARCH_SORT_TYPE_RANK;

			init(action.category, action.searchType, action.callBack, sort);
			break;
		case Constants.ActionTypes.SearchResult.SET_ACTOR_ONLINE_STATE:
			setActorOnlineState(action.actorId, action.state);
			break;
		case ProSearchActionTypes.SET_ACTOR_MAP_STATE:
			setActorMapState(action.state, action.searchType);
			break;
		case ProSearchActionTypes.CHANGE_SORT_TYPE:
			setSortType(action.sortType, action.searchType);
			updateActorList(undefined, undefined, action.searchType, 1, false, action.doSearch);
			break;
		case ProSearchActionTypes.SET_SEARCH_LOCATION_BY_ZIP:
			setSearchLocationByZip(action.zipCode);
			break;
		case ProSearchActionTypes.SET_LAST_SEARCHED_ZIP:
			setLastSearchedZip(action.zipCode);
			break;
		case ProSearchActionTypes.SET_GUEST_GEO_LOCATION:
			setGuestGeoLocation(action.geoLocation);
			break;
		default:
			break;
	}
});

export default ProSearchMetaStore;
