'use strict';

import {EventEmitter}          from 'events';
import assign                  from 'object-assign';
import Routes                  from './../../utils/Routes';
import Constants               from './../Constants';
import Dispatcher              from './../Dispatcher';
import BlogPostsStore                        from './BlogPostsStore';
import {doFetch, generateHash, urlWithQuery} from './../../utils/CommonUtils';
import Flux                                  from "../Flux";

const TVActionTypes = Constants.ActionTypes.TV;
const ChangeEvents = {
	formatChanged:                        'formatChanged',
	postChanged:                          'postChanged',
	videoChanged:                         'videoChanged',
	videoSuggestChanged:                  'videoSuggestChanged',
	mediathekVideoRecommendationsLoading: 'mediathekVideoRecommendationsLoading',
	mediathekVideoRecommendationsChange:  'mediathekVideoRecommendationsChange',
	mediathekVideosLoading:               'mediathekVideoLoading',
	mediathekVideosChange:                'mediathekVideoChange',
};
const _formatData                   = {};
const _videoData                    = {};
const _suggestVideoData             = {};
const _postData                     = {};

const _mediathekVideoRecommendationsData = {};
const _mediathekVideoRecommendationsLoading = {};

const _mediathekVideosData = {};
const _mediathekVideosLoading = {};


const _defaultMediathekVideoFilter = {contentGroups: null, excludedHashIds: null, formatKey: null};

const TVMediaStore = assign({}, EventEmitter.prototype, {

	init: function() {
	},

	emitFormatChange: function(type, key) {
		this.emit(ChangeEvents.formatChanged, type, key);
	},

	emitVideoChange: function(videoId) {
		this.emit(ChangeEvents.videoChanged, videoId);
	},
	emitSuggestVideoData: function(videoId) {
		this.emit(ChangeEvents.videoSuggestChanged, videoId);
	},

	/**
	 * @param {String} slug
	 * @param {Object} previous
	 * @param {Object} breadcrumbs
	 * @param {Array} categories
	 * @param {Array} similar
	 */
	emitPostChange: function(slug, previous, breadcrumbs, categories = [], similar = []) {
		this.emit(ChangeEvents.postChanged, slug, previous, breadcrumbs, categories, similar);
	},

	addFormatChangeListener: function(callback) {
		this.on(ChangeEvents.formatChanged, callback);
	},

	removeFormatChangeListener: function(callback) {
		this.removeListener(ChangeEvents.formatChanged, callback);
	},

	addPostChangeListener: function(callback) {
		this.on(ChangeEvents.postChanged, callback);
	},

	removePostChangeListener: function(callback) {
		this.removeListener(ChangeEvents.postChanged, callback);
	},

	addVideoChangeListener: function(callback) {
		this.on(ChangeEvents.videoChanged, callback);
	},

	addSuggestVideoChangeListener: function(callback) {
		this.on(ChangeEvents.videoSuggestChanged, callback);
	},

	removeVideoChangeListener: function(callback) {
		this.removeListener(ChangeEvents.videoChanged, callback);
	},

	removeSuggestVideoChangeListener: function(callback) {
		this.removeListener(ChangeEvents.videoSuggestChanged, callback);
	},

	getFormatData: function(key) {
		return _formatData[getFormatKey({key})];
	},

	getVideoData: function(videoId) {
		return _videoData[videoId];
	},

	getSuggestVideoData: function(videoId) {
		return _suggestVideoData[videoId];
	},
	getPostData: function(slug) {
		return _postData[slug];
	},


	getMediathekVideoRecommendations: function(hashId) {
		return _mediathekVideoRecommendationsData[hashId] || null;
	},

	isMediathekVideoRecommendationsLoading: function(hashId) {
		return _mediathekVideoRecommendationsLoading[hashId] || false;
	},


	addMediathekVideoRecommendationsChangeListener: function(callback) {
		this.on(ChangeEvents.mediathekVideoRecommendationsChange, callback);
	},

	removeMediathekVideoRecommendationsChangeListener: function(callback) {
		this.removeListener(ChangeEvents.mediathekVideoRecommendationsChange, callback);
	},

	addMediathekVideoRecommendationsLoadListener: function(callback) {
		this.on(ChangeEvents.mediathekVideoRecommendationsLoading, callback);
	},

	removeMediathekVideoRecommendationsLoadListener: function(callback) {
		this.removeListener(ChangeEvents.mediathekVideoRecommendationsLoading, callback);
	},


	getMediathekVideos: function(filter) {
		let videos = null;
		const key  = getMediathekVideoKey(filter);
		if (_mediathekVideosData[key]) {
			videos = _mediathekVideosData[key]['items'] || null;
		}
		return videos;
	},

	getMediathekVideosTotalCount: function(filter) {
		let total = null;
		const key = getMediathekVideoKey(filter);
		if (_mediathekVideosData[key]) {
			total = _mediathekVideosData[key]['total'] || null;
		}
		return total;
	},

	isMediathekVideosLoading: function(filter) {
		return _mediathekVideosLoading[getMediathekVideoKey(filter)] || false;
	},

	addMediathekVideosChangeListener: function(callback) {
		this.on(ChangeEvents.mediathekVideosChange, callback);
	},

	removeMediathekVideosChangeListener: function(callback) {
		this.removeListener(ChangeEvents.mediathekVideosChange, callback);
	},

	addMediathekVideosLoadListener: function(callback) {
		this.on(ChangeEvents.mediathekVideosLoading, callback);
	},

	removeMediathekVideosLoadListener: function(callback) {
		this.removeListener(ChangeEvents.mediathekVideosLoading, callback);
	},

});

function loadFormatData(key) {

	doFetchWrapper(Routes.getRoute(Routes.Names.TV_FORMAT_DATA, {key}), null, Constants.HttpMethods.GET, true, {cache: 'no-cache'}).then((result) => {
		if (result.data) {
			_formatData[getFormatKey({key})] = result.data;

			TVMediaStore.emitFormatChange(key);
		}
	});
}

/**
 * @param {String} slug
 */
function loadPostData(slug) {

	doFetchWrapper(Routes.getRoute(Routes.Names.TV_POST_DATA, {slug}), null, Constants.HttpMethods.GET, true, {cache: 'no-cache'}).then((result) => {
		// current requested post
		_postData[slug] = result.post;

		// previous
		if (Object.hasOwn(result, 'previous') && result.previous !== null) {
			_postData[result.previous.slug] = result.previous;
		}

		// similar
		if (Object.hasOwn(result, 'similar') && Object.hasOwn(result.similar, 'models')) {
			for (let i = 0; i < result.similar.models.length; i++) {
				_postData[result.similar.models[i].slug] = result.similar.models[i];
			}
		}

		// store categories (before emitting)
		BlogPostsStore.setCategories(result.categories);

		// emit received
		TVMediaStore.emitPostChange(
			slug,
			result.previous ? result.previous : false,
			result.breadcrumbs ? result.breadcrumbs : false,
			result.categories ? result.categories : [],
			result.similar.models ? result.similar.models : []
		);
	});
}

function loadVideoData(videoId) {

	doFetchWrapper(Routes.getRoute(Routes.Names.TV_VIDEO_DATA, {videoId}), null, Constants.HttpMethods.GET, true, {cache: 'no-cache'}).then((result) => {
		if (result.data) {
			_videoData[videoId] = result.data;

			TVMediaStore.emitVideoChange(videoId);
		}
	});
}

function loadSuggestVideoData(videoId) {
	const hashId = videoId;
	doFetchWrapper(Routes.getRoute(Routes.Names.TV_VIDEO_SUGGEST, {hashId}), null, Constants.HttpMethods.GET, true, {cache: 'no-cache'}).then((result) => {
		_suggestVideoData[videoId] = result.data;
		TVMediaStore.emitSuggestVideoData(videoId);
	});
}

function loadMediathekVideoRecommendations(
	hashId,
	count
) {
	_mediathekVideoRecommendationsLoading[hashId] = true;
	TVMediaStore.emit(ChangeEvents.mediathekVideoRecommendationsLoading);
	Flux.Vxql.getTVMediathekVideoRecommendationsByHashIdFromVXQL(hashId, count).then(({data}) => {
		const newData = data.tv.mediathekVideo.recommendations.mediathekVideos;

		if (newData) {
			let recommendationsData = _mediathekVideoRecommendationsData[hashId];

			if (!recommendationsData) {
				recommendationsData = [];
			}

			for (const [index, video] of newData.entries()) {
				recommendationsData[index] = video;
			}

			_mediathekVideoRecommendationsData[hashId] = recommendationsData;

			_mediathekVideoRecommendationsLoading[hashId] = false;

			TVMediaStore.emit(ChangeEvents.mediathekVideoRecommendationsLoading);
			TVMediaStore.emit(ChangeEvents.mediathekVideoRecommendationsChange);
		}
	});
}


function getShapedMediathekVideoFilter(filter) {
	// add missing filter values and sets defined order of key(so objects can be compared using stringify)
	return {..._defaultMediathekVideoFilter, ...filter};
}

function getMediathekVideoKey(filter) {
	return generateHash(JSON.stringify({...getShapedMediathekVideoFilter(filter)}));
}

function loadMediathekVideos(filter, count, offset) {
	filter = getShapedMediathekVideoFilter(filter);
	const key = getMediathekVideoKey(filter);

	_mediathekVideosLoading[key] = true;
	TVMediaStore.emit(ChangeEvents.mediathekVideosLoading);
	Flux.Vxql.getMediathekVideosFromVXQL(filter, count, offset).then(({data}) => {
		const newVideoData = data.tv.mediathekVideos;

		if (newVideoData) {
			let videoData = _mediathekVideosData[key];

			if (!videoData) {
				videoData = {};
			}


			videoData.total = newVideoData.total;

			let videos = videoData.items;

			if (!videos) {
				videos          = {};
				videoData.items = videos;
			}

			for (const [index, video] of newVideoData.items.entries()) {
				videos[offset + index] = video;
			}

			_mediathekVideosData[key] = videoData;

			_mediathekVideosLoading[key] = false;


			TVMediaStore.emit(ChangeEvents.mediathekVideosLoading);
			TVMediaStore.emit(ChangeEvents.mediathekVideosChange);
		}
	});
}


function doFetchWrapper(restUrl, payload, method, setCredentials = false, init = {}) {
	if (!(payload && typeof payload === 'object')) {
		payload = {};
	}

	Object.assign(payload, {'_': Date.now()});
	restUrl = urlWithQuery(restUrl, payload);

	return doFetch(restUrl, payload, method, setCredentials, init);
}

function getFormatKey(request) {
	const keys = [];
	for (const r in request) {
		if (Object.hasOwn(request, r)) {
			keys.push(r);
		}
	}

	keys.sort();

	const keyObject = {};
	for (let k = 0; k < keys.length; k++) {
		keyObject[keys[k]] = request[keys[k]];
	}

	return JSON.stringify(keyObject);
}

TVMediaStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case TVActionTypes.LOAD_FORMAT_DATA:
			loadFormatData(action.formatKey);
			break;
		case TVActionTypes.LOAD_POST_DATA:
			loadPostData(action.slug);
			break;
		case TVActionTypes.LOAD_VIDEO_DATA:
			loadVideoData(action.videoId);
			break;
		case TVActionTypes.LOAD_SUGGEST_VIDEO_DATA:
			loadSuggestVideoData(action.videoId);
			break;
		case TVActionTypes.LOAD_MEDIATHEK_VIDEO_RECOMMENDATIONS:
			loadMediathekVideoRecommendations(action.hashId, action.count);
			break;
		case TVActionTypes.LOAD_MEDIATHEK_VIDEOS:
			loadMediathekVideos(action.filter, action.count, action.offset, action.requestId);
			break;
		default:
	}
});

export default TVMediaStore;

