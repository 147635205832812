import Dispatcher               from '../Dispatcher';
import FluxEventEmitter         from '../FluxEventEmitter';
import Constants                from '../Constants';
import assign                   from 'object-assign';
import Flux                     from "../Flux";


const TVSeriesActionTypes = Constants.ActionTypes.TVSeries;
const CHANGE_EVENTS      = {
	tvSeriesDataChange: 'tvSeriesDataChange',
	tvSeriesLoad:       'tvSeriesLoad',
};

let _tvSeries = {};
let _tvSeriesLoading = false;


const TVSeriesStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @returns {Object[]|null}
	 */
	getSeries: function() {
		return _tvSeries['items'] || null;
	},

	/**
	 * @returns {*|null}
	 */
	getSeriesTotalCount: function() {
		return _tvSeries['total'] || null;
	},

	/**
	 *
	 * @returns {boolean}
	 */
	isTVSeriesLoading: function() {
		return _tvSeriesLoading || false;
	},


	addTVSeriesDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.tvSeriesDataChange, callback);
	},

	removeTVSeriesDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.tvSeriesDataChange, callback);
	},

	addTVSeriesLoadListener: function(callback) {
		this.on(CHANGE_EVENTS.tvSeriesLoad, callback);
	},

	removeTVSeriesLoadListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.tvSeriesLoad, callback);
	},


});

function loadTVSeries(
	count,
	offset = 0
) {
	_tvSeriesLoading = true;
	TVSeriesStore.emit(CHANGE_EVENTS.tvSeriesLoad);
	Flux.Vxql.getTVSeriesFromVXQL(count, offset).then(({data}) => {
		const newData = data.tv.series;

		if (newData) {
			let tvSeriesData = _tvSeries;

			if (!tvSeriesData) {
				tvSeriesData = {};
			}


			tvSeriesData.total = newData.total;

			let tvSeries = tvSeriesData.items;

			if (!tvSeries) {
				tvSeries          = {};
				tvSeriesData.items = tvSeries;
			}

			for (const [index, series] of newData.items.entries()) {
				tvSeries[offset + index] = series;
			}

			_tvSeries = tvSeriesData;

			_tvSeriesLoading = false;

			TVSeriesStore.emit(CHANGE_EVENTS.tvSeriesLoad);
			TVSeriesStore.emit(CHANGE_EVENTS.tvSeriesDataChange);
		}
	});
}

TVSeriesStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case TVSeriesActionTypes.LOAD_SERIES:
			loadTVSeries(action.count, action.offset);
			break;

		default:
		// acquire world dominance
	}
});

export default TVSeriesStore;
