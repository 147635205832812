/*global VXConfig*/
'use strict';

import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import {doFetch}        from './../../utils/CommonUtils';
import assign           from 'object-assign';
import Constants        from '../Constants';
import EventTracker     from '../../utils/EventTracker';


// directly access Store without ActionCreator to avoid dispatch in middle of dispatch
const TrackerStore = assign({}, FluxEventEmitter.prototype, {
	init: function() {
	},

	/**
	 *
	 * @returns {EventTracker}
	 */
	getEventTracker: function() {
		return new EventTracker(VXConfig.vxqlUrl, VXConfig.vxqlAccessToken);
	},

	trackActorProfileVisit: function(actorName) {
		doFetch(Routes.getRoute(Routes.Names.TRACKER_ACTOR_PROFILE_VISIT), {actorName: actorName}, Constants.HttpMethods.POST, true);
	},

	track: function(eventContext, eventAction, eventData) {
		doFetch(Routes.getRoute(Routes.Names.TRACKER_TRACK), {
			eventContext: eventContext,
			eventAction:  eventAction,
			eventData:    eventData,
		}, Constants.HttpMethods.POST, true);
	},
});


export default TrackerStore;
