import Dispatcher               from '../Dispatcher';
import {default as ActionTypes} from './Types/VideoJumbotronActionTypes';

class VideoJumbotronActionCreator {

	/**
	 * @param {String} type
	 */
	static requestConfig(type) {
		Dispatcher.dispatch({
			type:       ActionTypes.REQUEST_CONFIG,
			configType: type,
		});
	}
}

export default VideoJumbotronActionCreator;
