/*global VXConfig */
import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import {doFetch}        from "../../utils/CommonUtils";
import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';

const TeaserActionTypes = Constants.ActionTypes.Teaser;
const CHANGE_EVENTS     = {
//	StatusBar: 'statusBarChange',
    Teasers: 'teasersChange',
};

const _teasers = {};

/**
 * Find a mobile teaser config by its id
 * @param teaserId
 * @returns {*|null}
 */
function findConfigById(teaserId) {
    const teasers = window.VXBannerConfig ? {ALL: window.VXBannerConfig} : _teasers;

    for (const key in teasers) {
        for (const category in teasers[key]) {
            const config = teasers[key][category].find(item => item.id === teaserId);
            if (config) {
                return config;
            }
        }
    }
    return null;
}

const TeaserStore = assign({}, FluxEventEmitter.prototype, {
    addTeasersChangeListener: function(callback) {
        this.on(CHANGE_EVENTS.Teasers, callback);
    },

    removeTeasersChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENTS.Teasers, callback);
    },

    getTeasers: function(routeName, routeArgs) {
        const key = getTeaserCacheKey(routeName, routeArgs);
        return typeof _teasers[key] !== 'undefined' ? _teasers[key] : null;
    },

    hasTeasers:                            function(routeName, routeArgs) {
        const teasers = TeaserStore.getTeasers(routeName, routeArgs);
        return teasers !== null && teasers.constructor === Object && Object.keys(teasers).length > 0;
    },
    getPrivateShopDiscountMobileBannerUrl: function() {
        return VXConfig.privateShopDiscountMobileBannerUrl;
    },
    getTeaserConfigById:                   function(teaserId) {
        return findConfigById(teaserId);
    },
    getFeedbackFeatureByTeaserId:          function(id) {
        const bannerConfig    = TeaserStore.getTeaserConfigById(id);
        const featureFeedback = bannerConfig?.addons?.FeatureFeedback;
        return featureFeedback || undefined;
    },
});

/**
 *
 * @param {String} routeName
 * @param {Object} routeArgs
 * @returns {string}
 */
function getTeaserCacheKey(routeName, routeArgs) {
    return routeName + ':' + JSON.stringify(routeArgs);
}

function loadTeasers(routeName, routeArgs) {
    doFetch(Routes.getRoute(Routes.Names.TEASER), {
        routeName, routeArgs,
    }, Constants.HttpMethods.POST, true).then((result) => {
        const key = getTeaserCacheKey(routeName, routeArgs);

        if (result) {
            _teasers[key] = result.data;
            TeaserStore.emit(CHANGE_EVENTS.Teasers);
        }
    });
}

TeaserStore.dispatchToken = Dispatcher.register(function(action) {
    switch (action.type) {
        case TeaserActionTypes.LOAD_TEASERS:
            loadTeasers(action.routeName, action.routeArgs);
            break;
        default:
    }
});

export default TeaserStore;
