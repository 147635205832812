'use strict';

import Dispatcher from './../Dispatcher';
import Constants  from './../Constants';

const ActionTypes = Constants.ActionTypes.GuestProfile;

const GuestProfileActionCreator = {

	getGuestProfile: function() {
		Dispatcher.dispatch({
			type:         ActionTypes.GET_GUEST_PROFILE,
		});
	},
	
	setGuestProfile: function(guestProfile) {
		Dispatcher.dispatch({
			type:         ActionTypes.SET_GUEST_PROFILE,
			guestProfile: guestProfile,
		});
	},

	updateProperties: function(properties) {
		Dispatcher.dispatch({
			type:       ActionTypes.UPDATE_PROPERTIES,
			properties: properties,
		});
	},

	saveProperties: function() {
		Dispatcher.dispatch({
			type: ActionTypes.SAVE_PROPERTIES,
		});
	},
	
	deletePicture: function() {
		Dispatcher.dispatch({
			type: ActionTypes.DELETE_PICTURE,
		});
	},

};

export default GuestProfileActionCreator;
