/* global VXConfig */
'use strict';

import Dispatcher           from '../Dispatcher';
import Constants            from '../Constants';
import FluxEventEmitter     from '../FluxEventEmitter';
import assign               from 'object-assign';
import Routes               from '../../utils/Routes';
import {doFetch, setCookie} from '../../utils/CommonUtils';
import Flux                 from "../Flux";
import ProSearchMetaStore   from "./ProSearchMetaStore";
import MessengerGiftStore   from "./MessengerGiftStore";
import VxqlStore            from "./VxqlStore";

const GUEST_SERVICE_URL        = VXConfig.guestServiceUrl;
const GUEST_SERVICE_SESSION_ID = VXConfig.vxoneSessionId;

let geoLocationInitalized = false;

const ActionTypes  = Constants.ActionTypes.Guest;
const ChangeEvents = {
	autoRechargeTeaserState:       'autoRechargeTeaserState',
	canOneClickChange:             'canOneClickChange',
	guest:                         'guest',
	newsletterSubscribed:          'newsletterSubscribed',
	newsletterUnsubscribed:        'newsletterUnsubscribed',
	redeemBonus:                   'bonus',
	emailSuccess:                  'emailSuccess',
	emailGeneralFail:              'emailGeneralFail',
	emailTimeoutFail:              'emailTimeoutFail',
	notificationsSettingsReceived: 'notificationsSettingsReceived',
	geoLocation:                   'geoLocation',
//	boughtGifts:                   'boughtGifts',
	featureFeedbackSent:        'featureFeedbackSent',
	featureFeedbackSet:        'featureFeedbackSet',
	addRelationEvent:           'addRelationEvent',
	pincallVerificationStarted: 'pincallVerificationStarted',
	pincallResultReceived:      'pincallResultReceived',
	conversionChatUpdate:       'conversionChatUpdate',
	freeChatDuration:           'freeChatDuration',
	avsPayRequest:              'avsPayRequest',
	avsBonus:                   'avsBonus',
	videoLikeChange:            'videoLikeChange',
	videoDislikeChange:         'videoDislikeChange',
//	galleryLikeChange:             'galleryLikeChange',
	galleryDislikeChange: 'galleryDislikeChange',
	benefitsCode:         'benefitsCode',
	loyaltyPointsChanged: 'loyaltyPointsChanged',
    fifteenMinutesPreview: 'fifteenMinutesPreview',
};

let guestData = {};
updateGuestData();

let _freeChatDurations = [];

/**
 *
 * @param {string} query
 * @param {Object} variables
 * @returns {Promise}
 */
function doGuestServiceRequest(query, variables = null) {
	const queryObject = {};
	queryObject.query = query;

	if (variables) {
		queryObject.variables = variables;
	}

	const init = {
		headers: {'Authorization': 'Bearer ' + GUEST_SERVICE_SESSION_ID},
	};

	return doFetch(GUEST_SERVICE_URL, queryObject, Constants.HttpMethods.POST, false, init);
}

function closeDataProtection(accepted) {
	if (GuestStore.isLoggedIn()) {
		doFetch(Routes.getRoute(Routes.Names.GUEST_CLOSE_DATA_PROTECTION), {accepted: accepted}, Constants.HttpMethods.POST, false).then((result) => {
			if (result.success) {
				setShowDataProtection(false);
			}
		});
	}
}

function closeAgbAndDataProtection(accepted) {
	if (GuestStore.isLoggedIn()) {
		doFetch(Routes.getRoute(Routes.Names.GUEST_CLOSE_AGB_AND_DATA_PROTECTION), {accepted: accepted}, Constants.HttpMethods.POST, false).then((result) => {
			if (result.success) {
				setShowDataProtection(false);
			}
		});
	}
}

function setShowDataProtection(showDataProtection) {
	guestData.showDataProtection = !!showDataProtection;
	GuestStore.emitGuestChange();
}

function setAutoRechargeTeaserState(mode) {
	if (mode && GuestStore.isLoggedIn()) {
		doFetch(Routes.getRoute(Routes.Names.GUEST_AUTO_RECHARGE_TEASER_STATE), {mode: mode}, Constants.HttpMethods.POST, true).then((result) => {
			GuestStore.emitAutoRechargeTeaserStateChange(result.success);
		});
	}
}

function setVideoLike(videoId) {
	GuestStore.emitVideoLikeChange(videoId);
}

function setVideoDislike(videoId) {
	GuestStore.emitVideoDislikeChange(videoId);
}

function setGalleryLike(videoId) {
	GuestStore.emitGalleryLikeChange(videoId);
}

function setGalleryDislike(videoId) {
	GuestStore.emitGalleryDislikeChange(videoId);
}

function updateGuestData(fullUpdate = false) {
	guestData = {
		hasAvs:                              !!VXConfig.guestHasAvs,
		isLocked:                            !!VXConfig.guestIsLocked,
		isLoggedIn:                          !!VXConfig.guestIsLoggedIn,
		isRestricted:                        !!VXConfig.isGuestRestricted,
		isVip:                               !!VXConfig.isVip,
		isVXSelect:                          !!VXConfig.isVXSelect,
		isVXTVAboSubscriber:                 !!VXConfig.isVXTVAboSubscriber,
		wasVXTVAboSubscriber:                !!VXConfig.wasVXTVAboSubscriber,
		name:                                VXConfig.guestName,
		email:                               VXConfig.guestEmail,
		balance:                             VXConfig.guestBalance,
		balanceFormatted:                    VXConfig.guestBalanceFormatted,
		autoRechargeAmount:                  VXConfig.guestAutoRechargeAmount,
		autoRechargeConfig:                  VXConfig.guestAutoRechargeConfig,
		isPayingCustomer:                    !!VXConfig.isGuestPayingCustomer,
		isForcePayment:                      !!VXConfig.isGuestForcePayment,
		oneClickAmounts:                     VXConfig.guestOneClickAmounts,
		oneClickChargeAmounts:               VXConfig.guestOneClickChargeAmounts,
		oneClickConfig:                      VXConfig.guestOneClickConfig,
		isVoicecallActive:                   !!VXConfig.isVoicecallActive,
		isAnonymousVoicecall:                !!VXConfig.isAnonymousVoicecall,
		showDataProtection:                  !!VXConfig.guestShowDataProtection,
		testGroups:                          VXConfig.guestTestGroups,
		geoLocation:                         VXConfig.guestGeoLocation,
		urlPreviewPicture:                   VXConfig.guestUrlPreviewPicture,
		notificationsSettings:               VXConfig.notificationsSettings,
		signupOrigin:                        VXConfig.guestSignupOrigin,
		boughtGifts:                         guestData.boughtGifts ? guestData.boughtGifts : {},
		vouchers:                            guestData.vouchers,
		voucherFromParam:                    VXConfig.bonusCodeInfo,
		activeFeatureIdsWithFeedback:        VXConfig.guestActiveFeatureIdsWithFeedback,
		conversionChatCountdownEnd:          VXConfig.conversionChatCountdownEnd,
		conversionChatCandidateCountdownEnd: VXConfig.conversionChatCandidateCountdownEnd,
		pincallVerifications:                guestData.pincallVerifications ? guestData.pincallVerifications : {},
		restrictionLevel:                    VXConfig.guestRestrictionLevel,
		isBlockedForComments:                VXConfig.isBlockedForComments,
		avsStatus:                           VXConfig.guestAvsStatus,
		avsRejectMessage:                    VXConfig.guestAvsRejectMessage,
		id:                                  VXConfig.guestId,
		loyaltyPoints:                       VXConfig.guest ? VXConfig.guest.loyaltyPoints : 0,
        preferences:                         VXConfig.guestPreferences,
	};

	if (typeof window.Flux !== "undefined" && fullUpdate && guestData.isLoggedIn) {
		Flux.Vxql.getGuestInitData(
			function(result) {
				if (result.data.guest) {
					guestData.vouchers = result.data.guest.vouchers;
					GuestStore.emitGuestChange();
				}
			}
		);
	}

}

function setBalanceFormatted(balance, balanceFormatted, isPayingCustomer = null) {
	guestData.balance          = balance;
	guestData.balanceFormatted = balanceFormatted;

	if (isPayingCustomer !== null) {
		guestData.isPayingCustomer = isPayingCustomer;
	}

	GuestStore.emitGuestChange();
}

function setVouchers(vouchers) {
	guestData.vouchers = vouchers;

	GuestStore.emitGuestChange();
}

function setCanOneClick(oneClickConfig, oneClickAmounts, autoRechargeConfig, autoRechargeAmount, isPayingCustomer) {
	guestData.autoRechargeAmount = autoRechargeAmount;
	guestData.autoRechargeConfig = autoRechargeConfig;
	guestData.isPayingCustomer   = !!isPayingCustomer;
	guestData.oneClickAmounts    = oneClickAmounts;
	guestData.oneClickConfig     = oneClickConfig;

	GuestStore.emitCanOneClickChange();
}

function setHasAvs(hasAvs) {
	guestData.hasAvs = !!hasAvs;
	GuestStore.emitGuestChange();
}

function setAvsStatus(avsStatus) {
	guestData.avsStatus = avsStatus;
	GuestStore.emitGuestChange();
}

function setAvsRejectMessage(rejectMessage) {
	guestData.avsRejectMessage = rejectMessage;
	GuestStore.emitGuestChange();
}

function setIsLocked(isLocked) {
	guestData.isLocked = !!isLocked;
}

function setIsVip(isVip) {
	guestData.isVip = !!isVip;
	GuestStore.emitGuestChange();
}

function setIsVXSelect(isVXSelect) {
	guestData.setIsVXSelect = !!isVXSelect;
	GuestStore.emitGuestChange();
}

function setIsVoicecallActive(isVoicecallActive) {
	guestData.isVoicecallActive = isVoicecallActive;
	GuestStore.emitGuestChange();
}

function setIsVXTVAboSubscriber(isVXTVAboSubscriber) {
	guestData.isVXTVAboSubscriber = !!isVXTVAboSubscriber;
	GuestStore.emitGuestChange();
}

function setWasVXTVAboSubscriber(wasVXTVAboSubscriber) {
	guestData.wasVXTVAboSubscriber = !!wasVXTVAboSubscriber;
	GuestStore.emitGuestChange();
}

function redeemBonus(bonusCode) {
	if (bonusCode) {
		doFetch(Routes.getRoute(Routes.Names.GUEST_REDEEM_BONUS), {code: bonusCode}, Constants.HttpMethods.POST, true).then((result) => {
			GuestStore.emitRedeemBonus(result.data.success, result.data.bonus);
		});
	}
}

function requestGuestBalance() {
	doFetch(Routes.getRoute(Routes.Names.GUEST_GET_BALANCE), null, Constants.HttpMethods.GET, false).then((result) => {
		if (result.success && result.data) {
			setBalanceFormatted(result.data.balance, result.data.balanceFormatted, result.data.isPayingCustomer);
		}
	});
}

function acceptCancellationOffer(guestId, platformText, offerActivationFail, offerActivationSuccess) {
	if (guestId) {
		doFetch(Routes.getRoute(Routes.Names.GUEST_ACCEPT_CANCELLATION_OFFER), {
			guest_id:      guestId,
			platform_text: platformText,
		}, Constants.HttpMethods.POST, true).then((result) => {
			if (result.data.accepted_offer) {
				offerActivationSuccess(result.data.accepted_offer_info);
				setCookie(Constants.CookieNames.STATUS_BAR_CANCELLATION_OFFER, '1', 0, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
			} else {
				offerActivationFail();
			}
		});
	}
}

function removeRedeemableGifts(giftIds) {
	const boughtGifts = guestData.boughtGifts;

	for (let i = 0; i < giftIds.length; i++) {
		if (boughtGifts[giftIds[i]]) {
			boughtGifts[giftIds[i]]--;

			if (boughtGifts[giftIds[i]] === 0) {
				delete boughtGifts(giftIds[i]);
			}
		}
	}
}

function getBoughtGiftsForGuest() {
	Flux.Vxql.getBoughtGiftsForGuest(handleBoughtGiftsForGuest);
}

function handleBoughtGiftsForGuest(result) {
	if (result.data) {
		const vxqlBoughtGifts = result.data.guest.boughtMessengerGifts;
		const boughtGifts     = {};
		for (let i = 0; i < vxqlBoughtGifts.length; i++) {
			boughtGifts[parseInt(vxqlBoughtGifts[i].gift.id)] = parseInt(vxqlBoughtGifts[i].quantity);
		}
		guestData.boughtGifts = boughtGifts;
		MessengerGiftStore.onBoughtGiftsUpdate();
		MessengerGiftStore.emitGiftMessageChange();
	}
}

function getNotificationsSettings() {
	doFetch(Routes.getRoute(Routes.Names.GUEST_NOTIFICATIONS_GET_SETTINGS), {}, Constants.HttpMethods.POST, true).then((result) => {
		if (result.data.notifications_settings) {
			guestData.notificationsSettings = result.data.notifications_settings;
			GuestStore.emit(ChangeEvents.notificationsSettingsReceived);
		}
	});
}

function sendVideoShareEmail(recipient, message, videoId, guestId) {
	if (recipient && videoId && guestId) {
		doFetch(Routes.getRoute(Routes.Names.GUEST_SEND_VIDEO_SHARE_EMAIL), {
			recipient: recipient,
			message:   message,
			videoId:   videoId,
			guestId:   guestId,
		}, Constants.HttpMethods.POST, true).then((result) => {
			// Email was successfully sent
			if (result.data.Status === 'OK') {
				GuestStore.emit(ChangeEvents.emailSuccess);
				// User already sent an E-Mail in the last 15 Minutes
			} else if (result.data.Status === 'TIMEOUT') {
				GuestStore.emit(ChangeEvents.emailTimeoutFail);
				// General Error occurred
			} else {
				GuestStore.emit(ChangeEvents.emailGeneralFail);
			}
		});
	}
}

function subscribeNewsletter(typeId, userId = null, emailAddress = null) {
	doFetch(Routes.getRoute(Routes.Names.NEWSLETTER_SUBSCRIBE), {typeId, userId, emailAddress}, Constants.HttpMethods.POST, true)
		.then(function(result) {
			if (result.success && result.data.success) {
				GuestStore.emitNewsletterSubscribed(true, typeId);
			} else {
				throw new Error();
			}
		})
		.catch(function() {
			GuestStore.emitNewsletterSubscribed(false, typeId);
		});
}

function unsubscribeNewsletter(userId, emailAddress, option, reasons) {

	const payload = {
		emailAddress: emailAddress,
		option:       option,
		reasons:      reasons,
		userId:       userId,
	};

	doFetch(Routes.getRoute(Routes.Names.NEWSLETTER_UNSUBSCRIBE), payload, Constants.HttpMethods.POST, true)
		.then(function(result) {
			if (result.success) {
				GuestStore.emitNewsletterUnsubscribed(result.data.success);
			} else {
				throw new Error();
			}
		})
		.catch(function() {
			GuestStore.emitNewsletterUnsubscribed(false);
		});
}

function initGeoLocation() {
	if (!guestData.geoLocation) {
		updateGeoLocationByHtml5();
		return;
	}

	if (guestData.geoLocation.zip !== '') {
		Flux.ProSearch.setLastSearchedZip(guestData.geoLocation.zip);
	}

	if (geoLocationInitalized && Flux.ProSearch.getLastSearchedZip()) {
		Flux.ProSearch.setSearchLocationToPrevious();
	} else if (guestData.geoLocation.source !== 'zip' && guestData.geoLocation.source !== 'userInput') {
		updateGeoLocationByHtml5();
	} else {
		GuestStore.emitGeoLocationChangeEvent();
	}

	geoLocationInitalized = true;
}

function updateGeoLocationByHtml5() {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition((result) => {
			const lat  = result.coords.latitude;
			const long = result.coords.longitude;
			if (lat && long) {
				guestData.geoLocation = {lat: lat, long: long, source: 'html'};
				Flux.ProSearch.setGuestGeoLocation(guestData.geoLocation);
			}
			GuestStore.emitGeoLocationChangeEvent();
		});
	} else {
		GuestStore.emitGeoLocationChangeEvent();
	}
}

function sendFeatureFeedback(featureId, rating, text, url) {
	const handleSendFeatureFeedback = result => {
		const success = result.data.guest.sendFeatureFeedback === true;
		if (success) {
			guestData.activeFeatureIdsWithFeedback.push(featureId);
			GuestStore.emitGuestChange();
		} else {
			console.error('Error while sending feature feedback', {result}); // eslint-disable-line no-console
		}

		GuestStore.emitFeatureFeedbackSentEvent(success, featureId);
	};

	Flux.Vxql.sendFeatureFeedback(featureId, rating, text, url, handleSendFeatureFeedback);
}

function startPincallVerification(phoneNumber, type, language) {
	const query = `
	mutation ($phoneNumber: String!, $type: VerificationTypeEnum!, $language: LanguageEnum) {
		pinCall {
			startVerification(phoneNumber: $phoneNumber, type: $type, language: $language) {
                id
                type
                verified
                countryCode
                preCode
                number
                e123
                pinLength
            }
		}
	}`;

	doGuestServiceRequest(query, {phoneNumber, type, language})
		.then((result) => {
			let errorCode = null;
			if (result.errors) {
				errorCode = result.errors.length && result.errors[0].extensions && result.errors[0].extensions.errorCode ? result.errors[0].extensions.errorCode : Flux.Constants.Pincall.ErrorCodes.UNKNOWN;
			}

			let verificationId = null;
			let pinLength      = Flux.Constants.Pincall.PinCallLength.MOBILE;

			if (result.data.pinCall.startVerification && !errorCode) {
				verificationId = result.data.pinCall.startVerification.id;
				pinLength = result.data.pinCall.startVerification.pinLength;

				guestData.pincallVerifications[verificationId] = result.data.pinCall.startVerification;
			}
			GuestStore.emitPincallVerificationStartedEvent(verificationId, errorCode, pinLength);
		});
}

function perpareAvsPayRequest() {
	const query = `
	mutation {
        avs {
            requestAvs {
                checkoutLink
            }
        }
	}`;

	doGuestServiceRequest(query)
		.then((result) => {
			if (result.data.avs.requestAvs) {
				GuestStore.emitAvsPayRequestEvent(result.data.avs.requestAvs);
			}
		});
}

function isAvsBonusAvailable() {
	const query = `
	query {
        avs {
            bonusAvailable
        }
	}`;

	doGuestServiceRequest(query)
		.then((result) => {
			if (result.data.avs) {
				GuestStore.emitAvsBonusEvent(result.data.avs.bonusAvailable);
			}
		});
}

function verifyPincall(id, type, pin) {
	const query = `
	mutation ($id: Int!, $type: VerificationTypeEnum!, $pin: String!) {
		pinCall {
			verifyPin(id: $id, type: $type, pin: $pin) {
                id
                type
                verified
                countryCode
                preCode
                number
                e123
            }
		}
	}`;

	doGuestServiceRequest(query, {id, type, pin})
		.then((result) => {
			let errorCode = null;
			if (result.errors) {
				errorCode = result.errors.length && result.errors[0].extensions && result.errors[0].extensions.errorCode ? result.errors[0].extensions.errorCode : Flux.Constants.Pincall.ErrorCodes.UNKNOWN;
			}

			if (result.data.pinCall.verifyPin && !errorCode) {
				guestData.pincallVerifications[id] = result.data.pinCall.verifyPin;
			}
			GuestStore.emitPincallResultReceivedEvent(id, errorCode);
		});
}

/**
 *
 * @param {number} relationTypeId
 * @param {number} objectTypeId
 * @param {number} objectId
 */
function addGuestRelation(relationTypeId, objectTypeId, objectId) {
	if (!GuestStore.isLoggedIn()) {
		return;
	}

	const payload = {
		subjectTypeId:  Flux.Constants.Relations.Subjects.GUEST,
		subjectId:      VXConfig.guestId,
		relationTypeId: relationTypeId,
		objectTypeId:   objectTypeId,
		objectId:       objectId,
	};

	doFetch(Routes.getRoute(Routes.Names.ADD_RELATION), payload, Constants.HttpMethods.POST, true)
		.then(function(result) {
			if (result.success) {
				GuestStore.emitAddRelationResultEvent(true);
			} else {
				GuestStore.emitAddRelationResultEvent(false);
			}
		}).catch(function() {
		GuestStore.emitAddRelationResultEvent(false);
	});
}

function doUpdateConversionChat(enable) {
	const payload = {action: enable ? 'enable' : 'disable'};
	doFetch(Routes.getRoute(Routes.Names.GUEST_CONVERSION_CHAT), payload, Constants.HttpMethods.POST, true)
		.then((result) => {
			GuestStore.emitConversionChatUpdateEvent(result.data.success);
		}).catch(function() {
	});
}

function addGuestPreferences(data, callback) {
    GuestStore.setGuestPreferences(data);
	doFetch(Routes.getRoute(Routes.Names.GUEST_SAVE_PREFERENCES), {data}, Constants.HttpMethods.POST, true)
		.then((result) => {
			callback(result);
		}).catch(function() {
	});
}

/**
 *
 * @param {number} relationTypeId
 * @param {number} objectTypeId
 * @param {number} objectId
 */
function hasGuestRelation(relationTypeId, objectTypeId, objectId) {
	if (!GuestStore.isLoggedIn()) {
		return;
	}

	const payload = {
		subjectTypeId:  Flux.Constants.Relations.Subjects.GUEST,
		subjectId:      VXConfig.guestId,
		relationTypeId: relationTypeId,
		objectTypeId:   objectTypeId,
		objectId:       objectId,
	};

	return doFetch(Routes.getRoute(Routes.Names.HAS_RELATION), payload, Constants.HttpMethods.POST, true);
}

function handleFreeChatDurationResult(result, actorId) {
	if (result.data) {
		_freeChatDurations[actorId] = result.data.guest.chatFeatures.freeChatDuration;

		GuestStore.emit(ChangeEvents.freeChatDuration, actorId);
	}
}

function benefitsCodeChange(code) {
	GuestStore.emitBenefitsCodeChange(code);
}

const GuestStore = assign({}, FluxEventEmitter.prototype, {

	init: function() {
		updateGuestData(true);
	},

	updateGuestData: function() {
		updateGuestData();
	},

	emitGuestChange: function() {
		this.emit(ChangeEvents.guest);
	},

	addEmailSuccessListener: function(callback) {
		this.on(ChangeEvents.emailSuccess, callback);
	},

	removeEmailSuccessListener: function(callback) {
		this.removeListener(ChangeEvents.emailSuccess, callback);
	},

	addEmailGeneralFailListener: function(callback) {
		this.on(ChangeEvents.emailGeneralFail, callback);
	},

	removeEmailGeneralFailListener: function(callback) {
		this.removeListener(ChangeEvents.emailGeneralFail, callback);
	},

	addEmailTimeoutFailListener: function(callback) {
		this.on(ChangeEvents.emailTimeoutFail, callback);
	},

	removeEmailTimeoutFailListener: function(callback) {
		this.removeListener(ChangeEvents.emailTimeoutFail, callback);
	},

	addGuestChangeListener: function(callback) {
		this.on(ChangeEvents.guest, callback);
	},

	removeGuestChangeListener: function(callback) {
		this.removeListener(ChangeEvents.guest, callback);
	},

	emitRedeemBonus: function(success, bonus) {
		this.emit(ChangeEvents.redeemBonus, success, bonus);
	},

	updateBoughtGiftsForGuest: function() {
		getBoughtGiftsForGuest();
	},

	emitNewsletterSubscribed: function(success, typeId) {
		this.emit(ChangeEvents.newsletterSubscribed, success, typeId);
	},

	addNewsletterSubscribedListener: function(callback) {
		this.on(ChangeEvents.newsletterSubscribed, callback);
	},

	removeNewsletterSubscribedListener: function(callback) {
		this.removeListener(ChangeEvents.newsletterSubscribed, callback);
	},

	emitNewsletterUnsubscribed: function(success) {
		this.emit(ChangeEvents.newsletterUnsubscribed, success);
	},

	addNewsletterUnsubscribedListener: function(callback) {
		this.on(ChangeEvents.newsletterUnsubscribed, callback);
	},

	removeNewsletterUnsubscribedListener: function(callback) {
		this.removeListener(ChangeEvents.newsletterUnsubscribed, callback);
	},

	addRedeemedBonusListener: function(callback) {
		this.on(ChangeEvents.redeemBonus, callback);
	},

	addNotificationsSettingsReceivedListener: function(callback) {
		this.on(ChangeEvents.notificationsSettingsReceived, callback);
	},

	removeNotificationsSettingsReceivedListener: function(callback) {
		this.removeListener(ChangeEvents.notificationsSettingsReceived, callback);
	},

	removeRedeemedBonusListener: function(callback) {
		this.removeListener(ChangeEvents.redeemBonus, callback);
	},

	emitAutoRechargeTeaserStateChange(success) {
		this.emit(ChangeEvents.autoRechargeTeaserState, success);
	},

	addAutoRechargeTeaserStateChangeListener: function(callback) {
		this.on(ChangeEvents.autoRechargeTeaserState, callback);
	},

	removeAutoRechargeTeaserStateChangeListener: function(callback) {
		this.removeListener(ChangeEvents.autoRechargeTeaserState, callback);
	},

	emitCanOneClickChange() {
		this.emit(ChangeEvents.canOneClickChange);
	},

	addCanOneClickChangeListener: function(callback) {
		this.on(ChangeEvents.canOneClickChange, callback);
	},

	removeCanOneClickChangeListener: function(callback) {
		this.removeListener(ChangeEvents.canOneClickChange, callback);
	},

	emitVideoLikeChange: function(videoId) {
		this.emit(ChangeEvents.videoLikeChange, videoId);
	},

	addVideoLikeChangeListener: function(callback) {
		this.on(ChangeEvents.videoLikeChange, callback);
	},

	removeVideoLikeChangeListener: function(callback) {
		this.removeListener(ChangeEvents.videoLikeChange, callback);
	},

	emitVideoDislikeChange: function(videoId) {
		this.emit(ChangeEvents.videoDislikeChange, videoId);
	},

	addVideoDislikeChangeListener: function(callback) {
		this.on(ChangeEvents.videoDislikeChange, callback);
	},

	removeVideoDislikeChangeListener: function(callback) {
		this.removeListener(ChangeEvents.videoDislikeChange, callback);
	},

	emitGalleryDislikeChange: function(videoId) {
		this.emit(ChangeEvents.galleryDislikeChange, videoId);
	},

	addGalleryDislikeChangeListener: function(callback) {
		this.on(ChangeEvents.galleryDislikeChange, callback);
	},

	removeVGalleryDislikeChangeListener: function(callback) {
		this.removeListener(ChangeEvents.galleryDislikeChange, callback);
	},

	getPincallVerificationById: function(id) {
		return guestData.pincallVerifications[id] ? guestData.pincallVerifications[id] : null;
	},

	canAutoRecharge: function() {
		return guestData.isLoggedIn && guestData.autoRechargeConfig !== null;
	},

	canOneClick: function() {
		return guestData.isLoggedIn && guestData.oneClickConfig !== null;
	},

	getBoughtGifts: function() {
		return guestData.boughtGifts;
	},

	getAutoRechargeAmount: function() {
		return guestData.autoRechargeAmount;
	},

	getAutoRechargeConfig: function(kind, hostId) {
		const config = guestData.autoRechargeConfig;
		if (config) {
			config.kind   = kind;
			config.hostId = hostId;
		}

		return config;
	},

	getBalance: function() {
		return guestData.balance;
	},

	getVoucherFromParam: function() {
		return guestData.voucherFromParam;
	},

	getBalanceFormatted: function() {
		return guestData.balanceFormatted;
	},

	getNotificationSettings: function() {
		return guestData.notificationsSettings;
	},

	getEmailAddress: function() {
		return guestData.email;
	},

	getId: function() {
		return guestData.id;
	},

	getName: function() {
		return guestData.name;
	},

	getOneClickAmounts: function() {
		return guestData.oneClickAmounts;
	},

	getOneClickChargeAmounts: function() {
		return guestData.oneClickChargeAmounts;
	},

	getOneClickConfig: function(kind, hostId) {
		const config = guestData.oneClickConfig;
		if (config) {
			config.kind   = kind;
			config.hostId = hostId;
		}

		return config;
	},

	getAVSStatus: function() {
		return guestData.avsStatus;
	},

	getAVSRejectMessage: function() {
		return guestData.avsRejectMessage;
	},

	hasAvs: function() {
		return !!guestData.hasAvs;
	},

	/**
	 * Get test variant
	 * @param testId
	 * @returns {string|undefined}
	 */
	getTestGroup: function(testId) {
		return guestData.testGroups[testId]?.value;
	},

	isTestActive: function(testId) {
		return Boolean(guestData.testGroups[testId]?.isActive);
	},

	isAnonymousVoicecall: function() {
		return !!guestData.isAnonymousVoicecall;
	},

	isDataProtectionShown: function() {
		return !!guestData.showDataProtection;
	},

	isLocked: function() {
		return !!guestData.isLocked;
	},

	isLoggedIn: function() {
		return !!guestData.isLoggedIn;
	},

	isPayingCustomer: function() {
		return guestData.isPayingCustomer;
	},

	isRestricted: function() {
		return guestData.isRestricted;
	},

	isForcePayment: function() {
		return guestData.isForcePayment;
	},

	isBlockedForComments: function() {
		return guestData.isBlockedForComments;
	},

	isVip: function() {
		return !!guestData.isVip;
	},

	isVXSelect: function() {
		return !!guestData.isVXSelect;
	},

	isVXTVAboSubscriber: function() {
		return !!guestData.isVXTVAboSubscriber;
	},

	wasVXTVAboSubscriber: function() {
		return !!guestData.wasVXTVAboSubscriber;
	},

	isVoicecallActive: function() {
		return !!guestData.isVoicecallActive;
	},

	initGeoLocation: function() {
		initGeoLocation();
	},

	getGeoLocation: function(parsed = false) {
		let geoLocation = guestData.geoLocation;
		if (parsed) {
			geoLocation = ProSearchMetaStore.parseGeoLocation(geoLocation);
		}
		return geoLocation;
	},

	getGuestUrlPreviewPicture: function() {
		return guestData.urlPreviewPicture;
	},

	getSignupOrigin: function() {
		return guestData.signupOrigin;
	},

	setGuestUrlPreviewPicture: function(guestUrlPreviewPicture) {
		guestData.urlPreviewPicture = guestUrlPreviewPicture;
	},

	addGeoLocationChangeListener: function(callback) {
		this.on(ChangeEvents.geoLocation, callback);
	},

	removeGeoLocationChangeListener: function(callback) {
		this.removeListener(ChangeEvents.geoLocation, callback);
	},

	emitGeoLocationChangeEvent: function() {
		this.emit(ChangeEvents.geoLocation);
	},

	addPincallVerificationStartedListener: function(callback) {
		this.on(ChangeEvents.pincallVerificationStarted, callback);
	},

	removePincallVerificationStartedListener: function(callback) {
		this.removeListener(ChangeEvents.pincallVerificationStarted, callback);
	},

	emitPincallVerificationStartedEvent: function(verificationId, errorCode, pinLength) {
		this.emit(ChangeEvents.pincallVerificationStarted, verificationId, errorCode, pinLength);
	},

	addAvsPayRequestListener: function(callback) {
		this.on(ChangeEvents.avsPayRequest, callback);
	},

	removeAvsPayRequestListener: function(callback) {
		this.removeListener(ChangeEvents.avsPayRequest, callback);
	},

	emitAvsPayRequestEvent: function(avsPayRequestResult) {
		this.emit(ChangeEvents.avsPayRequest, avsPayRequestResult);
	},

	addAvsBonusListener: function(callback) {
		this.on(ChangeEvents.avsBonus, callback);
	},

	removeAvsBonusListener: function(callback) {
		this.removeListener(ChangeEvents.avsBonus, callback);
	},

	emitAvsBonusEvent: function(avsPayRequestResult) {
		this.emit(ChangeEvents.avsBonus, avsPayRequestResult);
	},

	addPincallResultReceivedListener: function(callback) {
		this.on(ChangeEvents.pincallResultReceived, callback);
	},

	removePincallResultReceivedListener: function(callback) {
		this.removeListener(ChangeEvents.pincallResultReceived, callback);
	},

	emitPincallResultReceivedEvent: function(verificationId, errorCode) {
		this.emit(ChangeEvents.pincallResultReceived, verificationId, errorCode);
	},

	addConversionChatUpdateEvent: function(callback) {
		this.on(ChangeEvents.conversionChatUpdate, callback);
	},

	removeConversionChatUpdateEvent: function(callback) {
		this.removeListener(ChangeEvents.conversionChatUpdate, callback);
	},

	emitConversionChatUpdateEvent: function(success) {
		this.emit(ChangeEvents.conversionChatUpdate, success);
	},

	getVoucherCountByType: function(type, actorId = null) {
		let count = 0;

		if (!guestData.vouchers) {
			return count;
		}

		for (let i = 0; i < guestData.vouchers.length; i++) {
			const matchType  = type === guestData.vouchers[i].type;
			const matchActor = guestData.vouchers[i].actorId === actorId || !guestData.vouchers[i].actorId;

			if (matchType && matchActor) {
				count += guestData.vouchers[i].amount;
			}
		}

		return count;
	},

	getActiveFeatureIdsWithFeedback: function() {
		return guestData.activeFeatureIdsWithFeedback;
	},

	emitFeatureFeedbackSentEvent: function(success, featureId) {
		this.emit(ChangeEvents.featureFeedbackSent, success, featureId);
	},

	addFeatureFeedbackSentListener: function(callback) {
		this.addListener(ChangeEvents.featureFeedbackSent, callback);
	},

	removeFeatureFeedbackSentListener: function(callback) {
		this.removeListener(ChangeEvents.featureFeedbackSent, callback);
	},

	addGuestRelation: function(relationTypeId, objectTypeId, objectId) {
		addGuestRelation(relationTypeId, objectTypeId, objectId);
	},

	hasGuestRelation: function(relationTypeId, objectTypeId, objectId) {
		return hasGuestRelation(relationTypeId, objectTypeId, objectId);
	},

	emitAddRelationResultEvent: function(success) {
		this.emit(ChangeEvents.addRelationEvent, success);
	},

	addAddRelationResultListener: function(callback) {
		this.on(ChangeEvents.addRelationEvent, callback);
	},

	removeAddRelationResultListener: function(callback) {
		this.removeListener(ChangeEvents.addRelationEvent, callback);
	},

	isEligibleForConversionChat: function() {
		return guestData.conversionChatCountdownEnd > 0 && guestData.conversionChatCountdownEnd > (Date.now() / 1000);
	},

	isCandidateForConversionChat: function() {
		return guestData.conversionChatCandidateCountdownEnd > 0 && guestData.conversionChatCandidateCountdownEnd > (Date.now() / 1000);
	},

	updateConversionChat: function(enable = false) {
		doUpdateConversionChat(enable);
	},

	addGuestPreferences: function(data = {}, callback) {
		addGuestPreferences(data, callback);
	},

	getRestrictionLevel: function() {
		return guestData.restrictionLevel;
	},

	/**
	 *
	 * @param {number} actorId
	 */
	loadFreeChatDuration: function(actorId) {
		if (actorId && guestData.isLoggedIn) {
			VxqlStore.loadFreeChatDuration(handleFreeChatDurationResult, actorId);
		}
	},

	/**
	 *
	 * @param {function} callback
	 * @param {boolean} feedOnly
	 */
	getFavoriteModels: function(callback, feedOnly = false) {
		VxqlStore.getFavoriteModels(callback, feedOnly);
	},

	/**
	 *
	 * @param first
	 * @param feedOnly
	 * @param callback
	 */
	getRecommendedModels: function(first, feedOnly, callback) {
		VxqlStore.getRecommendedModels(first, feedOnly, callback);
	},

	/**
	 *
	 * @param {function} callback
	 */
	getBenefits: function(callback) {
		VxqlStore.getBenefits(callback);
	},

	addBenefitsCodeListener: function(callback) {
		this.on(ChangeEvents.benefitsCode, callback);
	},

	removeBenefitsCodeListener: function(callback) {
		this.removeListener(ChangeEvents.benefitsCode, callback);
	},

	emitBenefitsCodeChange: function(code) {
		this.emit(ChangeEvents.benefitsCode, code);
	},

	/**
	 *
	 * @param {function} callback
	 */
	getMyVisitXSidebarData: function(callback) {
		VxqlStore.getMyVisitXSidebarData(callback);
	},

	/**
	 *
	 * @param {number} actorId
	 */
	emitFreeChatDurationChange: function(actorId) {
		this.emit(ChangeEvents.freeChatDuration, actorId);
	},

	/**
	 *
	 * @param {number} actorId
	 * @returns {number|null}
	 */
	getFreeChatDuration: function(actorId) {
		return Object.hasOwn(_freeChatDurations, actorId) ? _freeChatDurations[actorId] : null;
	},

	/**
	 *
	 * @param {function} callback
	 */
	addFreeChatDurationChangeListener: function(callback) {
		this.on(ChangeEvents.freeChatDuration, callback);
	},

	/**
	 *
	 * @param {function} callback
	 */
	removeFreeChatDurationChangeListener: function(callback) {
		this.removeListener(ChangeEvents.freeChatDuration, callback);
	},

	clearFreeChatDurations: function() {
		_freeChatDurations = [];
		// return null on full-update
		this.emit(ChangeEvents.freeChatDuration, null);
	},

	updateLoyaltyPoints: function(points) {
		guestData.loyaltyPoints = points;
		this.emit(ChangeEvents.loyaltyPointsChanged, points);
	},

	addLoyaltyPointsChangedListener: function(callback) {
		this.on(ChangeEvents.loyaltyPointsChanged, callback);
	},

	removeLoyaltyPointsChangedListener: function(callback) {
		this.removeListener(ChangeEvents.loyaltyPointsChanged, callback);
	},
	addFifteenMinutesPreviewCountdownListener: function(callback) {
		this.on(ChangeEvents.fifteenMinutesPreview, callback);
	},
	removeFifteenMinutesPreviewCountdownListener: function(callback) {
		this.removeListener(ChangeEvents.fifteenMinutesPreview, callback);
	},
    fifteenMinutesPreviewCountdownChange: function(isDone) {
        this.emit(ChangeEvents.fifteenMinutesPreview, isDone);
    },
    setGuestPreferences: function(preferences) {
        guestData.preferences = preferences;
    },
    getGuestPreferences: function() {
        return guestData.preferences;
    },
    setFeatureFeedback: function(featureId, rating) {
        this.emit(ChangeEvents.featureFeedbackSet, featureId, rating);
    },
    addFeatureFeedbackSetListener: function(callback) {
        this.on(ChangeEvents.featureFeedbackSet, callback);
    },
    removeFeatureFeedbackSetListener: function(callback) {
        this.removeListener(ChangeEvents.featureFeedbackSet, callback);
    },
    getGroupForTest(testId) {
        return guestData.testGroups[testId]?.value;
    }
});

GuestStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.ACCEPT_CANCELLATION_OFFER:
			acceptCancellationOffer(action.guestId, action.platformText, action.offerActivationFail, action.offerActivationSuccess);
			break;
		case ActionTypes.CLOSE_DATA_PROTECTION:
			closeDataProtection(action.accepted);
			break;
		case ActionTypes.CLOSE_AGB_AND_DATA_PROTECTION:
			closeAgbAndDataProtection(action.accepted);
			break;
		case ActionTypes.GET_NOTIFICATIONS_SETTINGS:
			getNotificationsSettings(action.onSuccess, action.onFail);
			break;
		case ActionTypes.SEND_VIDEO_SHARE_EMAIL:
			sendVideoShareEmail(action.recipient, action.message, action.videoId, action.guestId);
			break;
		case ActionTypes.NEWSLETTER_SUBSCRIBE:
			subscribeNewsletter(action.typeId, action.userId, action.emailAddress);
			break;
		case ActionTypes.NEWSLETTER_UNSUBSCRIBE:
			unsubscribeNewsletter(action.userId, action.emailAddress, action.option, action.reasons);
			break;
		case ActionTypes.REDEEM_BONUS:
			redeemBonus(action.bonusCode);
			break;
		case ActionTypes.REQUEST_GUEST_BALANCE:
			requestGuestBalance();
			break;
		case ActionTypes.SET_AUTO_RECHARGE_TEASER_STATE:
			setAutoRechargeTeaserState(action.mode);
			break;
		case ActionTypes.SET_VIDEO_LIKE:
			setVideoLike(action.id);
			break;
		case ActionTypes.SET_VIDEO_DISLIKE:
			setVideoDislike(action.id);
			break;
		case ActionTypes.SET_GALLERY_LIKE:
			setGalleryLike(action.id);
			break;
		case ActionTypes.SET_GALLERY_DISLIKE:
			setGalleryDislike(action.id);
			break;
		case ActionTypes.SET_BALANCE_FORMATTED:
			setBalanceFormatted(action.balance, action.balanceFormatted, action.isPayingCustomer);
			break;
		case ActionTypes.SET_VOUCHERS:
			setVouchers(action.vouchers);
			break;
		case ActionTypes.SET_CAN_ONECLICK:
			setCanOneClick(action.oneClickConfig, action.oneClickAmounts, action.autoRechargeConfig, action.autoRechargeAmount, action.isPaying);
			break;
		case ActionTypes.SET_SHOW_DATA_PROTECTION:
			setShowDataProtection(action.showDataProtection);
			break;
		case ActionTypes.SET_AVS_STATUS:
			setAvsStatus(action.avsStatus);
			break;
		case ActionTypes.SET_AVS_REJECT_MESSAGE:
			setAvsRejectMessage(action.rejectMessage);
			break;
		case ActionTypes.SET_HAS_AVS:
			setHasAvs(action.hasAvs);
			break;
		case ActionTypes.SET_IS_LOCKED:
			setIsLocked(action.isLocked);
			break;
		case ActionTypes.SET_IS_VIP:
			setIsVip(action.isVip);
			break;
		case ActionTypes.SET_IS_VXSELECT:
			setIsVXSelect(action.isVXSelect);
			break;
		case ActionTypes.SET_IS_VOICECALL_ACTIVE:
			setIsVoicecallActive(action.isVoicecallActive);
			break;
		case ActionTypes.SET_IS_VXTV_ABO_SUBSCRIBER:
			setIsVXTVAboSubscriber(action.isVXTVAboSubscriber);
			break;
		case ActionTypes.SET_WAS_VXTV_ABO_SUBSCRIBER:
			setWasVXTVAboSubscriber(action.wasVXTVAboSubscriber);
			break;
		case ActionTypes.REMOVE_REDEEMABLE_GIFTS:
			removeRedeemableGifts(action.giftIds);
			break;
		case ActionTypes.SEND_FEATURE_FEEDBACK:
			sendFeatureFeedback(action.featureId, action.rating, action.text, action.url);
			break;
		case ActionTypes.START_PINCALL_VERIFICATION:
			startPincallVerification(action.phoneNumber, action.verificationType, action.language);
			break;
		case ActionTypes.PERPARE_AVS_PAY_REQUEST:
			perpareAvsPayRequest();
			break;
		case ActionTypes.AVS_BONUS_AVAILABLE:
			isAvsBonusAvailable();
			break;
		case ActionTypes.VERIFY_PINCALL:
			verifyPincall(action.id, action.verificationType, action.pin);
			break;
		case ActionTypes.BENEFITS_CODE:
			benefitsCodeChange(action.code);
			break;

		default:
	}
});

export default GuestStore;
