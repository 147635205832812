'use strict';

import Dispatcher            from '../Dispatcher';
import Constants             from '../Constants';
import Routes                from './../../utils/Routes';
import FluxEventEmitter      from '../FluxEventEmitter';
import assign                from 'object-assign';
import {doFetch, gtagHelper} from '../../utils/CommonUtils';
import Flux                  from '../../flux/Flux';
import EnumGA4Events         from "../../utils/EnumGA4Events";
import {trackPurchase}       from '../../utils/DataLayerHelper';

const ActionTypes  = Constants.ActionTypes.Gallery;
const ChangeEvents = {
	Gallery:                        'gallery',
	GalleryRating:                  'galleryRating',
	PictureRating:                  'pictureRating',
	Comments:                       'comments',
	PinnedPhotos:                   'pinnedPhotos',
	CurrentVideoTimeEvent:          'currentVideoTimeEvent',
	CurrentVideoCinematicModeEvent: 'currentVideoCinematicModeEvent',
	Preview:                        'preview',
	FocusCommentsInput:             'focusCommentsInput',
	EnableFeedback:                 'enableFeedback',
    PauseVideo:                     'pauseVideo',
};

const galleryData = {};
clearGalleryData();

const videoPlayerData = {
	currentVideoTime: 0,
	cinematicMode:    false,
};

function clearGalleryData() {
    galleryData.albumData        = [];
    galleryData.pictureData      = [];
    galleryData.sedcardsData     = [];
    galleryData.commentsData     = [];
    galleryData.pinnedPhotosData = {};
    galleryData.previewData      = {};
}

function initSedcardsData(actorId, initValues) {
	initValues = initValues || {};

	if (galleryData.sedcardsData[actorId] !== undefined) {
		return;
	}

	galleryData.sedcardsData[actorId] = {
		albumTotalViews:     initValues.albumTotalViews || 0,
		guestRatingLikes:    initValues.guestRatingLikes || 0,
		guestRatingDislikes: initValues.guestRatingDislikes || 0,
		isLiked:             initValues.isLiked || false,
		isDisliked:          initValues.isDisliked || false,
	};
}

function initPictureData(pictureId, initValues) {
	initValues = initValues || {};

	if (galleryData.pictureData[pictureId] !== undefined) {
		return;
	}

	galleryData.pictureData[pictureId] = {
		guestRatingLikes:    initValues.guestRatingLikes || 0,
		guestRatingDislikes: initValues.guestRatingDislikes || 0,
		isLiked:             initValues.isLiked || false,
		isDisliked:          initValues.isDisliked || false,
	};
}

function getSedcards(actorId) {
	return doFetch(
		Routes.getRoute(Routes.Names.GALLERY_GET_SEDCARDS, {actorIdOrName: actorId}),
		{
			actorId: actorId,
		},
		Constants.HttpMethods.GET,
		true
	);
}

function loadSedcards(actorId) {
	if (typeof galleryData.sedcardsData[actorId] === 'undefined' || !galleryData.sedcardsData[actorId].photos) {
		getSedcards(actorId).then(function(res) {
			if (res.data) {
				galleryData.sedcardsData[actorId] = res.data;
				GalleryStore.emitGalleryChange(actorId);
			}
		});
	} else {
		GalleryStore.emitGalleryChange(actorId);
	}
}

function setSedcardsGuestRating(actorId, guestRating) {
	doFetch(
		Routes.getRoute(Routes.Names.GALLERY_SET_SEDCARDS_GUEST_RATING, {actorId: actorId}),
		{
			guestRating: guestRating,
		},
		Constants.HttpMethods.POST,
		true
	).then(function(res) {
		initSedcardsData(actorId);

		if (res.data && res.data.success) {
			galleryData.sedcardsData[actorId].albumTotalViews     = res.data.albumTotalViews;
			galleryData.sedcardsData[actorId].guestRatingLikes    = res.data.guestRatingLikes;
			galleryData.sedcardsData[actorId].guestRatingDislikes = res.data.guestRatingDislikes;
			galleryData.sedcardsData[actorId].isLiked             = res.data.isLiked;
			galleryData.sedcardsData[actorId].isDisliked          = res.data.isDisliked;
		}
		GalleryStore.emitGalleryRatingChange(actorId);
	});
}

function initCommentsData(albumId) {
	if (galleryData.commentsData[albumId] !== undefined) {
		return;
	}

	galleryData.commentsData[albumId] = {
		comments:                     [],
		commentsTotal:                0,
		isGuestAllowedToCommentAlbum: true,
	};
}

function initGalleryData(albumId, initValues) {
	initValues = initValues || {};

	if (galleryData.albumData[albumId] !== undefined) {
		return;
	}

	galleryData.albumData[albumId] = {
		albumTotalViews:     initValues.albumTotalViews || 0,
		guestRatingLikes:    initValues.guestRatingLikes || 0,
		guestRatingDislikes: initValues.guestRatingDislikes || 0,
		isLiked:             initValues.isLiked || false,
		isDisliked:          initValues.isDisliked || false,
	};
}

function getGalleryComments(albumId, limit, offset) {
	return doFetch(
		Routes.getRoute(Routes.Names.GALLERY_PRIVATE_SHOP_GET_COMMENTS),
		{
			albumId: albumId,
			limit:   limit,
			offset:  offset,
		},
		Constants.HttpMethods.GET,
		true
	);
}

function postComment(albumId, commentText) {
	return doFetch(
		Routes.getRoute(Routes.Names.GALLERY_PRIVATE_SHOP_POST_COMMENT),
		{
			albumId:     albumId,
			commentText: commentText,
		},
		Constants.HttpMethods.POST,
		true
	);
}

function postGuestRating(albumId, guestRating) {
	return doFetch(
		Routes.getRoute(Routes.Names.GALLERY_PRIVATE_SHOP_SET_GUEST_RATING),
		{
			albumId:     albumId,
			guestRating: guestRating,
		},
		Constants.HttpMethods.POST,
		true
	);
}

function postPictureGuestRating(pictureId, guestRating) {
	return doFetch(
		Routes.getRoute(Routes.Names.GALLERY_SET_PICTURE_GUEST_RATING),
		{
			pictureId:   pictureId,
			guestRating: guestRating,
		},
		Constants.HttpMethods.POST,
		true
	);
}

function showMoreComments(albumId) {
	const isInitialized = galleryData.commentsData[albumId] !== undefined;

	initCommentsData(albumId);

	const comments = galleryData.commentsData[albumId].comments;
	const offset   = comments.length;
	const limit    = 5;

	getGalleryComments(albumId, limit, offset).then(function(res) {
		if (res.data && res.data.comments.length > 0) {
			galleryData.commentsData[albumId].commentsTotal                = res.data.total;
			galleryData.commentsData[albumId].comments                     = comments.concat(res.data.comments);
			galleryData.commentsData[albumId].isGuestAllowedToCommentAlbum = res.data.isGuestAllowedToCommentAlbum;
			GalleryStore.emitCommentsChange(albumId);
		} else if (res.data && !isInitialized) {
			GalleryStore.emitCommentsChange(albumId);
		}
	});
}

function setGuestRating(albumId, rating) {
	initGalleryData(albumId);

	postGuestRating(albumId, rating).then(function(res) {
		if (res.success && res.data && galleryData.albumData[albumId] !== undefined) {
			galleryData.albumData[albumId].albumTotalViews     = res.data.albumTotalViews;
			galleryData.albumData[albumId].guestRatingLikes    = res.data.guestRatingLikes;
			galleryData.albumData[albumId].guestRatingDislikes = res.data.guestRatingDislikes;
			galleryData.albumData[albumId].isLiked             = res.data.isLiked;
			galleryData.albumData[albumId].isDisliked          = res.data.isDisliked;

			GalleryStore.emitGalleryRatingChange(null, albumId);
		}
	});
}

function setPictureGuestRating(pictureId, rating) {
	initPictureData(pictureId);

	postPictureGuestRating(pictureId, rating).then(function(res) {
		if (res.success && res.data && galleryData.pictureData[pictureId] !== undefined) {
			galleryData.pictureData[pictureId].guestRatingLikes    = res.data.guestRatingLikes;
			galleryData.pictureData[pictureId].guestRatingDislikes = res.data.guestRatingDislikes;
			galleryData.pictureData[pictureId].isLiked             = res.data.isLiked;
			galleryData.pictureData[pictureId].isDisliked          = res.data.isDisliked;

			GalleryStore.emitPictureRatingChange(pictureId);
		}
	});
}

function getGalleryData(albumId, params = {}) {
	const reqParams   = params;
	reqParams.albumId = albumId;

	return doFetch(
		Routes.getRoute(Routes.Names.GALLERY), reqParams,
		Constants.HttpMethods.GET,
		true
	).then(function(res) {
		galleryData.albumData[albumId] = res.data;
		GalleryStore.emitGalleryChange(res.data && res.data.actor ? res.data.actor.id : null, albumId);
	});
}


function loadPinnedPhotosData(params = {}) {
	const key     = getKey(params);
	const hasData = galleryData.pinnedPhotosData && typeof galleryData.pinnedPhotosData[key] !== 'undefined';

	if (!hasData) {
		doFetch(
			Routes.getRoute(Routes.Names.GALLERY_GET_PINNED_PHOTOS), params,
			Constants.HttpMethods.GET,
			true
		).then(function(res) {
			galleryData.pinnedPhotosData[key] = res.data;
			GalleryStore.emitPinnedPhotosChange();
		});
	} else {
		GalleryStore.emitPinnedPhotosChange();
	}
}


function updateGalleryData(albumId, useCache) {
	useCache = useCache || false;

	return new Promise(resolve => {
		if (!useCache) {
			getGalleryData(albumId)
				.then(() => {
					resolve();
				});
		} else {
			GalleryStore.emitGalleryChange(null, albumId);
			resolve();
		}
	});
}

function addComment(albumId, commentText) {
	postComment(albumId, commentText).then(function(res) {
		if (res.data) {
			initGalleryData(albumId);

			galleryData.commentsData[albumId].comments.unshift(res.data);
			galleryData.commentsData[albumId].commentsTotal += 1;
			galleryData.commentsData[albumId].isGuestAllowedToCommentAlbum = false;
			GalleryStore.emitCommentsChange(albumId);
		}
	});
}

function buyAlbum(albumId, {item_name = EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_PLATFORM_CONTENT} = {}) {
	const url = Routes.getRoute(Routes.Names.ALBUM_BUY).replace(':albumId', albumId);
	doFetch(url, null, Constants.HttpMethods.POST, true).then(function(result) {
		if (result.success && result.data.success) {
			if (typeof result.data.guestBalanceFormatted === 'string') {
				Flux.Guest.setBalanceFormatted(result.data.guestBalance, result.data.guestBalanceFormatted);
			}
			updateGalleryData(albumId, false).then(() => {
				const album = galleryData.albumData[albumId];
				if (album) {
					gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.DEFAULT_EVENT_PURCHASE, {
						[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: album.id + ":" + Flux.Guest.getId(), // required, pseudo transaction id
						[EnumGA4Events.DEFAULT_PARAMETER_CURRENCY]:       EnumGA4Events.DEFAULT_PARAMETER_CURRENCY__VALUE_EUR,
						[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          album.price,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [{
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_ID]:    album.id,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_BRAND]: album.actor.name,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:  album.title,
						}]
					});

					trackPurchase({
						[EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID]: album.id + ":" + Flux.Guest.getId(), // required, pseudo transaction id
						[EnumGA4Events.DEFAULT_PARAMETER_VALUE]:          album.price,
						[EnumGA4Events.DEFAULT_PARAMETER_ITEMS]:          [{
							[EnumGA4Events.DEFAULT_PARAMETER_ITEM_ID]:              album.id,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_BRAND]:    album.actor.name,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME]:     item_name,
							[EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY]: album.type === Flux.Constants.MediaTypes.VIDEO ? EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_VIDEO : EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_IMAGE_ALBUM,
						}]
					});
				}
			});
		}
	});
}

function loadPreview(actorName, albumId, isMobile) {
	doFetch(Routes.getRoute(Routes.Names.GALLERY_GET_PREVIEW), {actorName, albumId, isMobile}, Constants.HttpMethods.POST, true)
		.then(function(result) {
			const parsed                         = JSON.parse(result.data);
			galleryData.previewData.context      = parsed['context'];
			galleryData.previewData.previewTitle = parsed['previewTitle'];
			galleryData.previewData.urls         = parsed['urls'];
			GalleryStore.emitPreviewChange();
		})
		.catch(function() {
		});
}

function getKey(request) {
	const keys = [];
	for (const r in request) {
		if (Object.hasOwn(request, r)) {
			keys.push(r);
		}
	}

	keys.sort();

	const keyObject = {};
	for (let k = 0; k < keys.length; k++) {
		keyObject[keys[k]] = request[keys[k]];
	}

	return JSON.stringify(keyObject);
}

const GalleryStore = assign({}, FluxEventEmitter.prototype, {

	getGalleryData: function(albumId) {
		return galleryData.albumData[albumId];
	},

	getPictureData: function(pictureId) {
		return galleryData.pictureData[pictureId];
	},

	getCommentsData: function(albumId) {
		return galleryData.commentsData[albumId];
	},

	buyAlbum: function(albumId) {
		buyAlbum(albumId);
	},

	emitGalleryChange: function(actorId, albumId) {
		this.emit(ChangeEvents.Gallery, actorId, albumId);
	},

	addGalleryChangeListener: function(callback) {
		this.on(ChangeEvents.Gallery, callback);
	},

	removeGalleryChangeListener: function(callback) {
		this.removeListener(ChangeEvents.Gallery, callback);
	},

	emitGalleryRatingChange: function(actorId, albumId) {
		this.emit(ChangeEvents.GalleryRating, actorId, albumId);
	},

	addGalleryRatingChangeListener: function(callback) {
		this.on(ChangeEvents.GalleryRating, callback);
	},

	removeGalleryRatingChangeListener: function(callback) {
		this.removeListener(ChangeEvents.GalleryRating, callback);
	},

	emitPictureRatingChange: function(pictureId) {
		this.emit(ChangeEvents.PictureRating, pictureId);
	},

	addPictureRatingChangeListener: function(callback) {
		this.on(ChangeEvents.PictureRating, callback);
	},

	removePictureRatingChangeListener: function(callback) {
		this.removeListener(ChangeEvents.PictureRating, callback);
	},

	emitCommentsChange: function(albumId) {
		this.emit(ChangeEvents.Comments, albumId);
	},

	addCommentsChangeListener: function(callback) {
		this.on(ChangeEvents.Comments, callback);
	},

	removeCommentsChangeListener: function(callback) {
		this.removeListener(ChangeEvents.Comments, callback);
	},

	getSedcards: function(actorId) {
		return galleryData.sedcardsData[actorId];
	},

	initGalleryData: function(albumId, data) {
		initGalleryData(albumId, data);
	},

	initSedcardsData: function(actorId, data) {
		initSedcardsData(actorId, data);
	},

	loadGallery: function(albumId, params) {
		getGalleryData(albumId, params);
	},

	addPinnedPhotosChangeListener: function(callback) {
		this.on(ChangeEvents.PinnedPhotos, callback);
	},

	removePinnedPhotosChangeListener: function(callback) {
		this.removeListener(ChangeEvents.PinnedPhotos, callback);
	},

	emitPinnedPhotosChange: function() {
		this.emit(ChangeEvents.PinnedPhotos);
	},

	loadPinnedPhotos: function(params = {}) {
		loadPinnedPhotosData(params);
	},

	getPinnedPhotos: function(params = {}) {
		const key = getKey(params);
		return galleryData.pinnedPhotosData[key];
	},

	getCurrentVideoTime: function() {
		return videoPlayerData.currentVideoTime;
	},

	setCurrentVideoTime: function(time) {
		videoPlayerData.currentVideoTime = time;
		this.emit(ChangeEvents.CurrentVideoTimeEvent);
	},

	addCurrentVideoTimeChangeListener: function(callback) {
		this.on(ChangeEvents.CurrentVideoTimeEvent, callback);
	},

	removeCurrentVideoTimeChangeListener: function(callback) {
		this.removeListener(ChangeEvents.CurrentVideoTimeEvent, callback);
	},

	isCinematicModeEnabled: function() {
		return videoPlayerData.cinematicMode;
	},

	setCinematicModeEnabled: function(enabled) {
		videoPlayerData.cinematicMode = enabled;
		this.emit(ChangeEvents.CurrentVideoCinematicModeEvent, enabled);
	},

	addCurrentVideoCinematicModeListener: function(callback) {
		this.on(ChangeEvents.CurrentVideoCinematicModeEvent, callback);
	},

	removeCurrentVideoCinematicModeListener: function(callback) {
		this.removeListener(ChangeEvents.CurrentVideoCinematicModeEvent, callback);
	},

	loadPreview: function(actorName, albumId) {
		loadPreview(actorName, albumId);
	},

	emitPreviewChange: function() {
		this.emit(ChangeEvents.Preview);
	},

	getPreview: function() {
		return galleryData.previewData;
	},

	addPreviewChangeListener: function(callback) {
		this.on(ChangeEvents.Preview, callback);
	},

	removePreviewChangeListener: function(callback) {
		this.removeListener(ChangeEvents.Preview, callback);
	},

	/**
	 * @param {function} callback
	 */
	addFocusCommentsInputChangeListener: function(callback) {
		this.on(ChangeEvents.FocusCommentsInput, callback);
	},

	/**
	 * @param {function} callback
	 */
	removeFocusCommentsInputChangeListener: function(callback) {
		this.removeListener(ChangeEvents.FocusCommentsInput, callback);
	},

	focusCommentsInput: function() {
		this.emit(ChangeEvents.FocusCommentsInput);
	},

	/**
	 * @param {function} callback
	 */
	addEnableFeedbackListener: function(callback) {
		this.on(ChangeEvents.EnableFeedback, callback);
	},

	/**
	 * @param {function} callback
	 */
	removeEnableFeedbackListener: function(callback) {
		this.removeListener(ChangeEvents.EnableFeedback, callback);
	},

	enableFeedback: function() {
		this.emit(ChangeEvents.EnableFeedback);
	},

    pauseVideo: function() {
        this.emit(ChangeEvents.PauseVideo);
    },
    addVideoPauseListener: function(callback) {
        this.on(ChangeEvents.PauseVideo, callback);
    },
    removeVideoPauseListener: function(callback) {
        this.removeListener(ChangeEvents.PauseVideo, callback);
    },
    clearCache: function() {
        clearGalleryData();
    }
});

GalleryStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.BUY_ALBUM:
			buyAlbum(action.albumId, action.trackerContext);
			break;
		case ActionTypes.LOAD_SEDCARDS:
			loadSedcards(action.actorId);
			break;
		case ActionTypes.GALLERY_SET_SEDCARDS_GUEST_RATING:
			setSedcardsGuestRating(action.actorId, action.rating);
			break;
		case ActionTypes.UPDATE_GALLERY_DATA:
			void updateGalleryData(action.albumId, action.useCache);
			break;
		case ActionTypes.ADD_COMMENT:
			addComment(action.albumId, action.commentText);
			break;
		case ActionTypes.SHOW_MORE_COMMENTS:
			showMoreComments(action.albumId);
			break;
		case ActionTypes.SET_GUEST_RATING:
			setGuestRating(action.albumId, action.rating);
			break;
		case ActionTypes.SET_PICTURE_GUEST_RATING:
			setPictureGuestRating(action.pictureId, action.rating);
			break;
		case ActionTypes.LOAD_PREVIEW:
			loadPreview(action.actorName, action.albumId, action.isMobile);
			break;
		default:
	}
});

export default GalleryStore;
