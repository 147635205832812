import Dispatcher            from './../Dispatcher';
import DailyBonusActionTypes from './Types/DailyBonusActionTypes';

const DailyBonusActionCreator = {

	reload: function() {
		Dispatcher.dispatch({
			type: DailyBonusActionTypes.RELOAD,
		});
	},

	/**
	 * @param {Number} limit
	 * @param {String} order
	 */
	loadInteractiveContent: function(limit, order) {
		Dispatcher.dispatch({
			type: DailyBonusActionTypes.LOAD_INTERACTIVE_CONTENT,
			limit,
			order,
		});
	},

	/**
	 * @param {Number} limit
	 * @param {String} order
	 * @param {Array} content
	 */
	loadedInteractiveContent: function(limit, order, content) {
		Dispatcher.dispatch({
			type: DailyBonusActionTypes.LOADED_INTERACTIVE_CONTENT,
			limit,
			order,
			content,
		});
	},

	/**
	 * @param {String} date
	 * @param {Boolean} isMobile
	 */
	requestEventConfig: function(date, isMobile = false) {
		Dispatcher.dispatch({
			type: DailyBonusActionTypes.LOAD_EVENT_CONFIG,
			date,
			isMobile,
		});
	},

	/**
	 * @param {String} date
	 * @param {Boolean} isMobile
	 * @param {Object|null} event
	 */
	loadedEventConfig: function(date, isMobile = false, event = null) {
		Dispatcher.dispatch({
			type: DailyBonusActionTypes.LOADED_EVENT_CONFIG,
			date,
			isMobile,
			event,
		});
	},
};

export default DailyBonusActionCreator;
