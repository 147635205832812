import FluxEventEmitter       from './../FluxEventEmitter';
import assign                 from 'object-assign';
import Dispatcher             from './../Dispatcher';
import Constants              from './../Constants';
import Routes                 from './../../utils/Routes';
import BlogPostsActionCreator from './../actions/BlogPostsActionCreator';
import {doFetch}              from "../../utils/CommonUtils";

let excluded   = [],
	categories = {},
	totals     = {},
	route      = '';

let posts = {
	models: [],
	total: 0,
};

// defaults = 0
totals[Constants.Blog.CategoryIds.NEWS]       = 0;
totals[Constants.Blog.CategoryIds.ACTIONS]    = 0;
totals[Constants.Blog.CategoryIds.INTERVIEWS] = 0;
totals[Constants.Blog.CategoryIds.EVENT]      = 0;

const BlogPostsStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @param {Object} blogsHomePagePm
	 */
	init: blogsHomePagePm => {
		// set excludes & categories map to store
		BlogPostsStore.setExcludeIds(blogsHomePagePm.exclude);
		BlogPostsStore.setCategories(blogsHomePagePm.categories);
		BlogPostsStore.setTotals(blogsHomePagePm.totals);
		BlogPostsStore.setRoute(blogsHomePagePm.route);
	},

	/**
	 * @return {Number[]}
	 */
	getExcludeIds: () => excluded,

	/**
	 * @param {Number[]} ids
	 */
	setExcludeIds: ids => {
		excluded = ids;
	},

	/**
	 * @param {Object} map
	 */
	setCategories: map => {
		categories = map;
	},

	/**
	 * @param {Number} id
	 * @return {String}
	 */
	getCategoryById: id => categories[id],

	/**
	 * @param {Number} counts
	 */
	setTotals: counts => {
		totals = counts;
	},

	/**
	 * @param {String} routeTpl
	 */
	setRoute: routeTpl => {
		route = routeTpl;
	},

	/**
	 * @param {String} category
	 * @return {boolean}
	 */
	hasMore: category => totals[category] > 0,

	/**
	 * @param {String} slug
	 * @return {string}
	 */
	getPostLink: slug => route.replace('{slug}', slug),

	/**
	 * @param {String} categoryId
	 * @param {Function} callback
	 */
	setOnPostsRequestedListener: function(categoryId, callback) {
		this.on(BlogPostsActionCreator.getPostsRequestedType(categoryId), callback);
	},

	/**
	 * @param {String} categoryId
	 * @param {Function} callback
	 */
	removeOnPostsRequestedListener: function(categoryId, callback) {
		this.removeListener(BlogPostsActionCreator.getPostsRequestedType(categoryId), callback);
	},

	/**
	 * @param {Number} categoryId
	 * @param {Function} callback
	 */
	setOnPostsReceivedListener: function(categoryId, callback) {
		this.on(BlogPostsActionCreator.getPostsReceivedType(categoryId), callback);
	},

	/**
	 *  @param {Number} categoryId
	 * @param {Function} callback
	 */
	removeOnPostsReceivedListener: function(categoryId, callback) {
		this.removeListener(BlogPostsActionCreator.getPostsReceivedType(categoryId), callback);
	},

	/**
	 * @param {Function} callback
	 */
	setOnPmReceivedListener: function(callback) {
		this.on(BlogPostsStore.Event.PM_RECEIVED, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeOnPmReceivedListener: function(callback) {
		this.removeListener(BlogPostsStore.Event.PM_RECEIVED, callback);
	},

	/**
	 * @param {Function} callback
	 */
	setOnArchivesPmReceivedListener: function(callback) {
		this.on(BlogPostsStore.Event.ARCHIVES_PM_RECEIVED, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeOnArchivesPmReceivedListener: function(callback) {
		this.removeListener(BlogPostsStore.Event.ARCHIVES_PM_RECEIVED, callback);
	},

	/**
	 * @param {String} category
	 * @param {Number} year
	 * @param {Function} callback
	 */
	setOnArchivePostsReceivedListener: function(category, year, callback) {
		this.on(
			BlogPostsActionCreator.getArchivePostsReceivedType(category, year),
			callback
		);
	},

	/**
	 * @param {String} category
	 * @param {Number} year
	 * @param {Function} callback
	 */
	removeOnArchivePostsReceivedListener: function(category, year, callback) {
		this.removeListener(
			BlogPostsActionCreator.getArchivePostsReceivedType(category, year),
			callback
		);
	},

	/**
	 * @param {Number} category
	 * @param {Object} response
	 */
	emitPostsReceived: (category, response) => {
		// merge post ids to exclude further
		excluded = [
			...excluded,
			...response.data.models.map(m => m.id),
		];

		// remember totals
		totals[category] = response.data.total;

		// send event (incremental)
		BlogPostsStore.emit(
			BlogPostsActionCreator.getPostsReceivedType(category),
			category,
			response.data.models,
			response.data.total
		);
	},

	/**
	 * @param {String} category
	 * @param {Number} year
	 * @param {Object} response
	 */
	emitArchivesReceived: (category, year, response) => {
		const posts = Object.hasOwn(response.data, 'archives')
			? response.data.archives.filter(a => a.year === year).map(a => a.posts).shift()
			: [];

		// merge post ids to exclude further
		excluded = [
			...excluded,
			...posts.map(m => m.id),
		];

		// remember totals & categories & route template
		totals[category] = response.data.total;
		categories       = response.data.categories;
		route            = response.data.route;

		// send event (incremental)
		BlogPostsStore.emit(
			BlogPostsActionCreator.getArchivePostsReceivedType(category, year),
			category,
			year,
			posts,
			response.data.total
		);
	},

	/**
	 * @param {Object} response
	 */
	emitPmReceived(response) {
		// init self for further fetches
		BlogPostsStore.init({
			exclude:    response.data.exclude,
			categories: response.data.categories,
			totals:     response.data.totals,
			route:      response.data.route,
		});

		// send event (incremental)
		BlogPostsStore.emit(
			Constants.ActionTypes.Blog.PM_RECEIVED,
			response.data.featured,
			response.data.posts[Constants.Blog.CategoryAlias.LATEST],
			response.data.posts[Constants.Blog.CategoryIds.NEWS],
			response.data.posts[Constants.Blog.CategoryIds.ACTIONS],
			response.data.posts[Constants.Blog.CategoryIds.INTERVIEWS],
			response.data.posts[Constants.Blog.CategoryIds.EVENT],
			response.data.archivesRoute
		);
	},

	/**
	 * @param {String} category
	 * @param {Object} response
	 */
	emitArchivesPmReceived(category, response) {
		// save categories map + route template
		BlogPostsStore.setCategories(response.data.categories);
		BlogPostsStore.setRoute(response.data.route);

		BlogPostsStore.emit(
			Constants.ActionTypes.Blog.ARCHIVES_PM_RECEIVED,
			response.data.archives,
			category,
			response.data.categoryId
		);
	},
	getPostsFromStore() {
		return posts;
	},
});

/**
 * @param {Number} category
 * @param {Number} page
 * @param {Number} pageSize
 * @private
 */
const _fetchPosts = (category, page, pageSize) => {
	// combine route
	const handler = BlogPostsStore.emitPostsReceived.bind(null, category),
	      route   = Routes.getRoute(Routes.Names.BLOG, {
		      categoryId: category,
		      payload:    JSON.stringify({
			      page:     page,
			      pageSize: pageSize,
			      exclude:  BlogPostsStore.getExcludeIds(),
		      }),
	      });

	// fetch
	doFetch(route, null, Constants.HttpMethods.GET, false, {headers: {'Cache-Control': 'max-age=60', 'Pragma': ''}})
	// call in any case - OK or ERROR
		.then((response) => {
			posts = response.data;
			return response;
		})
		.then(handler, handler);
};

/**
 * @param {String} category
 * @param {Number} year
 * @param {Number} page
 * @param {Number} pageSize
 * @private
 */
const _fetchArchives = (category, year, page, pageSize) => {
	// combine route
	const handler = BlogPostsStore.emitArchivesReceived.bind(null, category, year),
	      route   = Routes.getRoute(
		      Routes.Names.BLOG_ARCHIVES,
		      {category: category},
		      {
			      page:     page,
			      pageSize: pageSize,
			      year:     year,
		      }
	      );

	// fetch
	doFetch(route, null, Constants.HttpMethods.GET)
		// call in any case - OK or ERROR
		.then(handler, handler);
};

/**
 * @param {String} route
 * @param {Function} callback
 * @private
 */
const _fetchPM = (route, callback) => {
	// fetch
	doFetch(route, null, Constants.HttpMethods.GET)
		// call in any case - OK or ERROR
		.then(callback, callback);
};

BlogPostsStore.dispatchToken = Dispatcher.register((action) => {
	switch (action.type) {
		// magazine home page PM fetch (mobile magazine)
		case Constants.ActionTypes.Blog.PM_FETCH:
			_fetchPM(Routes.getRoute(Routes.Names.BLOG_PM), BlogPostsStore.emitPmReceived);
			break;

		// fetch archives posts (mobile + desktop archives page)
		case Constants.ActionTypes.Blog.ARCHIVES_FETCH:
			_fetchArchives(action.category, action.year, action.page, action.pageSize);
			break;

		// fetch archives PM (mobile archives page)
		case Constants.ActionTypes.Blog.ARCHIVES_PM_FETCH:
			_fetchPM(
				Routes.getRoute(Routes.Names.BLOG_ARCHIVES, {category: action.category}),
				BlogPostsStore.emitArchivesPmReceived.bind(null, action.category)
			);
			break;

		// fetch posts (mobile + desktop magazine home)
		default:
			if (BlogPostsActionCreator.isFetchEvent(action.type)) {
				_fetchPosts(action.category, action.page, action.pageSize);
			}

			break;
	}
});

BlogPostsStore.PAGE_SIZE          = Constants.Blog.PageSize.default;
BlogPostsStore.PAGE_SIZE_ARCHIVES = Constants.Blog.PageSize.archives;

BlogPostsStore.Event = {
	RECEIVED:              Constants.ActionTypes.Blog.WP_BLOG_POSTS_RECEIVED,
	REQUESTED:             Constants.ActionTypes.Blog.WP_BLOG_POSTS_FETCH,
	PM_REQUESTED:          Constants.ActionTypes.Blog.PM_FETCH,
	PM_RECEIVED:           Constants.ActionTypes.Blog.PM_RECEIVED,
	ARCHIVES_PM_REQUESTED: Constants.ActionTypes.Blog.ARCHIVES_PM_FETCH,
	ARCHIVES_PM_RECEIVED:  Constants.ActionTypes.Blog.ARCHIVES_PM_RECEIVED,
};

export default BlogPostsStore;
