/* global VXConfig */
import FluxEventEmitter        from '../FluxEventEmitter';
import assign                  from 'object-assign';
import {doFetch, urlWithQuery} from '../../utils/CommonUtils';
import Constants               from '../Constants';

const VXQL_URL          = VXConfig.vxqlUrl;
const SERVICE_URL       = VXConfig.tagsServiceUrl;
const LANG              = VXConfig.language;
const VXQL_ACCESS_TOKEN = VXConfig.vxqlAccessToken;

const CHANGE_EVENTS      = {
	mostPopularTags: 'mostPopularTags',
};

let _mostPopularTags = null;



/**
 *
 * @param {string} query
 * @param {Object} variables
 * @param {Object} queryParam
 * @returns {Promise}
 */
function doServiceRequest(query, variables = null, queryParam = {}, url) {
	const queryObject = {};
	queryObject.query = query;

	if (variables) {
		queryObject.variables = variables;
	}

	const init = {
		headers: {'Authorization': 'Bearer ' + VXQL_ACCESS_TOKEN},
	};

	const requestUrl = urlWithQuery(url, {
		...queryParam,
		language: LANG,
	});

	return doFetch(requestUrl, queryObject, Constants.HttpMethods.POST, false, init);
}

/**
 *
 * @param {String} search
 * @param {Function} callback
 */
function searchTags(search, callback) {
	const query = `
	query($search: String){
		tagSearch (search: $search){
			tags {
				id
				label
			}
		}
	}`;

	doServiceRequest(query, {search}, null, SERVICE_URL).then(callback);
}

/**
 *
 * @param {Int} first
 * @param {Function} callback
 */
function getMostPopularTags(first, callback) {
	const query = `
	query($first: Int){
		tags {
		  mostPopular(first: $first) {
			label
		  }
		}
	  }`;

	doServiceRequest(query, {first}, null, VXQL_URL).then(callback);
}

/**
 *
 * @param {Int} first
 */
function loadMostPopularTags(first) {
	if (_mostPopularTags && _mostPopularTags.length === first) {
		TagsStore.emit(CHANGE_EVENTS.mostPopularTags);
	} else {
		getMostPopularTags(first, (result) => {
			if(result && result.data && result.data.tags && result.data.tags.mostPopular) {
				_mostPopularTags = result.data.tags.mostPopular.map(tag => tag.label);
				TagsStore.emit(CHANGE_EVENTS.mostPopularTags);
			}
		});
	}
}

const TagsStore = assign({}, FluxEventEmitter.prototype, {
	searchTags(search, callback) {
		searchTags(search, callback);
	},

	getMostPopularTags() {
		return _mostPopularTags;
	},

	loadMostPopularTags(first) {
		loadMostPopularTags(first);
	},

	addMostPopularDataChangeListener(callback) {
		this.on(CHANGE_EVENTS.mostPopularTags, callback);
	},

	removeMostPopularDataChangeListener(callback) {
		this.removeListener(CHANGE_EVENTS.mostPopularTags, callback);
	},
});


export default TagsStore;

