import FluxEventEmitter  from '../FluxEventEmitter';
import assign            from 'object-assign';
import { doVxqlRequest } from './VxqlStore';

let _actors = [];
let _videos = [];

const SlotmachineStore = assign({}, FluxEventEmitter.prototype, {
	getActorsByIds: function(ids, callback) {
        if (_actors.length > 0) {
            callback(_actors);
        } else {
            const clb = (data) => {
                _actors = [...data.data.models_v2.items];
                callback(_actors);
            };
            loadActors(ids, clb);
        }
	},
	getVideosByIds: function(videos, callback) {
        if (_videos.length > 0) {
            callback(_videos);
        } else {
            const clb = (data) => {
                _videos = data.data;
                callback(_videos);
            };
            loadVideos(videos, clb);
        }
	},
});

function loadActors(ids, callback) {
	const args  = {filter: {ids}, first: ids.length};
	const query = `
	query($filter: ModelsFilter = null, $first: Int = null){
		models_v2(filter: $filter, first: $first){
			items {
				id
                name
                online
                linkVX(internal: true)
                avatar(ageRating: 16){
                    url(size: w640)
                }
            }
		}
	}
	`;
	doVxqlRequest(query, args).then((result) => callback(result));
}

function loadVideos(videos = [], callback) {
	const query = `
	query{
        ${videos.map(item => `${item.id}: videos_v2(filter: {ids: [${item.ids}]}){
            items {
                title
                linkVX(internal: true)
                duration
                preview {
					id
					aspectRatio
					images(size: w320, placeholder: true) {
						size
						url
					}
				}                
                model {
                    id
                    linkVX(internal: true)
                    name
                }
            }  
        }`)}
	}
	`;
	doVxqlRequest(query).then((result) => callback(result));
}

export default SlotmachineStore;
