const DailyBonusActionTypes = {
	RELOAD:                     'DAILY_BONUS_RELOAD',
	LOADED:                     'DAILY_BONUS_LOADED',
	LOAD_INTERACTIVE_CONTENT:   'INTERACTIVE_CONTENT_LOAD',
	LOADED_INTERACTIVE_CONTENT: 'INTERACTIVE_CONTENT_LOADED',
	LOAD_EVENT_CONFIG:          'EVENT_CONFIG_LOAD',
	LOADED_EVENT_CONFIG:        'EVENT_CONFIG_LOADED',
};

export default DailyBonusActionTypes;
