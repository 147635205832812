import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import {doFetch}        from "../../utils/CommonUtils";

const TileGridActionTypes = Constants.ActionTypes.TileGrid;
const GalleryActionTypes  = Constants.ActionTypes.Gallery;
const CHANGE_EVENTS = {
	GridData:           'gridDataChange',
	VideoPicturesData:  'videoPicturesDataChange',
	PinnedMediaElement: 'pinnedMediaElement',
	ToggleCrop:         'toggleCrop',
	FilterList:         'filterList',
};

let _backLinks             = {};
let _restoreScrollPosition = false;
let _scrollPositions       = {};
let _tileGridData          = {};
const _tileGridKey         = {};
const _tileGridModes       = {};
let _tilePictureData       = {};
let _pinBoardPayload       = {};
let _filterList            = {};


function clearCache() {
	_backLinks             = {};
	_restoreScrollPosition = false;
	_scrollPositions       = {};
	_tileGridData          = {};
	_tilePictureData       = {};
	_pinBoardPayload       = {};
	_filterList            = {};
}

const TileGridStore = assign({}, FluxEventEmitter.prototype, {
	addGridDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.GridData, callback);
	},

	addGlobalPinChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.PinnedMediaElement, callback);
	},

	removeGlobalPinChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.PinnedMediaElement, callback);
	},

	removeGridDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.GridData, callback);
	},

	addVideoTileDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.VideoPicturesData, callback);
	},

	removeVideoTileDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.VideoPicturesData, callback);
	},

	addFilterListChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.FilterList, callback);
	},
	
	removeFilterListChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.FilterList, callback);
	},

	/**
	 * @param {Function} callback
	 */
	addOnGridCropListener: function(callback) {
		this.on(CHANGE_EVENTS.ToggleCrop, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeOnGridCropListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ToggleCrop, callback);
	},

	getGridData: function(type, payload) {
		return getData(type, payload);
	},

	hasGridData: function(type, payload) {
		return hasData(type, payload);
	},

	getBackLinkById(tileGridId) {
		return _backLinks[tileGridId];
	},

	getPictureData: function(albumId) {
		return _tilePictureData[albumId];
	},

	getRestoreScrollPosition() {
		return _restoreScrollPosition;
	},

	getScrollPositionById(tileGridId) {
		return _scrollPositions[tileGridId];
	},

	getTileGridKeyById(tileGridId) {
		return _tileGridKey[tileGridId];
	},

	getTileGridModeById(tileGridId) {
		return _tileGridModes[tileGridId];
	},

	getPinBoardPayload(pinBoardType) {
		return _pinBoardPayload[pinBoardType];
	},

	removePinnedTileElement(type, payload, tileIndex) {
		const key = getKey(payload);
		if (hasData(type, payload)) {
			_tileGridData[type][key].totalCount -= 1;
			_tileGridData[type][key].initialTiles.splice(tileIndex, 1);
			TileGridStore.emit(CHANGE_EVENTS.PinnedMediaElement, type);
		}
	},

	storePinBoardPayload(pinBoardType, payload) {
		_pinBoardPayload[pinBoardType] = payload;
	},

	setTileGridFilterList(filterList) {
		setTileGridFilterList(filterList);
	},
	
	getFilterList() {
		return _filterList;
	},
});

function getData(type, payload) {
	const key = getKey(payload);

	return hasData(type, payload) ? _tileGridData[type][key] : null;
}

function getKey(request) {
	const keys = [];
	for (const r in request) {
		if (Object.hasOwn(request, r)) {
			keys.push(r);
		}
	}

	keys.sort();

	const keyObject = {};
	for (let k = 0; k < keys.length; k++) {
		keyObject[keys[k]] = request[keys[k]];
	}

	return JSON.stringify(keyObject);
}

function hasData(type, payload) {
	const key = getKey(payload);
	return typeof _tileGridData[type] !== 'undefined' && typeof _tileGridData[type][key] !== 'undefined';
}

function loadGridData(type, payload, useInfiniteScrolling) {
	if (typeof payload === 'object') {
		doFetch(Routes.getRoute(Routes.Names.TILE_GRID, {
			type:    type,
			payload: encodeURIComponent(JSON.stringify(payload)),
		}), null, Constants.HttpMethods.GET, true, {cache: 'no-cache'}).then(function(result) {
			storeGridData(type, payload, result, useInfiniteScrolling);
		});
	}
}

function storeGridData(type, payload, result, useInfiniteScrolling) {
	const key      = getKey(payload);
	let hasChanged = false;
	let error      = null;

	let previousKey = null;
	const extendPreviousSet = useInfiniteScrolling && typeof payload.page !== 'undefined' && payload.page > 1;
	if (extendPreviousSet) {
		const payload2 = assign({}, payload, {page: payload.page - 1});
		previousKey    = getKey(payload2);
	}

	if (typeof _tileGridData[type] === 'undefined') {
		_tileGridData[type] = {};
	}
	if(result.total) {
		result.data.total = result.total;
	}
	if (result.data) {
		// merge new data with previous data
		if (extendPreviousSet && typeof _tileGridData[type][previousKey] !== 'undefined') {
			result.data.initialTiles = _tileGridData[type][previousKey].initialTiles.concat(result.data.initialTiles);
		}

		_tileGridData[type][key] = result.data;
		hasChanged               = true;
	} else if (result.success === false) {
		hasChanged = true;
		error      = 'rest-error';
	}
	if (hasChanged) {
		TileGridStore.emit(CHANGE_EVENTS.GridData, type, error);
	}
}

/**
 * @param {String} tileGridId
 * @param {Boolean} crop
 */
function toggleGridCrop(tileGridId, crop) {
	TileGridStore.emit(CHANGE_EVENTS.ToggleCrop, tileGridId, crop);
}

function loadVideoPicture(albumId) {
	if (!_tilePictureData[albumId]) {
		doFetch(Routes.getRoute(Routes.Names.TILE_GRID_GET_VIDEO_PICTURES, {albumId: albumId}), null, Constants.HttpMethods.GET, true, {cache: 'no-cache'}).then(function(result) {
			_tilePictureData[albumId] = result;
			TileGridStore.emit(CHANGE_EVENTS.VideoPicturesData, albumId);
		});
	}
}

function setScrollPosition(tileGridId, offset) {
	if (tileGridId) {
		_scrollPositions[tileGridId] = offset;
	}
}

function setBackLink(tileGridId) {
	if (tileGridId) {
		_backLinks[tileGridId] = window.location.href;
	}
}

function resetScrollPosition(tileGridId) {
	if (tileGridId) {
		_scrollPositions[tileGridId] = null;
	}
}

function resetBackLink(tileGridId) {
	if (tileGridId) {
		_backLinks[tileGridId] = null;
	}
}

function setRestoreScrollPosition(restorePosition) {
	_restoreScrollPosition = restorePosition;
}

function setTileGridKey(tileGridId, tileGridKey) {
	if (tileGridId) {
		_tileGridKey[tileGridId] = tileGridKey;
	}
}

function setTileGridMode(tileGridId, gridMode) {
	if (tileGridId) {
		_tileGridModes[tileGridId] = gridMode;
	}
}


function setTileGridFilterList(filterList) {
	if (filterList && filterList.items && filterList.items.length > 0) {
		_filterList = filterList;
		TileGridStore.emit(CHANGE_EVENTS.FilterList, filterList);
	}
}


TileGridStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case TileGridActionTypes.TOGGLE_CROP:
			toggleGridCrop(action.tileGridId, action.crop);
			break;
		case TileGridActionTypes.LOAD_GRID:
			loadGridData(action.gridType, action.payload, action.useInfiniteScrolling);
			break;
		case TileGridActionTypes.LOAD_VIDEO_ALBUM_PICTURES:
			loadVideoPicture(action.albumId);
			break;
		case TileGridActionTypes.SET_SCROLL_POSITION:
			setScrollPosition(action.tileGridId, action.offset);
			break;
		case TileGridActionTypes.SET_BACK_LINK:
			setBackLink(action.tileGridId);
			break;
		case TileGridActionTypes.RESET_SCROLL_POSITION:
			resetScrollPosition(action.tileGridId);
			break;
		case TileGridActionTypes.RESET_BACK_LINK:
			resetBackLink(action.tileGridId);
			break;
		case TileGridActionTypes.SET_RESTORE_SCROLL_POSITION:
			setRestoreScrollPosition(action.restorePosition);
			break;
		case TileGridActionTypes.SET_TILE_GRID_KEY:
			setTileGridKey(action.tileGridId, action.tileGridKey);
			break;
		case TileGridActionTypes.SET_TILE_GRID_MODE:
			setTileGridMode(action.tileGridId, action.gridMode);
			break;
		case TileGridActionTypes.SET_FILTER_LIST:
			setTileGridFilterList(action.filterList);
			break;
		case GalleryActionTypes.BUY_ALBUM:
			clearCache();
			break;
		default:
	}
});

export default TileGridStore;