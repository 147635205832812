import Dispatcher     from './../Dispatcher';
import Constants      from './../Constants';

class BlogPostsActionCreator {
	/**
	 * @param {Number} category
	 * @param {Number} page
	 * @param {Number} pageSize
	 */
	static dispatchGetBlogPostsAction(category, page = 1, pageSize = Constants.Blog.PageSize.default) {
		Dispatcher.dispatch({
			type: Constants.ActionTypes.Blog.WP_BLOG_POSTS_FETCH + BlogPostsActionCreator.SEPARATOR + category,
			category,
			page,
			pageSize,
		});
	}

	/**
	 * @return void
	 */
	static dispatchGetBlogPmAction() {
		Dispatcher.dispatch({
			type: Constants.ActionTypes.Blog.PM_FETCH,
		});
	}

	/**
	 * @param {string} category
	 * @return void
	 */
	static dispatchGetArchivesPmAction(category) {
		Dispatcher.dispatch({
			type: Constants.ActionTypes.Blog.ARCHIVES_PM_FETCH,
			category,
		});
	}

	/**
	 * @param {String} category
	 * @param {Number} year
	 * @param {Number} page
	 * @param {Number} pageSize
	 */
	static dispatchGetArchivesAction(category, year, page = 2, pageSize = Constants.Blog.PageSize.archives) {
		Dispatcher.dispatch({
			type: Constants.ActionTypes.Blog.ARCHIVES_FETCH,
			category,
			year,
			page,
			pageSize,
		});
	}

	/**
	 * @param {Number} category
	 * @return {string}
	 */
	static getPostsReceivedType(category) {
		return [
			Constants.ActionTypes.Blog.WP_BLOG_POSTS_RECEIVED,
			BlogPostsActionCreator.SEPARATOR,
			category,
		].join();
	}

	static getPostsRequestedType(category) {
		return [
			Constants.ActionTypes.Blog.WP_BLOG_POSTS_FETCH,
			BlogPostsActionCreator.SEPARATOR,
			category,
		].join();
	}

	/**
	 * @param {String} category
	 * @param {Number} year
	 * @return {string}
	 */
	static getArchivePostsReceivedType(category, year) {
		return [
			Constants.ActionTypes.Blog.ARCHIVES_RECEIVED,
			BlogPostsActionCreator.SEPARATOR,
			category,
			BlogPostsActionCreator.SEPARATOR,
			year,
		].join();
	}

	/**
	 * @param {String} type
	 * @return {boolean}
	 */
	static isFetchEvent(type) {
		return type.indexOf(
			Constants.ActionTypes.Blog.WP_BLOG_POSTS_FETCH +
			BlogPostsActionCreator.SEPARATOR
		) === 0;
	}
}

BlogPostsActionCreator.SEPARATOR = ':';

export default BlogPostsActionCreator;
