/**
 * Store for SpecialPages
 */

import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import {doFetch}        from "../../utils/CommonUtils";
import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';

const SpecialActionTypes = Constants.ActionTypes.Special;
const CHANGE_EVENTS      = {
	GamePoints:                'gamePoints',
	Reward:                    'rewardChange',
	RewardHistory:             'rewardHistoryChange',
	SpecialEventData:          'specialEventDataChange',
	SpecialEvents:             'specialEventsChange',
	Tries:                     'triesChange',
	EasterNotification:        'easterNotification',
	SpitOrSwallowConfigChange: 'spitOrSwallowQuestionChange',
	showLoader:                'showLoader',
//	hideLoader:                'hideLoader',
};

let _spitOrSwallowConfig = [];

let _gamePoints         = [];
let _reward             = null;
let _rewardHistory      = [];
const _specialEventData = {};
let _specialEvents      = [];
const _tries            = {
	available: 0,
	total:     0,
	used:      0,
};

let _loaderType = '';

const SpecialStore = assign({}, FluxEventEmitter.prototype, {
	addGamePointsChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.GamePoints, callback);
	},

	removeGamePointsChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.GamePoints, callback);
	},

	addRewardChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.Reward, callback);
	},

	removeRewardChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.Reward, callback);
	},

	addRewardHistoryChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.RewardHistory, callback);
	},

	removeRewardHistoryChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.RewardHistory, callback);
	},

	addTriesChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.Tries, callback);
	},

	removeTriesChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.Tries, callback);
	},

	addSpecialEventDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.SpecialEventData, callback);
	},

	removeSpecialEventDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.SpecialEventData, callback);
	},

	addSpecialsChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.SpecialEvents, callback);
	},

	removeSpecialsChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.SpecialEvents, callback);
	},

	addEasterChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.EasterNotification, callback);
	},

	removeEasterChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.EasterNotification, callback);
	},

	addSpitOrSwallowConfigChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.SpitOrSwallowConfigChange, callback);
	},

	removeSpitOrSwallowConfigChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.SpitOrSwallowConfigChange, callback);
	},

	getGamePointsByType(type) {
		return _gamePoints[type];
	},

	getReward: function() {
		return _reward;
	},

	getRewardHistory: function() {
		return _rewardHistory;
	},

	getSpecialEventData: function(eventType) {
		let eventData = null;
		if (typeof _specialEventData[eventType] !== 'undefined') {
			eventData = _specialEventData[eventType];
		}

		return eventData;
	},

	getTriesAvailable: function() {
		return _tries.available;
	},

	getTriesTotal: function() {
		return _tries.total;
	},

	getSpecials: function(offset, limit) {
		return _specialEvents.slice(offset, offset + limit);
	},

	getSpecialsCount: function() {
		return _specialEvents.length;
	},

	getSpitOrSwallowConfig: function() {
		return _spitOrSwallowConfig;
	},

	addShowLoaderChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.showLoader, () => callback(_loaderType));
	},

	removeShowLoaderChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.showLoader, () => callback(_loaderType));
	}
});

function loadSpecialEventData(eventType, qmDate) {
	let route = Routes.getRoute(Routes.Names.SPECIAL_EVENT_DATA, {'eventType': eventType});
	if (qmDate) {
		route += '&qmdate=' + qmDate;
	}

	doFetch(route, null, Constants.HttpMethods.GET, true).then((result) => {
		if (result) {
			_specialEventData[eventType] = result.data;
			SpecialStore.emit(CHANGE_EVENTS.SpecialEventData, eventType);
		}
	});
}

function loadSpecialsEvents() {

	doFetch(Routes.getRoute(Routes.Names.SPECIALS_EVENTS), null, Constants.HttpMethods.GET, true).then((result) => {
		if (result) {
			_specialEvents = result.data.specials;
			SpecialStore.emit(CHANGE_EVENTS.SpecialEvents);
		}
	});
}

function setActorOnlineState(actorId, state) {
	if (_specialEventData[Constants.SpecialEvents.SPECIAL_VR_PORN]) {
		const vrPornData = _specialEventData[Constants.SpecialEvents.SPECIAL_VR_PORN];
		for (const a in vrPornData.actors) {
			if (Object.hasOwn(vrPornData.actors, a) && vrPornData.actors[a].id === actorId) {
				vrPornData.actors[a].isOnline = state;
				SpecialStore.emit(CHANGE_EVENTS.SpecialEventData, Constants.SpecialEvents.SPECIAL_VR_PORN);
				break;
			}
		}
	}
}

function setLastOnTopInactiveByCode(code) {
	for (let r = _rewardHistory.length - 1; r >= 0; r--) {
		if (_rewardHistory[r].optionCode === code && _rewardHistory[r].canRedeem) {
			_rewardHistory[r].canRedeem = false;
			break;
		}
	}

	SpecialStore.emit(CHANGE_EVENTS.RewardHistory);
}

function setReward(reward) {
	_reward = reward;
	SpecialStore.emit(CHANGE_EVENTS.Reward);
}

function setRewardHistory(rewardHistory) {
	_rewardHistory = rewardHistory;
	SpecialStore.emit(CHANGE_EVENTS.RewardHistory);
}

function setTries(available, total) {
	_tries.available = available;
	_tries.total     = total;
	_tries.used      = total - available;
	SpecialStore.emit(CHANGE_EVENTS.Tries);
}

function setGamePoints(gamePoints) {
	_gamePoints = gamePoints;
	SpecialStore.emit(CHANGE_EVENTS.GamePoints, gamePoints);
}

function collectEgg(verificationToken, qmDate) {
	doFetch(Routes.getRoute(Routes.Names.EASTER_COLLECT_EGG), {verificationToken, qmdate: qmDate}, Constants.HttpMethods.POST, true).then(() => {
		SpecialStore.emit(CHANGE_EVENTS.EasterNotification);
	});
}

function loadRewardBoxReward(eventId, qmDate) {
	const route = Routes.getRoute(Routes.Names.REWARD_BOX_GET_REWARD, {eventId: eventId}, null);

	doFetch(route, {qmdate: qmDate}, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {

				const data = result.data.data;

				if (data.reward) {
					setReward(data.reward);
				}

			} else {
				throw new Error();
			}
		}.bind(this))
		.catch(function() {

		});
}

function loadWiesnShootingReward(qmDate) {
	const route = Routes.getRoute(Routes.Names.WIESN_SHOOTING_GET_REWARD, {eventId: 22}, null);

	doFetch(route, {qmdate: qmDate}, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {

				const data = result.data.data;

				if (data.guestData) {
					setRewardHistory(data.guestData.rewardHistory);
					setTries(data.guestData.creditsAvailable, data.guestData.creditsTotal);
					setGamePoints(data.guestData.gamePoints);
				}

				if (data.reward) {
					setReward(data.reward);
				}

			} else {
				throw new Error();
			}
		}.bind(this))
		.catch(function() {

		});
}


function loadWetAndWildReward(eventId, qmDate) {
	const route = Routes.getRoute(Routes.Names.WET_AND_WILD_GET_REWARD, {eventId}, null);

	doFetch(route, {qmdate: qmDate}, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {

				const data = result.data.data;

				if (data.guestData) {
					setRewardHistory(data.guestData.rewardHistory);
					setTries(data.guestData.creditsAvailable, data.guestData.creditsTotal);
					setGamePoints(data.guestData.gamePoints);
				}

				if (data.reward) {
					setReward(data.reward);
				}

			} else {
				throw new Error();
			}
		}.bind(this))
		.catch(function() {

		});
}


function loadWiesnBudeReward(qmDate) {
	const route = Routes.getRoute(Routes.Names.WIESN_BUDE_GET_REWARD, {eventId: 30}, null);

	doFetch(route, {qmdate: qmDate}, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {

				const data = result.data.data;

				if (data.guestData) {
					setRewardHistory(data.guestData.rewardHistory);
					setTries(data.guestData.creditsAvailable, data.guestData.creditsTotal);
					setGamePoints(data.guestData.gamePoints);
				}

				if (data.reward) {
					setReward(data.reward);
				}

			} else {
				throw new Error();
			}
		}.bind(this))
		.catch(function() {

		});
}

function loadVenusAttacksReward(qmDate) {
	const route = Routes.getRoute(Routes.Names.VENUS_ATTACKS_GET_REWARD, {eventId: 7}, null);

	doFetch(route, {qmdate: qmDate}, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {

				const data = result.data.data;

				if (data.reward) {
					setReward(data.reward);
				}

				if (data.guestData) {
					setRewardHistory(data.guestData.rewardHistory);
					setTries(data.guestData.creditsAvailable, data.guestData.creditsTotal);
					setGamePoints(data.guestData.gamePoints);
				}

			} else {
				throw new Error();
			}
		}.bind(this))
		.catch(function() {

		});
}

function loadWiesnGaudiReward(qmDate) {
	let route = Routes.getRoute(Routes.Names.WIESN_GAUDI_GET_REWARD, {eventId: 6}, null);

	if (qmDate) {
		route += '&qmdate=' + qmDate;
	}

	doFetch(route, null, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {

				const data = result.data.data;

				if (data.reward) {
					setReward(data.reward);
				}

				if (data.guestData) {
					setRewardHistory(data.guestData.rewardHistory);
				}

			} else {
				throw new Error();
			}
		}.bind(this))
		.catch(function() {

		});
}

function loadHalloweenReward(qmDate, eventId) {
	let route = Routes.getRoute(Routes.Names.HALLOWEEN_GET_REWARD, {eventId}, null);

	if (qmDate) {
		route += '&qmdate=' + qmDate;
	}
	doFetch(route, null, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {
				const data = result.data.data;

				if (data.reward) {
					setReward(data.reward);
				}

				if (data.guestData) {
					setRewardHistory(data.guestData.rewardHistory);
				}

			} else {
				throw new Error();
			}
		}.bind(this))
		.catch(function() {
		});
}

function loadSpitOrSwallowConfig() {
	doFetch(Routes.getRoute(Routes.Names.SPIT_OR_SWALLOW_GET_CONFIG), {}, Constants.HttpMethods.GET, true).then((result) => {
		if (result) {
			_spitOrSwallowConfig = result.data;
			SpecialStore.emit(CHANGE_EVENTS.SpitOrSwallowConfigChange);
		}
	});
}

function showLoader(type) {
	_loaderType = type;
	SpecialStore.emit(CHANGE_EVENTS.showLoader, type);
}

function hideLoader() {
	_loaderType = 'none';
	SpecialStore.emit(CHANGE_EVENTS.showLoader, 'none');
}

SpecialStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case SpecialActionTypes.LOAD_SPECIAL_EVENT_DATA:
			loadSpecialEventData(action.eventType, action.qmDate);
			break;
		case SpecialActionTypes.LOAD_SPECIAL_EVENTS:
			loadSpecialsEvents();
			break;
		case SpecialActionTypes.SET_ACTOR_ONLINE_STATE:
			setActorOnlineState(action.actorId, action.state);
			break;
		case SpecialActionTypes.SET_GAME_POINTS:
			setGamePoints(action.gamePoints);
			break;
		case SpecialActionTypes.SET_LAST_ON_TOP_INACTIVE_BY_CODE:
			setLastOnTopInactiveByCode(action.code);
			break;
		case SpecialActionTypes.SET_REWARD:
			setReward(action.reward);
			break;
		case SpecialActionTypes.SET_REWARD_HISTORY:
			setRewardHistory(action.rewardHistory);
			break;
		case SpecialActionTypes.SET_TRIES:
			setTries(action.available, action.total);
			break;
		case SpecialActionTypes.COLLECT_EGG:
			collectEgg(action.verificationToken, action.qmDate);
			break;
		case SpecialActionTypes.LOAD_SPECIAL_WIESN_GAUDI_REWARD:
			loadWiesnGaudiReward(action.qmDate);
			break;
		case SpecialActionTypes.LOAD_SPECIAL_HALLOWEEN_REWARD:
			loadHalloweenReward(action.qmDate, action.eventId);
			break;
		case SpecialActionTypes.LOAD_SPECIAL_VENUS_ATTACKS_REWARD:
			loadVenusAttacksReward(action.qmDate);
			break;
		case SpecialActionTypes.LOAD_SPECIAL_WIESN_SHOOTING_REWARD:
			loadWiesnShootingReward(action.qmDate);
			break;
		case SpecialActionTypes.LOAD_SPECIAL_WET_AND_WILD_REWARD:
			loadWetAndWildReward(action.eventId, action.qmDate);
			break;
		case SpecialActionTypes.LOAD_SPECIAL_WIESN_BUDE_REWARD:
			loadWiesnBudeReward(action.qmDate);
			break;
		case SpecialActionTypes.LOAD_SPIT_OR_SWALLOW_CONFIG:
			loadSpitOrSwallowConfig();
			break;
		case SpecialActionTypes.LOAD_SPECIAL_REWARD_BOX_REWARD:
			loadRewardBoxReward(action.eventId, action.qmDate);
			break;
		case SpecialActionTypes.SHOW_LOADER:
			showLoader(action.loaderType);
			break;
		case SpecialActionTypes.HIDE_LOADER:
			hideLoader();
			break;
		default:
	}
});

export default SpecialStore;