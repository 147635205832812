import assign                from 'object-assign';
import FluxEventEmitter      from '../FluxEventEmitter';
import Dispatcher            from './../Dispatcher';
import DailyBonusActionTypes from '../actions/Types/DailyBonusActionTypes';

const interactiveContent = {};
const eventConfigs       = {};

const Events = {
	INTERACTIVE_CONTENT_LOADED: 'interactiveLoaded',
	EVENT_CONFIG_LOADED: 'eventConfigLoaded',
};

/**
 * @param {Number} limit
 * @param {String} order
 * @param {Array} content
 */
function setInteractiveContent(limit, order, content) {
	interactiveContent[getKey(limit, order)] = content;
}

/**
 * @param {String} date
 * @param {Boolean} isMobile
 * @param event
 */
function setEventConfig(date, isMobile = false, event = null) {
	eventConfigs[(getConfigKey(date, isMobile))] = event;
}

/**
 * @param {String} date
 * @param {Boolean} isMobile
 * @return {string}
 */
function getConfigKey(date, isMobile = false) {
	return 'd:' + JSON.stringify(date) + ';isMobile:' + JSON.stringify(isMobile);
}

/**
 * @param {Number} limit
 * @param {String} order
 * @return {string}
 */
function getKey(limit, order) {
	return 'l:' + limit + ';order:' + order;
}

const DailyBonusStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @param {Function} callback
	 */
	addInteractiveContentLoadListener(callback) {
		this.on(Events.INTERACTIVE_CONTENT_LOADED, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeInteractiveContentLoadListener(callback) {
		this.removeListener(Events.INTERACTIVE_CONTENT_LOADED, callback);
	},

	/**
	 * @param {Number} limit
	 * @param {String} order
	 * @return {Array}
	 */
	getInteractiveContent(limit, order) {
		const key = getKey(limit, order);
		return Object.hasOwn(interactiveContent, key) ? interactiveContent[key] : [];
	},

	/**
	 * @param {Function} callback
	 */
	addEventConfigLoadedListener(callback) {
		this.on(Events.EVENT_CONFIG_LOADED, callback);
	},
	/**
	 * @param {Function} callback
	 */
	removeEventConfigLoadedListener(callback) {
		this.removeListener(Events.EVENT_CONFIG_LOADED, callback);
	},
	/**
	 * @param {String} date
	 * @param {Boolean} isMobile
	 * @return {Object}
	 */
	getEventConfig(date = null, isMobile = false) {
		const key = getConfigKey(date, isMobile);
		return Object.hasOwn(eventConfigs, key) ? eventConfigs[key] : null;
	},
});

DailyBonusStore.dispatchToken = Dispatcher.register((action) => {
	switch (action.type) {
		case DailyBonusActionTypes.LOADED_INTERACTIVE_CONTENT:
			setInteractiveContent(action.limit, action.order, action.content);
			DailyBonusStore.emit(Events.INTERACTIVE_CONTENT_LOADED, action.limit, action.order);
			break;

		case DailyBonusActionTypes.LOADED_EVENT_CONFIG:
			setEventConfig(action.date, action.isMobile, action.event);
			DailyBonusStore.emit(Events.EVENT_CONFIG_LOADED, action.date, action.isMobile);
			break;

		default:
			// do nothing
	}
});

export default DailyBonusStore;
