'use strict';

import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import {doFetch}        from './../../utils/CommonUtils';
import assign           from 'object-assign';
import Constants        from '../Constants';
import Dispatcher       from "../Dispatcher";

const ActionTypes  = Constants.ActionTypes.Promo;

const ChangeEvents = {
	hasNotRedeemedPromoCodeInstanceChange: 'hasNotRedeemedPromoCodeInstanceChange',
};

function hasGuestNotRedeemedPromoCodeInstanceByName(promoCode) {
	const payload = {promoCode};

	doFetch(Routes.getRoute(Routes.Names.PROMO_HAS_NOT_REDEEMED_PROMO_CODE_INSTANCE, payload), null, Constants.HttpMethods.GET, true)
		.then(function(result) {
			if (result.success) {
				PromoStore.emitHasNotRedeemedPromoCodeInstanceChange(promoCode, result.data.hasNotRedeemedInstance);
			}
		});
}

const PromoStore = assign({}, FluxEventEmitter.prototype, {
	init: function() {
	},

	addHasNotRedeemedPromoCodeInstanceChangeListener: function(callback) {
		this.addListener(ChangeEvents.hasNotRedeemedPromoCodeInstanceChange, callback);
	},

	removeHasNotRedeemedPromoCodeInstanceChangeListener: function(callback) {
		this.removeListener(ChangeEvents.hasNotRedeemedPromoCodeInstanceChange, callback);
	},

	emitHasNotRedeemedPromoCodeInstanceChange: function(promoCode, hasNotRedeemedInstance) {
		this.emit(ChangeEvents.hasNotRedeemedPromoCodeInstanceChange, promoCode, hasNotRedeemedInstance);
	},

});

PromoStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.HAS_GUEST_NOT_REDEEMED_PROMO_CODE_INSTANCE:
			hasGuestNotRedeemedPromoCodeInstanceByName(action.promoCode);
			break;
		default:
	}
});


export default PromoStore;
