import assign           from 'object-assign';
import FluxEventEmitter from './../FluxEventEmitter';
import Flux             from "../Flux";
import VxqlStore        from '../stores/VxqlStore';
import Constants        from "../Constants";

const ChangeEvents = {
	overlayVisibilityChanged: 'overlayVisibilityChanged',
	searchResult:             'searchResult',
};

const LOCAL_STORAGE_KEY = Constants.LocalItemNames.GLOBAL_SEARCH_REQUESTS;

let _searchResult = {
	searchPattern: '',
	proposals:     [],
	actors:        {
		count: 0,
		link:  '',
		items: [
			{
				name:        '',
				imageUrl:    '',
				imageAspect: 1.0,
				link:        '',
				online:      false,
			},
		],
	},
	videos:        {
		count: 0,
		link:  '',
		items: [],
		type: '',
	},
	mediathek:     {
		count: 0,
		link:  '',
		items: [],
		type: '',
	},
	blog:          {
		count: 0,
		link:  '',
		items: [],
		type: '',
	},
};

let _suggestions        = [];
let _lastSearchRequests = [];
let _searchUrl          = '';
let _lastSearchPattern  = '';


/**
 *
 * @param {string} pattern
 */
function processSearch(pattern, isExtended) {
	Flux.Vxql.processGlobalSearch(pattern, isExtended, processSearchResult);
}

function processSearchResult(result) {
	if (typeof result.data.search.global !== 'undefined') {

		const data = result.data.search.global;

		if (!data.searchPattern || data.searchPattern !== _lastSearchPattern) {
			// ignore result if its not the most recent one
			return;
		}

		_searchResult.searchPattern = data.searchPattern;

		if (data.proposals) {
			_searchResult.proposals = data.proposals;
		}

		if (data.actors) {
			_searchResult.actors.count = data.actors.count;
			_searchResult.actors.link  = data.actors.link;
			_searchResult.actors.items = [];

			for (let i = 0; i < data.actors.items.length; i++) {
				const el   = data.actors.items[i];
				const item = {
					name:            el.model.name,
					imageUrl:        el.model.avatar ? el.model.avatar.url : Constants.Placeholders.Avatar.FEMALE,
					imageAspect:     el.model.avatar ? el.model.avatar.aspectRatio : 1,
					link:            el.model.linkVX,
					online:          el.model.chat ? el.model.chat.online : false,
					mobileVideoCall: el.model.chat ? el.model.chat.mobileVideoCall : false,
				};
				_searchResult.actors.items.push(item);
			}

		} else {
			_searchResult.actors.count = 0;
			_searchResult.actors.link  = '';
			_searchResult.actors.items = [];
		}

		if (data.videos) {
			_searchResult.videos.count  = data.videos.count;
			_searchResult.videos.link   = data.videos.link;
			_searchResult.videos.items  = data.videos.items;
			_searchResult.videos.type   = data.videos.type;
		} else {
			_searchResult.videos.count  = 0;
			_searchResult.videos.link   = '';
			_searchResult.videos.items  = [];
			_searchResult.videos.type   = '';
		}

		if (data.mediathek) {
			_searchResult.mediathek.count = data.mediathek.count;
			_searchResult.mediathek.link  = data.mediathek.link;
			_searchResult.mediathek.items = data.mediathek.items;
			_searchResult.mediathek.type  = data.mediathek.type;
		} else {
			_searchResult.mediathek.count = 0;
			_searchResult.mediathek.link  = '';
			_searchResult.mediathek.items = [];
			_searchResult.mediathek.type  = '';
		}

		if (data.blog) {
			_searchResult.blog.count = data.blog.count;
			_searchResult.blog.link  = data.blog.link;
			_searchResult.blog.items = data.blog.items;
			_searchResult.blog.type  = data.blog.type;
		} else {
			_searchResult.blog.count = 0;
			_searchResult.blog.link  = '';
			_searchResult.blog.items  = [];
			_searchResult.blog.type  = '';
		}

		GlobalSearchStore.emitSearchResultChange();
	} else {
		_searchResult = null;
		GlobalSearchStore.emitSearchResultChange();
	}
}

const GlobalSearchStore = assign({}, FluxEventEmitter.prototype, {
	init: function(suggestions, lastSearchRequests, searchUrl) {
		_suggestions = suggestions;
		_searchUrl   = searchUrl;

		const stored = Flux.Browser.getLocalItem(LOCAL_STORAGE_KEY);
		if (stored) {
			_lastSearchRequests = JSON.parse(stored);
		} else {
			_lastSearchRequests = lastSearchRequests;
		}
	},

	doSearch: function(searchPattern, isExtended) {
		_lastSearchPattern = searchPattern;
		processSearch(searchPattern, isExtended);
	},

	showOverlay: function() {
		this.emit(ChangeEvents.overlayVisibilityChanged, true);
	},

	hideOverlay: function() {
		this.emit(ChangeEvents.overlayVisibilityChanged, false);
	},

	getSearchResult: function() {
		return _searchResult;
	},

	getSuggestions: function() {
		return _suggestions;
	},

	getLastSearchRequests: function() {
		return _lastSearchRequests;
	},

	getSearchUrl: function() {
		return _searchUrl;
	},

	storeSearchRequest: function(searchPattern) {
		if (searchPattern.length < 3) {
			return;
		}

		if (_lastSearchRequests.indexOf(searchPattern) === -1) {
			if (_lastSearchRequests.length > 2) {
				_lastSearchRequests.pop();
			}
			_lastSearchRequests.unshift(searchPattern);
		}

		Flux.Browser.setLocalItem(LOCAL_STORAGE_KEY, JSON.stringify(_lastSearchRequests));
		Flux.Vxql.storeSearchPattern(searchPattern, {});
	},

	addOverlayVisibilityChangeListener: function(callback) {
		this.addListener(ChangeEvents.overlayVisibilityChanged, callback);
	},

	removeOverlayVisibilityChangeListener: function(callback) {
		this.removeListener(ChangeEvents.overlayVisibilityChanged, callback);
	},

	addSearchResultListener: function(callback) {
		this.addListener(ChangeEvents.searchResult, callback);
	},

	removeSearchResultListener: function(callback) {
		this.removeListener(ChangeEvents.searchResult, callback);
	},

	emitSearchResultChange: function() {
		this.emit(ChangeEvents.searchResult);
	},
	processSuggestions:              function(callback) {
		VxqlStore.processSuggestions((result) => {
			const stored = Flux.Browser.getLocalItem(LOCAL_STORAGE_KEY);
			if (stored) {
				result.data.search.lastSearchRequests = JSON.parse(stored);
			}
			callback(result);
		});
	},

});

export default GlobalSearchStore;
