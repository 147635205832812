function EnumGA4Events() {
}

EnumGA4Events.DEFAULT_PARAMETER_EVENT                                         = "event";
EnumGA4Events.DEFAULT_PARAMETER_CURRENCY                                      = "currency";
EnumGA4Events.DEFAULT_PARAMETER_CURRENCY__VALUE_EUR                           = "EUR";
EnumGA4Events.DEFAULT_PARAMETER_CURRENCY__VALUE_CREDITS                       = "credits";
EnumGA4Events.DEFAULT_PARAMETER_VALUE                                         = "value";
EnumGA4Events.DEFAULT_PARAMETER_COUPON                                        = "coupon";
EnumGA4Events.DEFAULT_PARAMETER_PAYMENT_TYPE                                  = "payment_type";
EnumGA4Events.DEFAULT_PARAMETER_VIRTUAL_CURRENCY_NAME                         = "virtual_currency_name";
EnumGA4Events.DEFAULT_PARAMETER_TRANSACTION_ID                                = "transaction_id";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS                                         = "items";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_ID                                = "item_id";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME                              = "item_name";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_PAID_MESSAGE          = "paid_message";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_LIVE_CHAT             = "live_chat";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_VOYEUR_CAM            = "voyeur_cam";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_BRAND                             = "item_brand"; // amateur name
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY                          = "item_category"; // content type
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_VIDEO             = "video";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_IMAGE_ALBUM       = "image_album";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_FEED_PAID_CONTENT = "feed_paid_content";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_FEED_TIP          = "feed_tip";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_FEED_TIPPING_GOAL = "feed_tipping_goal";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_CAM_SHOW_TICKET   = "cam_show_ticket";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_TOY_CONTROL       = "toy_control";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_PAID_MESSAGE      = "paid_message";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_LIVE_CHAT         = "live_chat";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_VOYEUR_CAM        = "voyeur_cam";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY2                         = "item_category2"; // tags
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY3                         = "item_category3"; // tags
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY4                         = "item_category4"; // tags
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY5                         = "item_category5"; // tags
EnumGA4Events.DEFAULT_PARAMETER_CONTENT_TYPE                                  = "content_type";
EnumGA4Events.DEFAULT_PARAMETER_CONTENT_ID                                    = "content_id";
EnumGA4Events.DEFAULT_PARAMETER_METHOD                                        = "method";
EnumGA4Events.DEFAULT_PARAMETER_CONTENT_TYPE                                  = "content_type";
EnumGA4Events.DEFAULT_PARAMETER_ITEM_ID                                       = "item_id";

EnumGA4Events.DEFAULT_EVENT_ADD_PAYMRNT_INFO      = "add_payment_info";
EnumGA4Events.DEFAULT_EVENT_BEGIN_CHECKOUT        = "begin_checkout";
EnumGA4Events.DEFAULT_EVENT_EARN_VIRTUAL_CURRENCY = "earn_virtual_currency";
EnumGA4Events.DEFAULT_EVENT_LOGIN                 = "login";
EnumGA4Events.DEFAULT_EVENT_PURCHASE              = "purchase";
EnumGA4Events.DEFAULT_EVENT_SELECT_CONTENT        = "select_content";
EnumGA4Events.DEFAULT_EVENT_SHARE                 = "share";
EnumGA4Events.DEFAULT_EVENT_SIGN_UP               = "sign_up";


EnumGA4Events.CUSTOM_EVENT_IS_OFFICE_IP                                            = "is_office_ip";
EnumGA4Events.CUSTOM_EVENT_CONVERSION                                              = "conversion";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION                                          = "conversion";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_PURCHASE_VIDEO_CLICK              = "purchase_video_click";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_PURCHASE_VIDEO_SUCCESS            = "purchase_video_success";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_MONEYCHARGE_CLICK                 = "moneycharge_click";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_MONEYCHARGE_SUCCESS               = "moneycharge_success";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_OPEN_VIP_CLICK                    = "open_vip_click";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_OPEN_SIGNUP_CLICK                 = "open_signup_click";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_OVERLAY                           = "overlay";
EnumGA4Events.CUSTOM_PARAMETER_OVERLAY__TYPE                                       = "overlay_type";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_INPAGE                            = "inpage";
EnumGA4Events.CUSTOM_PARAMETER_INPAGE__TYPE                                        = "inpage_type";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_BALANCE_REFILL_POPUP_CLICK_REFILL = "balance_refill_popup_click_refill";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_BALANCE_REFILL_POPUP_CLICK_CLOSE  = "balance_refill_popup_click_close";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_SALE_CLICK_BONUS_COUNTER          = "sale_click_bonus_counter";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_SALE_SUCCESS_BONUS_COUNTER        = "sale_success_bonus_counter";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_LOGIN_SUCCESS_CLIP_COUNTER        = "login_success_clip_counter";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_SIGNUP_SUCCESS_CLIP_COUNTER       = "signup_success_clip_coutner";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_OPEN_SIGNUP_CLICK_BONUS_COUNTER   = "signup_click_bonus_counter";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_VIDEO_PLAYER_INCENTIVE            = "video_player_incentive";
EnumGA4Events.CUSTOM_PARAMETER_VIDEO_PLAYER_INCENTIVE__TYPE                        = "video_player_incentive_type";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_LUCY_CAT_VIDEO_SIGNUP_SUCCESS     = "lucy_cat_video_signup_success";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_LUCY_CAT_VIDEO_BUTTON_CLICK       = "lucy_cat_video_button_click";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_LUCY_CAT_VIDEO_ENDED              = "lucy_cat_video_ended";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_EXIT_INTENT_LOGIN_SUCCESS         = "exit_intent_login_success";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_EXIT_INTENT_SIGNUP_SUCCESS        = "exit_intent_signup_success";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_EXIT_INTENT_OPEN_SIGNUP_CLICK     = "exit_intent_open_signup_click";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_EXIT_INTENT_DEFAULT               = "exit_intent_default";

EnumGA4Events.CUSTOM_EVENT_CONVERSION_360            = "conversion_360";
EnumGA4Events.CUSTOM_PARAMETER_CONVERSION_360_ACTION = "conversion_360_action";

EnumGA4Events.CUSTOM_EVENT_WEB_PUSH            = "web_push";
EnumGA4Events.CUSTOM_PARAMETER_WEB_PUSH_ACTION = "web_push_action";

EnumGA4Events.CUSTOM_EVENT_CHAT_EXIT                                           = "chat_exit";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_ACTION                                = "chat_exit_action";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_ACTION__VALUE_CLOSE_CLICK             = "close_click";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_ACTION__VALUE_PROFILE_PICTURE_CLICK   = "profile_picture_click";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_ACTION__VALUE_RECOMMENDED_ACTOR_CLICK = "recommended_actor_click";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_ACTION__VALUE_SEND_FEEDBACK_CLICK     = "send_feedback_click";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_ACTION__VALUE_SKIP_CLICK              = "skip_click";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_ACTION__VALUE_SEND_GIFT               = "send_gift";
EnumGA4Events.CUSTOM_PARAMETER_CHAT_EXIT_SELECTED_GIFT                         = "selected_gift";

EnumGA4Events.CUSTOM_EVENT_TRACK_CLICK                 = "track_click";
EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_CATEGORY    = "category";
EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_EVENT       = "click_event";
EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_LABEL       = "label";
EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_USER_STATUS = "user_status";

EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE                       = "login_state";
EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE__VALUE_LOGOUT_SUCCESS = "logout_success";
EnumGA4Events.CUSTOM_PARAMETER_MONEYCHARGE                       = "moneycharge";

EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_PLATFORM_CONTENT = "platform_content";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_365              = "365";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_ABO              = "abo";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_NAME__VALUE_FEED             = "feed";

EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_VIP = "vip";
EnumGA4Events.DEFAULT_PARAMETER_ITEMS__ITEM_CATEGORY__VALUE_TV  = "tv";

EnumGA4Events.mapParamToEvent = {
	[EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE]: EnumGA4Events.DEFAULT_EVENT_LOGIN,
	[EnumGA4Events.CUSTOM_PARAMETER_MONEYCHARGE]: EnumGA4Events.DEFAULT_EVENT_EARN_VIRTUAL_CURRENCY,
};

export default EnumGA4Events;
