'use strict';

class EventTracker {

	/**
	 *
	 * @param {string} vxqlUrl
	 * @param {string} vxqlToken
	 */
	constructor(vxqlUrl, vxqlToken) {
		this.vxqlUrl   = vxqlUrl;
		this.vxqlToken = vxqlToken;
	}

	/**
	 *
	 * @returns {Event}
	 */
	getNewEvent() {
		const event = new Event();
		Object.preventExtensions(event);

		return event;
	}

	/**
	 *
	 * @param {Event} event
	 * @returns {*}
	 */
	trackEvent(event) {

		if (!event || !(event instanceof Event) || !this.vxqlUrl || !this.vxqlToken) {
			return false;
		}

		const queryObject   = {};
		queryObject.query = `
			mutation($timestamp: String, $type: TrackingEventType!, $source: TrackingEventSource!, $context: TrackingEventContext!, $data: String, $measure_int1: Int, $measure_int2: Int, $measure_int3: Int, $measure_int4: Int, $measure_int5: Int, $measure_float1: Float, $measure_float2: Float, $measure_float3: Float, $measure_float4: Float, $measure_float5: Float) {
				tracking {
					trackApplication(
						timestamp: $timestamp,
						type: $type,
						source: $source,
						context: $context,
						data: $data,
						measure_int1: $measure_int1, measure_int2: $measure_int2, measure_int3: $measure_int3, measure_int4: $measure_int4, measure_int5: $measure_int5,
						measure_float1: $measure_float1, measure_float2: $measure_float2, measure_float3: $measure_float3, measure_float4: $measure_float4, measure_float5: $measure_float5
					)
					{success}
				}
			}`;

		queryObject.variables = event;

		if (navigator) {
			const headers = {
				type: 'text/plain',
			};
			const blob    = new Blob([JSON.stringify(queryObject)], headers);
			const url   = this.vxqlUrl + '?webtoken=' + this.vxqlToken;

			try {
				return navigator.sendBeacon(url, blob);
			} catch {
				// ignore and try fetch
			}
		}

		const init = {
			headers: {'Authorization': 'Bearer ' + this.vxqlToken, 'Content-Type': 'application/json'},
			body:    JSON.stringify(queryObject),
			method:  'POST',
		};

		return fetch(this.vxqlUrl, init);
	}
}

function Event() {
	/**
	 *
	 * @type {string}
	 */
	this.timestamp = '';

	/**
	 *
	 * @type {string}
	 */
	this.source = '';

	/**
	 *
	 * @type {string}
	 */
	this.type = '';

	/**
	 *
	 * @type {string}
	 */
	this.context = '';

	/**
	 *
	 * @type {string}
	 */
	this.data = '';

	/**
	 *
	 * @type {number}
	 */
	this.measure_int1 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_int2 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_int3 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_int4 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_int5 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_float1 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_float2 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_float3 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_float4 = 0;

	/**
	 *
	 * @type {number}
	 */
	this.measure_float5 = 0;
}

export default EventTracker;
