import assign           from 'object-assign';
import FluxEventEmitter from './../FluxEventEmitter';
import Routes           from './../../utils/Routes';
import Constants        from './../Constants';
import {doFetch}        from './../../utils/CommonUtils';
import WebPushHelper    from '../../utils/WebPushHelper';

/**
 * @link https://developer.mozilla.org/en-US/docs/Web/API/PushSubscription
 * @link https://gist.github.com/BigstickCarpet/a0d6389a5d0e3a24814b
 */
const WebPushSubscriptionStore = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @returns {Promise<PushSubscription|null>}
	 */
	getCurrentSubscription: () => new Promise((resolve) => {
		navigator.serviceWorker
			.getRegistration()
			.then(registration => {
				if (typeof registration === "undefined") {
					throw new Error('No registration available');
				}

				return registration.pushManager;
			})
			.then(manager => manager.getSubscription())
			.then(sub => resolve(sub))
			.catch(() => resolve(null));
	}),

	/**
	 * @return {Promise<String>}
	 */
	getKey: function() {
		return doFetch(Routes.getRoute(Routes.Names.WEB_PUSH_KEY, false, false), null, Constants.HttpMethods.GET)
			.then(json => json.data.key);
	},

	/**
	 * @returns {Promise}
	 */
	isAlreadySubscribed: function() {
		return WebPushSubscriptionStore
			.getCurrentSubscription()
			.then(sub => {
				if (null === sub) {
					return new Promise(resolve => resolve(false));
				}

				return WebPushSubscriptionStore.getStatus(sub);
			});
	},

	/**
	 * @param {PushSubscription} subscription
	 * @param {Object} events
	 * @return {Promise<Boolean>}
	 * @link https://gist.github.com/BigstickCarpet/a0d6389a5d0e3a24814b
	 */
	subscribe: function(subscription, events = WebPushSubscriptionStore.EVENTS_ALL) {
		const store = this,
		      route = Routes.getRoute(Routes.Names.WEB_PUSH_SUBSCRIBE),
		      data  = {
			      subscription: subscription,
			      events:       events,
			      encoding:     WebPushHelper.getSupportedEncoding(),
		      };

		return doFetch(route, data, Constants.HttpMethods.POST, true, {cache: 'no-cache'})
			.then(json => {
				store.setRenewalToken(json.data.token);
				return json.data;
			})
			.then(data => data.subscribed || false);
	},

	/**
	 * Save subscription meta in IndexedDB store where service worker can access it
	 *
	 * @param token
	 * @returns {Promise}
	 */
	setRenewalToken: function(token) {
		return new Promise((resolve) => {
			// This works on all devices/browsers, and uses IndexedDBShim as a final fallback
			const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB,
			      // Open (or create) the database
			      open = indexedDB.open(WebPushSubscriptionStore.IDB_NAME, WebPushSubscriptionStore.IDB_VERSION);

			// Create the schema (store + index)
			open.onupgradeneeded = function(event) {
				const db = event.target.result;

				if (!db.objectStoreNames.contains(WebPushSubscriptionStore.IDB_STORE)) {
					const store = db.createObjectStore(WebPushSubscriptionStore.IDB_STORE, {keyPath: 'id'});
					store.createIndex(WebPushSubscriptionStore.IDB_INDEX, ['token']);
				}
			};

			open.onsuccess = function(event) {
				// Start a new transaction
				const db = event.target.result,
				      tx = db.transaction(WebPushSubscriptionStore.IDB_STORE, 'readwrite');

				tx.objectStore(WebPushSubscriptionStore.IDB_STORE)
					.put({
						id:    WebPushSubscriptionStore.IDB_ID_KEY,
						token: token,
					});

				// Close the db when the transaction is done
				tx.oncomplete = function() {
					db.close();
					resolve();
				};
			};
		});
	},

	/**
	 * Get the re-subscribe JWT from IndexedDB
	 *
	 * @returns {Promise<String>}
	 */
	getRenewalToken: function() {
		return new Promise((resolve, reject) => {
			// This works on all devices/browsers, and uses IndexedDBShim as a final fallback
			const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

			// Open (or create) the database
			const open = indexedDB.open(WebPushSubscriptionStore.IDB_NAME, WebPushSubscriptionStore.IDB_VERSION);

			open.onsuccess = function(event) {
				try {
					const db = event.target.result;
					const tx = db.transaction(WebPushSubscriptionStore.IDB_STORE, 'readonly');

					// if we don't have a web-push store - nothing to do here ...
					if (!db.objectStoreNames.contains(WebPushSubscriptionStore.IDB_STORE)) {
						return reject('No re-subscribe token found (web-push store missing).');
					}

					tx.oncomplete      = () => db.close();
					const store        = tx.objectStore(WebPushSubscriptionStore.IDB_STORE);
					const getToken     = store.get(WebPushSubscriptionStore.IDB_ID_KEY);
					getToken.onsuccess = () => resolve(getToken.result.token);
					getToken.onerror   = (e) => reject(e);
				} catch (e) {
					reject(e.message);
				}
			};
		});
	},

	/**
	 * @param {PushSubscription} subscription
	 * @return {Promise<Boolean>}
	 */
	getStatus: function(subscription) {
		if (null === subscription) {
			return new Promise((resolve) => resolve(false));
		}

		const params = {payload: encodeURIComponent(JSON.stringify({subscription: subscription}))};

		return doFetch(Routes.getRoute(Routes.Names.WEB_PUSH_STATUS, params), null, Constants.HttpMethods.GET)
			.then(json => json.data.subscribed.toString() !== 'false');
	},

	/**
	 * @param {PushSubscription} subscription
	 * @return {Promise<Boolean>}
	 */
	setGuestId: function(subscription) {
		const params = {payload: encodeURIComponent(JSON.stringify(subscription))};

		return doFetch(Routes.getRoute(Routes.Names.WEB_PUSH_SET_GUEST_ID, params), null, Constants.HttpMethods.PUT, true, {cache: 'no-cache'})
			.then(json => json.data.updated);
	},
});

WebPushSubscriptionStore.KEY_FAVOURITES_CLOSED     = 'web-push:favourites';
WebPushSubscriptionStore.KEY_WEB_PUSH_SUBSCRIPTION = 'web-push:subscription';

/**
 * @note should match with EnumMessageTag constants (in php)
 */
WebPushSubscriptionStore.EVENTS_ACTOR_ONLINE = 'actor-online';
WebPushSubscriptionStore.EVENTS_CAMPAIGNS    = 'campaigns';
WebPushSubscriptionStore.EVENTS_NEWS         = 'news';

WebPushSubscriptionStore.IDB_VERSION = 5;
WebPushSubscriptionStore.IDB_NAME    = 'vx';
WebPushSubscriptionStore.IDB_STORE   = 'web-push';
WebPushSubscriptionStore.IDB_INDEX   = 'identity';

WebPushSubscriptionStore.IDB_ID_KEY = 'identity:key';

WebPushSubscriptionStore.EVENTS_ALL = {
	[WebPushSubscriptionStore.EVENTS_ACTOR_ONLINE]: true,
	[WebPushSubscriptionStore.EVENTS_CAMPAIGNS]:    true,
	[WebPushSubscriptionStore.EVENTS_NEWS]:         true,
};

export default WebPushSubscriptionStore;
