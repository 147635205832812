/* global VXConfig */

'use strict';

import Constants         from '../Constants';
import NotificationStore from './NotificationStore';
import FluxEventEmitter  from '../FluxEventEmitter';
import assign            from 'object-assign';
import {doFetch}         from "../../utils/CommonUtils";
import Routes            from "../../utils/Routes";

const IncomingCommandTypes = Constants.ApiTypes.Notification.Incoming;

const ChangeEvents = {
	favoriteActors:      'favoriteActors',
	actorPinned:         'actorPinned',
	actorUnpinned:       'actorUnpinned',
	albumPinned:         'albumPinned',
	sedcardsAlbumPinned: 'sedcardsAlbumPinned',
	picturePinned:       'picturePinned',
//	emailNotification:   'emailNotification',
};

/*
 * data
 */

const favoriteActorsData = {
	items:       [],
	onlineCount: 0,
	pinnedIds:   false,
};

/**
 * @param command
 */
function handleIncomingCommand(command) {
	if (typeof command.type === 'string') {
		switch (command.type) {
			case IncomingCommandTypes.FAVORITE_ACTOR_PINNED:
				FavoriteStore.emit(ChangeEvents.actorPinned, command.actorId, true);
				break;
			case IncomingCommandTypes.FAVORITE_ACTOR_UNPINNED:
				FavoriteStore.emit(ChangeEvents.actorUnpinned, command.actorId, false);
				break;
			case IncomingCommandTypes.FAVORITE_ALBUM_PINNED:
				FavoriteStore.emit(ChangeEvents.albumPinned, command.albumId, true);
				break;
			case IncomingCommandTypes.FAVORITE_ALBUM_UNPINNED:
				FavoriteStore.emit(ChangeEvents.albumPinned, command.albumId, false);
				break;
			case IncomingCommandTypes.FAVORITE_SEDCARDS_ALBUM_PINNED:
				FavoriteStore.emit(ChangeEvents.sedcardsAlbumPinned, command.actorId, true);
				break;
			case IncomingCommandTypes.FAVORITE_SEDCARDS_ALBUM_UNPINNED:
				FavoriteStore.emit(ChangeEvents.sedcardsAlbumPinned, command.actorId, false);
				break;
			case IncomingCommandTypes.FAVORITE_PICTURE_PINNED:
				FavoriteStore.emit(ChangeEvents.picturePinned, command.pictureId, true);
				break;
			case IncomingCommandTypes.FAVORITE_PICTURE_UNPINNED:
				FavoriteStore.emit(ChangeEvents.picturePinned, command.pictureId, false);
				break;
			case IncomingCommandTypes.FAVORITE_ACTORS:
				favoriteActorsData.items       = command.items;
				favoriteActorsData.onlineCount = command.onlineCount;
				FavoriteStore.emit(ChangeEvents.favoriteActors);
				break;
			case IncomingCommandTypes.ACTOR_ONLINE:
			case IncomingCommandTypes.ACTOR_OFFLINE:
				handleActorOnlineOffline(command);
				break;
			default:
				break;
		}
	}
}

function handleActorOnlineOffline(command) {
	for (let i = 0; i < favoriteActorsData.items.length; i++) {
		if (command.actorId === favoriteActorsData.items[i].id) {
			const oldState = favoriteActorsData.items[i].isOnline;
			const newState = command.type === IncomingCommandTypes.ACTOR_ONLINE;

			if (oldState !== newState) {
				favoriteActorsData.items[i].isOnline = newState;

				if (newState) {
					favoriteActorsData.onlineCount++;
				} else {
					favoriteActorsData.onlineCount--;
				}

				FavoriteStore.emit(ChangeEvents.favoriteActors);
			}

			break;
		}
	}
}

const FavoriteStore = assign({}, FluxEventEmitter.prototype, {

	/*
	 * Actor
	 */

	addActorPinnedListener: function(callback) {
		this.on(ChangeEvents.actorPinned, callback);
	},

	removeActorPinnedListener: function(callback) {
		this.removeListener(ChangeEvents.actorPinned, callback);
	},

	addActorUnpinnedListener: function(callback) {
		this.on(ChangeEvents.actorUnpinned, callback);
	},

	removeActorUnpinnedListener: function(callback) {
		this.removeListener(ChangeEvents.actorUnpinned, callback);
	},

	/*
	 * Actors
	 */

	getFavoriteActors: function() {
		return favoriteActorsData.items;
	},

	getFavoriteActorsOnlineCount: function() {
		return favoriteActorsData.onlineCount;
	},

	addFavoriteActorsChangeListener: function(callback) {
		this.on(ChangeEvents.favoriteActors, callback);
	},

	removeFavoriteActorsChangeListener: function(callback) {
		this.removeListener(ChangeEvents.favoriteActors, callback);
	},

	/**
	 * @return {Promise<Object>}
	 */
	requestFavoriteActorIds: function() {
		return new Promise(resolve => {
			// already requested?
			if (favoriteActorsData.pinnedIds !== false) {
				resolve(favoriteActorsData.pinnedIds);
			} else {
				doFetch(Routes.getRoute(Routes.Names.GUEST_FAVORITE_ACTOR_IDS), null, Constants.HttpMethods.GET, true)
					.then(json => {
						favoriteActorsData.pinnedIds = json.data;
						resolve(favoriteActorsData.pinnedIds);
					});
			}
		});
	},

	/*
	 * Media
	 */

	addAlbumPinStatusListener: function(callback) {
		this.on(ChangeEvents.albumPinned, callback);
	},

	removeAlbumPinStatusListener: function(callback) {
		this.removeListener(ChangeEvents.albumPinned, callback);
	},

	addSedcardsAlbumPinStatusListener: function(callback) {
		this.on(ChangeEvents.sedcardsAlbumPinned, callback);
	},

	removeSedcardsAlbumPinStatusListener: function(callback) {
		this.removeListener(ChangeEvents.sedcardsAlbumPinned, callback);
	},

	addPicturePinStatusListener: function(callback) {
		this.on(ChangeEvents.picturePinned, callback);
	},

	removePicturePinStatusListener: function(callback) {
		this.removeListener(ChangeEvents.picturePinned, callback);
	},

});

// init
FavoriteStore.setMaxListeners(0);
NotificationStore.Favorite.addCommandListener(handleIncomingCommand);
if (VXConfig.guestIsLoggedIn) {
	NotificationStore.Favorite.requestActors();
}

export default FavoriteStore;
