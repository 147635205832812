/* global VXConfig */
import FluxEventEmitter        from '../FluxEventEmitter';
import assign                  from 'object-assign';
import {doFetch, urlWithQuery} from '../../utils/CommonUtils';
import Constants               from '../Constants';

const VXQL_URL          = VXConfig.vxqlUrl;
const VXQL_ACCESS_TOKEN = VXConfig.vxqlAccessToken;
const LANG              = VXConfig.language;

const CHANGE_EVENTS = {
	vote: 'vote',
};

/**
 *
 * @param {string} query
 * @param {Object} variables
 * @param {Object} queryParam
 * @returns {Promise}
 */
function doVxqlRequest(query, variables = null, queryParam = {}) {
	if (!VXQL_ACCESS_TOKEN) {
		return Promise.reject(new Error('Missing Vxql-Token'));
	}

	const queryObject = {};
	queryObject.query = query;

	if (variables) {
		queryObject.variables = variables;
	}

	const init = {
		headers: {'Authorization': 'Bearer ' + VXQL_ACCESS_TOKEN},
	};

	const requestUrl = urlWithQuery(VXQL_URL, {
		...queryParam,
		language: LANG,
	});

	return doFetch(requestUrl, queryObject, Constants.HttpMethods.POST, false, init);
}

const ClashOfCamsStore = assign({}, FluxEventEmitter.prototype, {

	getClashOfCamsData(clashId, callback) {
		getClashOfCamsData(clashId, callback);
	},
	addClashOfCamsVote(challengeId, teamId, callback) {
		addClashOfCamsVote(challengeId, teamId, callback);
	},
	removeClashOfCamsVote(challengeId, callback) {
		removeClashOfCamsVote(challengeId, callback);
	},
	addClashOfCamsVoteListener(callback) {
		this.on(CHANGE_EVENTS.vote, callback);
	},
	removeClashOfCamsVoteListener(callback) {
		this.removeListener(CHANGE_EVENTS.vote, callback);
	},
});

/**
 * @param {number} clashId
 * @param {Function} callback
 */
function getClashOfCamsData(clashId, callback) {
	const args = {id: clashId};
	const query = `
	query coc($id: Int!) {
		specialEvents {
            clashOfCams {
                clashById(id: $id) {
                    id
                    name
                    start
                    end
                    themeId
                    challenges {
                        id
                        name
                        type
                        start
                        end
                        clashId
                        title
                        subTitle
                        description
                        previewPicture
                        winnerAdmin
                        winnerVoting
                        winner
                        votable
                        media {
                            type
                            src
                            srcH5Live
                            previewPicture
                            mediaId
                            actorIds
                        }
                        moderator {
                            name
                            previewPicture
                            media {
                                src
                                srcH5Live
                                type
                            }
                        }
                        teams {
                            id
                            votes {
                                teamId
                                votes
                                hasVoted
                            }
                            media {
                                type
                                src
                                srcH5Live
                                actorIds
                                previewPicture
                            }
                        }
                    }
                    teams {
                        id
                        name
                        points
                        teamCaptain {
                            id
                            name
                            online
                            avatar{url(size: w320)}
                            linkVX(internal: true)
                            profile {
                                age
                            }
                        }
                        member {
                            id
                            name
                            avatar{url(size: w320)}
                            linkVX(internal: true)
                            profile {
                                age
                            }
                        }
                    }
                }
            }
        }
    }
	`;
	doVxqlRequest(query, args).then(callback);
}

/**
 * @param {int} challengeId
 * @param {int} teamId
 */
function addClashOfCamsVote(challengeId, teamId, callback) {
	ClashOfCamsStore.emit(CHANGE_EVENTS.vote, {challengeId, teamId});
	const args = {};
	const query = `
  mutation {
    guest {
      clashOfCams {
        addVote(challengeId: ${challengeId}, teamId: ${teamId})
      }
    }
  }
	`;
	doVxqlRequest(query, args).then(callback);
}

/**
 * @param {int} challengeId
 * @param {int} teamId
 */
function removeClashOfCamsVote(challengeId, callback) {
	ClashOfCamsStore.emit(CHANGE_EVENTS.vote, {challengeId});
	const args = {};
	const query = `
  mutation {
    guest {
      clashOfCams {
        revokeVote(challengeId: ${challengeId})
      }
    }
  }
	`;
	doVxqlRequest(query, args).then(callback);
}

export default ClashOfCamsStore;