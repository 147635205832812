'use strict';

import Dispatcher from './../Dispatcher';
import Constants  from './../Constants';

const ActionTypes = Constants.ActionTypes.ProSearch;

const ProSearchActionCreator = {
	changeSearchCondition: function(field, value, valueTranslation, checked, searchType, doSearch = true) {
		Dispatcher.dispatch({
			type: ActionTypes.CHANGE_SEARCH_CONDITION,
			field,
			value,
			valueTranslation,
			checked,
			searchType,
			doSearch,
		});
	},

	loadPage: function(page, searchType, append) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_PAGE,
			page,
			append,
			searchType,
		});
	},

	setCategory: function(category, page, searchType) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_CATEGORY,
			category,
			page,
			searchType,
		});
	},

	deleteFilter: function(searchType) {
		Dispatcher.dispatch({
			type: ActionTypes.DELETE_FILTER,
			searchType,
		});
	},

	changeMoodValue: function(moodValue, searchType) {
		Dispatcher.dispatch({
			type: ActionTypes.CHANGE_MOOD_VALUE,
			moodValue,
			searchType,
		});
	},

	/**
	 * @param {Number}   category
	 * @param {String}   searchType
	 * @param {Function} callBack
	 * @param {String}   sortType
	 */
	init: function(category, searchType, callBack, sortType) {
		Dispatcher.dispatch({
			type: ActionTypes.INIT,
			category,
			searchType,
			callBack,
			sortType,
		});
	},

	setActorOnlineState: function(actorId, state) {
		Dispatcher.dispatch({
			type: Constants.ActionTypes.SearchResult.SET_ACTOR_ONLINE_STATE,
			actorId,
			state,
		});
	},

	setActorMapState: function(state, searchType) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_ACTOR_MAP_STATE,
			state,
			searchType,
		});
	},

	changeSortType: function(sortType, searchType, doSearch = true) {
		Dispatcher.dispatch({
			type: ActionTypes.CHANGE_SORT_TYPE,
			sortType,
			searchType,
			doSearch,
		});
	},

	setSearchLocationByZip: function(zipCode) {
		Dispatcher.dispatch({
			type:    ActionTypes.SET_SEARCH_LOCATION_BY_ZIP,
			zipCode: zipCode,
		});
	},

	setLastSearchedZip: function(zipCode) {
		Dispatcher.dispatch({
			type:    ActionTypes.SET_LAST_SEARCHED_ZIP,
			zipCode: zipCode,
		});
	},

	setGuestGeoLocation: function(geoLocation) {
		Dispatcher.dispatch({
			type:        ActionTypes.SET_GUEST_GEO_LOCATION,
			geoLocation: geoLocation,
		});
	},

};

export default ProSearchActionCreator;
