import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import {doFetch}        from "../../utils/CommonUtils";
import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';

const SurveyActionTypes = Constants.ActionTypes.Survey;
const CHANGE_EVENTS     = {
	Survey:        'surveyChange',
	SurveyActive:  'surveyActiveChange',
	SurveyBlocked: 'surveyBlockedChange',
};

const _surveysActive = [];

const _surveysBlocked = [];

const _surveyData = {};

const SurveyStore = assign({}, FluxEventEmitter.prototype, {
	addSurveyChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.Survey, callback);
	},

	removeSurveyChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.Survey, callback);
	},

	addSurveyActiveChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.SurveyActive, callback);
	},

	removeSurveyActiveChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.SurveyActive, callback);
	},

	addSurveyBlockedChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.SurveyBlocked, callback);
	},

	removeSurveyBlockedChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.SurveyBlocked, callback);
	},

	getActiveSurveyType: function() {
		return _surveysActive.length > 0 ? _surveysActive[0] : null;
	},

	getSurveyData: function(type) {
		let data = null;
		if (typeof _surveyData[type] !== 'undefined') {
			data = _surveyData[type];
		}

		return data;
	},

	isSurveyBlocked: function(type) {
		return _surveysBlocked.indexOf(type) >= 0;
	},
});

function blockSurvey(type) {
	if (_surveysBlocked.indexOf(type) < 0) {
		_surveysBlocked.push(type);
		SurveyStore.emit(CHANGE_EVENTS.SurveyBlocked, type);
	}
}

function closeSurvey(type) {
	const pos = _surveysActive.indexOf(type);
	if (pos >= 0) {
		_surveysActive.splice(pos, 1);
		SurveyStore.emit(CHANGE_EVENTS.SurveyActive, type);
	}
}

function loadFeedbackData() {
	doFetch(Routes.getRoute(Routes.Names.SURVEY_FEEDBACK_DATA), null, Constants.HttpMethods.GET, true).then((result) => {
		if (result.success) {
			_surveyData[Constants.SurveyTypes.FEEDBACK] = result.data;
			SurveyStore.emit(CHANGE_EVENTS.Survey, Constants.SurveyTypes.FEEDBACK);
		}
	});
}

function openSurvey(type) {
	if (_surveysActive.indexOf(type) < 0) {
		_surveysActive.push(type);
		setTimeout(() => {
			SurveyStore.emit(CHANGE_EVENTS.SurveyActive, type);
		});
	}
}

function storeChatAnswer(chatId, actorId, rating, message, isMobile, isAnonymousVoicecall, chatType) {
	doFetch(Routes.getRoute(Routes.Names.SURVEY_CHAT_EXIT), {
		currentUrl:           window.location.href,
		chatId:               chatId,
		actorId:              actorId,
		mobile:               isMobile,
		feedback:             message,
		rating:               rating,
		isAnonymousVoicecall: isAnonymousVoicecall,
        chatType:             chatType,
	}, Constants.HttpMethods.POST, true).then((result) => {
		if (result.success && result.data.stored) {
			if (typeof _surveyData[Constants.SurveyTypes.CHAT_EXIT] === 'undefined') {
				_surveyData[Constants.SurveyTypes.CHAT_EXIT] = {};
			}
			_surveyData[Constants.SurveyTypes.CHAT_EXIT].stored = true;
			SurveyStore.emit(CHANGE_EVENTS.Survey, Constants.SurveyTypes.CHAT_EXIT);
		}
	});
}

function storeFeedbackAnswer(isMobile, feedback, email, rating) {
	doFetch(Routes.getRoute(Routes.Names.SURVEY_FEEDBACK), {
		currentUrl: window.location.href,
		mobile:     isMobile,
		feedback:   feedback,
		email:      email,
		rating:     rating,
	}, Constants.HttpMethods.POST, true).then((result) => {
		if (result.success) {
			if (typeof _surveyData[Constants.SurveyTypes.FEEDBACK] === 'undefined') {
				_surveyData[Constants.SurveyTypes.FEEDBACK] = {};
			}
			if (result.data.stored) {
				_surveyData[Constants.SurveyTypes.FEEDBACK].stored    = true;
				_surveyData[Constants.SurveyTypes.FEEDBACK].timeLimit = false;
			} else if (result.data.timeLimit) {
				_surveyData[Constants.SurveyTypes.FEEDBACK].stored    = false;
				_surveyData[Constants.SurveyTypes.FEEDBACK].timeLimit = true;
			}

			SurveyStore.emit(CHANGE_EVENTS.Survey, Constants.SurveyTypes.FEEDBACK);
		}
	});
}

SurveyStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case SurveyActionTypes.BLOCK_SURVEY:
			blockSurvey(action.surveyType);
			break;
		case SurveyActionTypes.CLOSE_SURVEY:
			closeSurvey(action.surveyType);
			break;
		case SurveyActionTypes.LOAD_FEEDBACK_DATA:
			loadFeedbackData();
			break;
		case SurveyActionTypes.OPEN_SURVEY:
			openSurvey(action.surveyType);
			break;
		case SurveyActionTypes.STORE_CHAT_RATING:
			storeChatAnswer(action.chatId, action.actorId, action.rating, action.message, action.isMobile, action.isAnonymousVoicecall, action.chatType);
			break;
		case SurveyActionTypes.STORE_FEEDBACK_ANSWER:
			storeFeedbackAnswer(action.isMobile, action.feedback, action.email, action.rating);
			break;
		default:
	}
});

export default SurveyStore;
