'use strict';

import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import {doFetch}        from "../../utils/CommonUtils";
import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
//import Flux             from '../Flux';

const ActionTypes  = Constants.ActionTypes.Contest;
const ChangeEvents = {
	Voting:          'voting',
	ContestHead:     'contesthead',
	ContestGrid:     'contestGrid',
	ShouldOpenMedia: 'ShouldOpenMedia',
	OpenAvsModal:    'OpenAvsModal'
};

const contestGridData                  = {};
const contestVotings                   = [];
const contestHeaderData                = [];
let contestShouldOpenMedia             = false;
let contestShouldOpenMediaConfig       = null;
let contestShouldOpenNextContestsModal = false;
let contestShouldOpenProfileVideo      = true;

function addContestMediaVoting(actorId, contestId, guestId, mediaId, albumId) {
	doFetch(Routes.getRoute(Routes.Names.CONTESTS_ADD), {
		actorId:   actorId,
		contestId: contestId,
		guestId:   guestId,
		mediaId:   mediaId,
		albumId:   albumId,
	}, Constants.HttpMethods.POST, true).then((data) => {
		contestVotings[data.mediaId] = data.mediaVotes;
		if (data.needAvs) {
			ContestStore.emit(ChangeEvents.OpenAvsModal, true);
		} else {
			ContestStore.emit(ChangeEvents.Voting, data.guestId, data.contestId, data.mediaId, true);
		}
	});
}

function deleteContestMediaVoting(actorId, contestId, guestId, mediaId, albumId) {
	doFetch(Routes.getRoute(Routes.Names.CONTESTS_DELETE), {
		actorId:   actorId,
		contestId: contestId,
		guestId:   guestId,
		mediaId:   mediaId,
		albumId:   albumId,
	}, Constants.HttpMethods.POST, true).then((data) => {
		contestVotings[data.mediaId] = data.mediaVotes;
		ContestStore.emit(ChangeEvents.Voting, data.guestId, data.contestId, data.mediaId, false);
	}
	);
}

function loadContestHeader(contestId, language) {
	if (typeof contestHeaderData[contestId] !== 'undefined') {
		ContestStore.emit(ChangeEvents.ContestHead, contestId);
	} else {

		doFetch(Routes.getRoute(Routes.Names.CONTESTS_GET_CONTEST_HEAD, {
			contestId: contestId,
			language:  language,
		}), {}, Constants.HttpMethods.GET, true).then((data) => {
			contestHeaderData[contestId] = data.data;
			ContestStore.emit(ChangeEvents.ContestHead, contestId);
		});
	}
}

function loadContestGridItems(contestId, contestArea, limit, offset, useCache, actorName) {
	if (useCache && typeof contestGridData[contestId] !== 'undefined' && contestGridData[contestId][contestArea] &&
		contestGridData[contestId][contestArea].contestsGridConfig && contestGridData[contestId][contestArea].contestsGridConfig.contestGridItems &&
		contestGridData[contestId][contestArea].contestsGridConfig.contestGridItems.length >= limit + offset) {
		ContestStore.emit(ChangeEvents.ContestGrid, contestId, contestArea);
	} else {
		doFetch(Routes.getRoute(Routes.Names.CONTESTS_GET_CONTEST_GRID_ITEMS, {
			contestId:  contestId,
			limit:      limit,
			contestSub: contestArea,
			offset:     offset,
		}), {actorName: actorName}, Constants.HttpMethods.GET, true).then((data) => {
			if (!contestGridData[contestId]) {
				contestGridData[contestId] = {};
			}
			if (!contestGridData[contestId][contestArea]) {
				contestGridData[contestId][contestArea] = {};
			}
			if (!contestGridData[contestId][contestArea].contestsGridConfig) {
				contestGridData[contestId][contestArea].contestsGridConfig = {};
			}

			let oldItems = [];

			if (contestGridData[contestId][contestArea].contestsGridConfig.contestGridItems) {
				oldItems = contestGridData[contestId][contestArea].contestsGridConfig.contestGridItems;
			}

			contestGridData[contestId][contestArea].contestsGridConfig.contestGridItems = arrayConcatUnique(oldItems, data.data.contestsGridItems);
			contestGridData[contestId][contestArea].contestsGridConfig.elementsCount    = data.data.elementsCount;
			contestGridData[contestId][contestArea].contestsGridConfig.guestId          = data.data.guestId;
			contestGridData[contestId][contestArea].id                                  = data.data.id;
			ContestStore.emit(ChangeEvents.ContestGrid, contestId, contestArea);
		});
	}
}

function setShouldOpenMedia(shouldOpen, config) {
	contestShouldOpenMedia       = shouldOpen;
	contestShouldOpenMediaConfig = config;

	ContestStore.emit(ChangeEvents.ShouldOpenMedia, shouldOpen, config);
}

function arrayConcatUnique(arr1, arr2) {
	const newArray = [...arr1];

	for (let i = 0; i < arr2.length; i++) {
		const el = arr2[i];

		const foundIndex = arr1.findIndex((element) => (el === element || el.itemHash && element.itemHash && el.itemHash === element.itemHash));

		if (foundIndex === -1) {
			newArray.push(el);
		}
	}

	return newArray;
}

function setShouldOpenNextContestsModal(shouldOpen) {
	contestShouldOpenNextContestsModal = shouldOpen;
}

function setShouldForwardToProfileVideo(shouldForwardToProfileVideo) {
	contestShouldOpenProfileVideo = shouldForwardToProfileVideo;
}

function setOpenAvsModal(shouldOpen) {
	ContestStore.emit(ChangeEvents.OpenAvsModal, shouldOpen);
}

const ContestStore = assign({}, FluxEventEmitter.prototype, {
	init: function(contestsGridConfig, contestId, contestArea) {
		if (contestsGridConfig && contestId && contestArea) {
			contestGridData[contestId]              = {};
			contestGridData[contestId][contestArea] = {contestsGridConfig, id: contestId};
			ContestStore.emit(ChangeEvents.ContestGrid, contestId, contestArea);
		}
	},

	addContestShouldOpenMediaChangeListener: function(callback) {
		this.on(ChangeEvents.ShouldOpenMedia, callback);
	},

	removeContestShouldOpenMediaChangeListener: function(callback) {
		this.removeListener(ChangeEvents.ShouldOpenMedia, callback);
	},

	addContestVotingChangeListener: function(callback) {
		this.on(ChangeEvents.Voting, callback);
	},

	removeContestVotingChangeListener: function(callback) {
		this.removeListener(ChangeEvents.Voting, callback);
	},

	addContestHeadChangeListener: function(callback) {
		this.on(ChangeEvents.ContestHead, callback);
	},

	removeContestHeadChangeListener: function(callback) {
		this.removeListener(ChangeEvents.ContestHead, callback);
	},

	addContestGridChangeListener: function(callback) {
		this.on(ChangeEvents.ContestGrid, callback);
	},

	removeContestGridChangeListener: function(callback) {
		this.removeListener(ChangeEvents.ContestGrid, callback);
	},

	addContestOpenAvsModalChangeListener: function(callback) {
		this.on(ChangeEvents.OpenAvsModal, callback);
	},

	removeContestOpenAvsModalChangeListener: function(callback) {
		this.removeListener(ChangeEvents.OpenAvsModal, callback);
	},

	getContestVoting: function(contestId) {
		return contestVotings[contestId];
	},

	getContestHeaderData: function(contestId) {
		return contestHeaderData[contestId];
	},

	getContestGridConfig: function(contestId, contestArea) {
		if (!contestGridData[contestId]) {
			contestGridData[contestId] = {};
		}
		return contestGridData[contestId][contestArea];
	},

	getShouldOpenMedia: function() {
		return contestShouldOpenMedia;
	},

	getShouldOpenMediaConfig: function() {
		return contestShouldOpenMediaConfig;
	},

	getShouldOpenNextContestsModal: function() {
		return contestShouldOpenNextContestsModal;
	},

	getShouldForwardToProfileVideo: function() {
		return contestShouldOpenProfileVideo;
	},

});

ContestStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.ADD_MEDIA_VOTING:
			addContestMediaVoting(action.actorId, action.contestId, action.guestId, action.mediaId, action.albumId);
			break;
		case ActionTypes.DELETE_MEDIA_VOTING:
			deleteContestMediaVoting(action.actorId, action.contestId, action.guestId, action.mediaId, action.albumId);
			break;
		case ActionTypes.LOAD_CONTEST_HEADER:
			loadContestHeader(action.contestId, action.language);
			break;
		case ActionTypes.LOAD_CONTEST_GRID_ITEMS:
			loadContestGridItems(action.contestId, action.contestArea, action.limit, action.offset, action.useCache, action.actorName);
			break;
		case ActionTypes.SET_SHOULD_OPEN_MEDIA:
			setShouldOpenMedia(action.shouldOpen, action.config);
			break;
		case ActionTypes.SET_SHOULD_FORWARD_TO_PROFILE_VIDEO:
			setShouldForwardToProfileVideo(action.shouldForwardToProfileVideo);
			break;
		case ActionTypes.SET_SHOULD_OPEN_NEXT_CONTESTS_MODAL:
			setShouldOpenNextContestsModal(action.shouldOpen);
			break;
		case ActionTypes.SET_SHOULD_OPEN_AVS_MODAL:
			setOpenAvsModal(action.shouldOpen);
			break;
		default:
	}
});

export default ContestStore;
