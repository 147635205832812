'use strict';

import Routes           from '../../utils/Routes';
import Flux             from '../Flux';
import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import Dictionary       from '../Dictionary';
import {doFetch}        from "../../utils/CommonUtils";


const ActionTypes        = Constants.ActionTypes.Messenger;
const ChannelFilterTypes = Constants.ChannelFilterTypes;

const ChangeEvents = {
	channel:               'channel',
	channels:              'channels',
	selected:              'selected',
	recommendedActors:     'recommendedActors',
	recommendedActorsGrid: 'recommendedActorsGrid',
	messages:              'messages',
	searchText:            'searchText',
	clearSearchText:       'clearSearchText',
};

let unreadMessagesCount    = null;
let savedDictionary        = null;
let recommendedActors      = [];
let recommendedActorsGrid  = null;
let selectedChannelId      = 0;
let maxMessageTimestamp    = 0;
let maxLastViewedTimestamp = 0;
const inSearchMode           = false;
let filteredBy             = ChannelFilterTypes.NEWEST;
let filterOnline           = false;
let navbarMessages         = [];
let restorePreviousUrl     = false;
let _searchText            = '';


const maxChannelsToShow = 5;

let channelsDictionary = new Dictionary();

function compareByMessageTimestamp(a, b) {
	if (a.messageTimestamp > b.messageTimestamp) {
		return -1;
	} else if (b.messageTimestamp > a.messageTimestamp) {
		return 1;
	} else if (a.id > b.id) {
		return -1;
	} else {
		return 1;
	}
}

function compareByLastViewedTimestamp(a, b) {
	if (a.lastViewedTimestamp > b.lastViewedTimestamp) {
		return -1;
	} else if (b.lastViewedTimestamp > a.lastViewedTimestamp) {
		return 1;
	} else if (a.id > b.id) {
		return -1;
	} else {
		return 1;
	}
}

function touchChannel(channel) {
	if (channel && channel.id) {
		channel.messageTimestamp    = maxMessageTimestamp = maxMessageTimestamp + 1;
		channel.lastViewedTimestamp = maxLastViewedTimestamp = maxLastViewedTimestamp + 1;
		channel.sentDiffForHumans   = '';
	}
}

function sortChannels(channelsTemp, channel) {
	channelsTemp.sort(function(a, b) {
		if (channel && a.id === channel.id) {
			return -10;
		} else if (channel && b.id === channel.id) {
			return 10;
		}

		// store max timestamp
		if (a.messageTimestamp > b.messageTimestamp) {
			maxMessageTimestamp = Math.max(maxMessageTimestamp, a.messageTimestamp);
		}

		if (filteredBy === ChannelFilterTypes.NEWEST || filteredBy === ChannelFilterTypes.ARCHIVED) {
			return compareByMessageTimestamp(a, b);
		} else if (filteredBy === ChannelFilterTypes.UNREAD) {
			if (a.unread && !b.unread) {
				return -1;
			} else if (b.unread && !a.unread) {
				return 1;
			} else {
				return compareByMessageTimestamp(a, b);
			}
		}
	});

	touchChannel(channel);
}

function sortLastViewedChannels(channelsTemp, channel) {
	channelsTemp.sort(function(a, b) {
		if (channel && a.id === channel.id) {
			return -10;
		} else if (channel && b.id === channel.id) {
			return 10;
		}

		// store max timestamp
		if (a.lastViewedTimestamp > b.lastViewedTimestamp) {
			maxLastViewedTimestamp = Math.max(maxLastViewedTimestamp, a.lastViewedTimestamp);
		}

		if (filteredBy === ChannelFilterTypes.ARCHIVED) {
			return compareByMessageTimestamp(a, b);
		} else if (filteredBy === ChannelFilterTypes.UNREAD) {
			if (a.unread && !b.unread) {
				return -1;
			} else if (b.unread && !a.unread) {
				return 1;
			} else {
				return compareByMessageTimestamp(a, b);
			}
		}

		return compareByLastViewedTimestamp(a, b);
	});

	touchChannel(channel);
}

function updateChannel(channelId, actorId, success) {
	let url;

	if (channelId) {
		url = Routes.getRoute(Routes.Names.MESSENGER_GET_CHANNEL,
			{channelId: channelId},
			{}
		);
	} else if (actorId > 0) {
		url = Routes.getRoute(Routes.Names.MESSENGER_GET_CHANNEL_BY_ACTOR,
			{actorId: actorId},
			{}
		);
	}

	if (url) {
		doFetch(url, null, Flux.Constants.HttpMethods.GET, true).then(success);
	}
}

function insertOrUpdateChannel(data) {
	channelsDictionary.add(data, data.id);
}

function setActorOnlineState(actorId, isOnline, data) {
	const channel = MessengerChannelsStore.getChannelByActor(actorId);

	if (channel) {
		channel.isActorOnline = isOnline;
		// update additional channel data
		if (data) {
			channel.multiChatPrice              = data.multiChatPrice;
			channel.canVoyeurChat               = data.canVoyeurChat;
			channel.isVoyeurInSingleChatAllowed = data.isVoyeurInSingleChatAllowed;
			channel.isDildocontrolAvailable     = data.isDildocontrolAvailable;
		}

		MessengerChannelsStore.emitChannelsChange();

		if (channel.id === selectedChannelId) {
			MessengerChannelsStore.emitChannelChange(selectedChannelId);
		}
	}

	if (!isOnline) {
		removeRecommendedActor(actorId);
	}
}

function setActorMessagePrice(channelId, freeMessagesAmount, messagePrice, isMessagingFree) {
	const channel = MessengerChannelsStore.getChannel(channelId);

	if (channel) {
		channel.freeMessagesAmount = freeMessagesAmount;
		channel.messagePrice       = messagePrice;
		channel.isMessagingFree    = isMessagingFree;

		MessengerChannelsStore.emitChannelsChange();

		if (channel.id === selectedChannelId) {
			MessengerChannelsStore.emitChannelChange(selectedChannelId);
		}
	}
}

function updateChannelToUnselect(success) {
	if (selectedChannelId) {
		const tmpSelectedChannelId = selectedChannelId;
		updateChannel(tmpSelectedChannelId, null, function(response) {
			const data = response.data;
			if (data) {
				const oldChannel   = MessengerChannelsStore.getChannel(data.id);
				data.subject       = data.subject || (oldChannel && oldChannel.subject);
				data.lastMessageId = data.lastMessageId || (oldChannel && oldChannel.lastMessageId);
				insertOrUpdateChannel(data);
				MessengerChannelsStore.emitChannelChange(data.id);
			} else {
				removeChannel(tmpSelectedChannelId);
			}
			success();
		});
	} else {
		setTimeout(() => success(), 1);
	}
}

function selectChannel(channelId, actorId, isRestored, shouldSelect = true) {
	// Always fetch a fresh channel instance from the server to get up to date pricing information
	updateChannel(channelId, actorId, function(response) {
		const data = response.data;

		if (data) {
			const oldChannel = MessengerChannelsStore.getChannel(data.id);
			// A newly selected channel means we could as well mark it as seen.
			setChannelRead(data, oldChannel);
			data.subject       = data.subject || (oldChannel && oldChannel.subject);
			data.lastMessageId = data.lastMessageId || (oldChannel && oldChannel.lastMessageId);

			let channel = {};
			if (oldChannel) {
				channel = {...oldChannel, ...data};
			} else {
				channel = data;
			}

			updateSelectedChannel(channel, isRestored, shouldSelect);
		} else if (channelId) {
			removeChannel(channelId);
			MessengerChannelsStore.emitChannelChange(channelId);
			MessengerChannelsStore.emitChannelsChange();
		}
	});
}

function setChannelRead(channel, oldChannel) {
	if (oldChannel) {
		unreadMessagesCount -= oldChannel.unreadCount;
	} else {
		unreadMessagesCount -= channel.unreadCount;
	}

	if (unreadMessagesCount < 0) {
		unreadMessagesCount = 0;
	}

	channel.unread      = false;
	channel.unreadCount = 0;
}

function updateSelectedChannel(channel, isRestored, shouldSelect = true) {
	insertOrUpdateChannel(channel);
	updateChannelToUnselect(function() {
		if (shouldSelect) {
			selectedChannelId = channel.id;
		}

		touchChannel(channelsDictionary.get(channel.id));
		MessengerChannelsStore.emitChannelsChange();

		if (shouldSelect) {
			MessengerChannelsStore.emitChannelSelected(!!isRestored);
		}
	});
	MessengerChannelsStore.emitChannelChange(channel.id);
}

function removeChannel(channelId) {
	channelsDictionary.remove(channelId);
}

function removeRecommendedActor(actorId) {
	for (let i = recommendedActors.length - 1; i >= 0; i--) {
		const current = recommendedActors[i];

		if (current && current.actorId === actorId) {
			recommendedActors.splice(i, 1);
			MessengerChannelsStore.emitRecommendedActorsChange();
			break;
		}
	}

	if (recommendedActorsGrid && recommendedActorsGrid.initialTiles) {
		const recommendedActorsTiles = recommendedActorsGrid.initialTiles;
		for (let i = recommendedActorsTiles.length - 1; i >= 0; i--) {
			const current = recommendedActorsTiles[i];

			if (current && current.actorId === actorId) {
				recommendedActorsTiles.splice(i, 1);
				MessengerChannelsStore.emitRecommendedActorsGridChange();
				break;
			}
		}
	}
}

function getRecommendedActors(currentActorId, useGrid, fsk) {

	doFetch(Routes.getRoute(Routes.Names.MESSENGER_GET_RECOMMENDATIONS, {}, {
		currentActorId: currentActorId,
		useGrid:        useGrid,
		fsk:            fsk,
	}), null, Constants.HttpMethods.GET, {cache: 'no-cache'})
		.then(function(response) {
			if (useGrid) {
				recommendedActorsGrid = response.data;
				MessengerChannelsStore.emitRecommendedActorsGridChange();
			} else {
				recommendedActors = response.data;
				MessengerChannelsStore.emitRecommendedActorsChange();
			}
		});
}

//NavbarMessages

function setAllNavbarMessagesRead() {
	for (let m = 0; m < navbarMessages.length; m++) {
		if (navbarMessages[m].unread) {
			navbarMessages[m].unread      = false;
			navbarMessages[m].unreadCount = 0;
		}
	}

	if (unreadMessagesCount > 0) {
		unreadMessagesCount = 0;
		MessengerChannelsStore.emitNavbarMessagesChange();
	}
}

function setRestorePreviousUrl(restore) {
	restorePreviousUrl = restore;
}

function setUnreadMessagesCount(unreadCount) {
	unreadMessagesCount = unreadCount;
}

function updateNavbarChannel(channelId) {
	const channel = MessengerChannelsStore.getChannel(channelId);
	if (channel && channel.id) {
		let shouldUpdate = true;

		for (let m = 0; m < navbarMessages.length; m++) {
			if (navbarMessages[m].channelId === channel.id) {
				if ((channel.lastMessageId && navbarMessages[m].id !== channel.lastMessageId) || (channel.sentDiffForHumans && navbarMessages[m].sentDiffForHumans !== channel.sentDiffForHumans) || (navbarMessages[m].unreadCount !== channel.unreadCount)) {
					navbarMessages.splice(m, 1);
					shouldUpdate = channel.unreadCount > 0;
				} else {
					shouldUpdate = false;
				}
			} else if (!channel.unread) {
				// don't prepend already read messages (mostly due to older channels being selected)
				shouldUpdate = false;
			}
		}

		if (shouldUpdate) {
			navbarMessages.unshift({
				id:                channel.lastMessageId,
				isActorOnline:     channel.isActorOnline,
				unread:            channel.unread,
				unreadCount:       channel.unreadCount,
				channelId:         channel.id,
				actorId:           channel.actorId,
				pictureUrl:        channel.previewPictureUrl,
				actorName:         channel.actorName,
				actorAge:          channel.actorAge,
				sentDiffForHumans: channel.sentDiffForHumans,
				subject:           channel.subject,
			});

			navbarMessages = navbarMessages.slice(0, maxChannelsToShow);

		}

		MessengerChannelsStore.emitNavbarMessagesChange();
	} else {
		for (let i = 0; i < navbarMessages.length; i++) {
			if (navbarMessages[i].channelId === channelId) {
				navbarMessages.splice(i, 1);
			}
		}
	}
}

function updateUnreadCount() {
	Flux.Vxql.getUnreadMessagesCount(handleUnreadMessagesCount);
}

function handleUnreadMessagesCount(result) {
	if (result.data.guest) {
		// handle success
		unreadMessagesCount = result.data.guest.messages.unreadCount;
		MessengerChannelsStore.emitNavbarMessagesChange();
	} else {
		// handle error
	}
}

function onChannelChange(channelId) {
	if (Flux.Guest.isLoggedIn()) {
		updateNavbarChannel(channelId);
		MessengerChannelsStore.emitNavbarMessagesChange();
	}
}

const MessengerChannelsStore = assign({}, FluxEventEmitter.prototype, {

	init: function(_channels, _navbarMessages, _filteredBy = ChannelFilterTypes.NEWEST, showOnline = false) {

		if (_navbarMessages) {
			navbarMessages = _navbarMessages;
		}

		Flux.Notification.addNotificationChangeListener(this.onNotificationChanged);
		Flux.Messenger.addChannelChangeListener(onChannelChange);


		if (_channels) {
			/* it can happen that the store will be initialized after a channel was selected due to deferred loading of init values.
			   in that case it's necessary to overwrite the channels array with the previously selected channel as it contains more information. */
			const selectedChannel = this.getSelectedChannel();
			if (selectedChannel) {
				for (let i = 0; i < _channels.length; i++) {
					if (_channels[i] && _channels[i].id === selectedChannel.id) {
						_channels[i] = selectedChannel;
						break;
					}
				}
			}

			if (unreadMessagesCount === null) {
				unreadMessagesCount = 0;
				if (Flux.Guest.isLoggedIn()) {
					updateUnreadCount();
				}
			}
			channelsDictionary.populate(_channels, 'id');
			MessengerChannelsStore.emitNavbarMessagesChange();
		}

		filteredBy   = _filteredBy;
		filterOnline = showOnline;
		this.emitChannelsChange();
	},

	addMessagesChangeListener: function(callback) {
		this.on(ChangeEvents.messages, callback);
	},

	removeMessagesChangeListener: function(callback) {
		this.removeListener(ChangeEvents.messages, callback);
	},

	addChannelChangeListener: function(callback) {
		this.on(ChangeEvents.channel, callback);
	},

	removeChannelChangeListener: function(callback) {
		this.removeListener(ChangeEvents.channel, callback);
	},

	addChannelsChangeListener: function(callback) {
		this.on(ChangeEvents.channels, callback);
	},

	removeChannelsChangeListener: function(callback) {
		this.removeListener(ChangeEvents.channels, callback);
	},

	addChannelSelectedListener: function(callback) {
		this.on(ChangeEvents.selected, callback);
	},

	removeChannelSelectedListener: function(callback) {
		this.removeListener(ChangeEvents.selected, callback);
	},

	addRecommendedActorsChangeListener: function(callback) {
		this.on(ChangeEvents.recommendedActors, callback);
	},

	removeRecommendedActorsChangeListener: function(callback) {
		this.removeListener(ChangeEvents.recommendedActors, callback);
	},

	addRecommendedActorsGridChangeListener: function(callback) {
		this.on(ChangeEvents.recommendedActorsGrid, callback);
	},

	removeRecommendedActorsGridChangeListener: function(callback) {
		this.removeListener(ChangeEvents.recommendedActorsGrid, callback);
	},

	emitChannelChange: function(channelId) {
		this.emit(ChangeEvents.channel, channelId);
	},

	emitChannelsChange: function() {
		this.emit(ChangeEvents.channels);
	},

	emitChannelSelected: function(isRestored) {
		this.emit(ChangeEvents.selected, isRestored);
	},

	emitRecommendedActorsChange: function() {
		this.emit(ChangeEvents.recommendedActors);
	},

	emitRecommendedActorsGridChange: function() {
		this.emit(ChangeEvents.recommendedActorsGrid);
	},

	emitNavbarMessagesChange: function() {
		this.emit(ChangeEvents.messages);
	},

	getNavbarMessages: function() {
		return navbarMessages;
	},

	getSelectedChannel: function() {
		return MessengerChannelsStore.getChannel(selectedChannelId);
	},

	getSelectedChannelId: function() {
		return selectedChannelId;
	},

	getChannel: function(channelId) {
		return channelsDictionary.get(channelId);
	},

	getChannelByActor: function(actorId) {
		return channelsDictionary.getBy('actorId', actorId);
	},

	getChannels: function() {
		return channelsDictionary.getSortedArray(function(channel) {
			if (!channel) {
				return null;
			}

			// filter criteria
			if (filteredBy === ChannelFilterTypes.ARCHIVED && !channel.archived) {
				return null;
			} else if (filteredBy !== ChannelFilterTypes.ARCHIVED && channel.archived) {
				return null;
			} else if (filteredBy === ChannelFilterTypes.UNREAD && !channel.unread) {
				return null;
			} else if (filteredBy === ChannelFilterTypes.BLOCKED && !channel.blocked) {
				return null;
			}

			if ([ChannelFilterTypes.ARCHIVED, ChannelFilterTypes.UNREAD].indexOf(filteredBy) === -1 && filterOnline && !channel.isActorOnline) {
				return null;
			}

			return channel;
		}, sortChannels);
	},

	getLastViewedChannels: function() {
		return channelsDictionary.getSortedArray(function(channel) {
			if (!channel) {
				return null;
			}

			// filter criteria
			if (filteredBy === ChannelFilterTypes.ARCHIVED && !channel.archived) {
				return null;
			} else if (filteredBy !== ChannelFilterTypes.ARCHIVED && channel.archived) {
				return null;
			} else if (filteredBy === ChannelFilterTypes.UNREAD && !channel.unread) {
				return null;
			} else if (filteredBy === ChannelFilterTypes.BLOCKED && !channel.blocked) {
				return null;
			}

			if ([ChannelFilterTypes.ARCHIVED, ChannelFilterTypes.UNREAD].indexOf(filteredBy) === -1 && filterOnline && !channel.isActorOnline) {
				return null;
			}

			return channel;
		}, sortLastViewedChannels);
	},

	channelsFilteredBy: function() {
		return filteredBy;
	},

	getRecommendedActors: function() {
		return recommendedActors.slice(0, 9);
	},

	getRecommendedActorsGrid: function() {
		return recommendedActorsGrid;
	},

	removeRecommendedActor: function(actorId) {
		removeRecommendedActor(actorId);
	},

	touchChannel: function(channel) {
		if (!channel || !channel.id) {
			return;
		}

		touchChannel(channel);

		MessengerChannelsStore.emitChannelsChange();
	},

	getUnreadMessagesCount: function() {
		return unreadMessagesCount;
	},

	isInSearchMode: function() {
		return inSearchMode;
	},

	isInOnlineMode: function() {
		return filterOnline;
	},

	isRestorePreviousUrl: function() {
		return restorePreviousUrl;
	},

	setSearchText(searchText) {
		_searchText = searchText;
		this.emit(ChangeEvents.searchText, _searchText);
	},

	addSearchTextChangeListener(callback) {
		this.on(ChangeEvents.searchText, callback);
	},

	removeSearchTextChangeListener(callback) {
		this.removeListener(ChangeEvents.searchText, callback);
	},

	addSearchTextClearListener(callback) {
		this.on(ChangeEvents.clearSearchText, callback);
	},

	removeSearchTextClearListener(callback) {
		this.removeListener(ChangeEvents.clearSearchText, callback);
	},

	clearSearchText() {
		_searchText = '';
		this.emit(ChangeEvents.searchText, _searchText);
		this.emit(ChangeEvents.clearSearchText);
	},

	onNotificationChanged: function(notification) {
		let channel             = null;
		const NotificationTypes = Constants.ApiTypes.Notification.Incoming;

		switch (notification.type) {
			case NotificationTypes.ACTOR_ONLINE :
				setActorOnlineState(notification.actorId, true, notification);
				break;
			case NotificationTypes.ACTOR_OFFLINE :
				setActorOnlineState(notification.actorId, false);
				break;
			case NotificationTypes.MESSAGE_PRICE :
				setActorMessagePrice(notification.channelId, notification.freeMessagesAmount, notification.messagePrice, notification.isMessagingFree);
				break;
			case NotificationTypes.CHANNEL :
				channel = notification.channel;

				Flux.Messenger.setUnreadMessagesCount(notification.unreadMessagesCount);
				if (channel) {
					insertOrUpdateChannel(channel);
					MessengerChannelsStore.emitChannelsChange();
					MessengerChannelsStore.emitChannelChange(channel.id);
				}
				break;
			case NotificationTypes.CHANNELS :
				if (notification.channels) {

					if (notification.filteredBy) {
						filteredBy = notification.filteredBy;
					} else if (inSearchMode) {
						if (!savedDictionary || savedDictionary.isEmpty()) {
							savedDictionary = channelsDictionary;
						}

						channelsDictionary = new Dictionary();
					}

					channelsDictionary.populate(notification.channels, 'id');

					channelsDictionary.each(function(channel) {
						maxMessageTimestamp    = Math.max(maxMessageTimestamp, channel.messageTimestamp);
						maxLastViewedTimestamp = Math.max(maxLastViewedTimestamp, channel.lastViewedTimestamp);
					});

					MessengerChannelsStore.emitChannelsChange();
				}
				break;
			case NotificationTypes.ALL_CHANNELS_SEEN :
				channelsDictionary.each(function(channel) {
					channel.unread = false;
				});
				if (savedDictionary) {
					savedDictionary.each(function(channel) {
						channel.unread = false;
					});
				}
				MessengerChannelsStore.emitChannelsChange();
				break;
			case NotificationTypes.CHANNEL_ARCHIVED :
				channel = MessengerChannelsStore.getChannel(notification.channelId);

				if (channel) {
					// dirty hack to overcome SyncChatStateWorker's clean up
					if (channel.id === selectedChannelId) {
						selectChannel(0, channel.actorId, false, false);
					} else {
						setChannelRead(channel);
						removeChannel(notification.channelId);
						MessengerChannelsStore.emitChannelsChange();
						MessengerChannelsStore.emitChannelChange(channel.id);
					}
				}

				break;
			case NotificationTypes.FREE_SHOW_START:
				channel = MessengerChannelsStore.getChannelByActor(notification.actorId);

				if (channel) {
					channel.freeChat = notification.data;

					MessengerChannelsStore.touchChannel(channel);
					MessengerChannelsStore.emitChannelChange(channel.id);
				}
				break;
			case NotificationTypes.FREE_SHOW_STOP:
				channel = MessengerChannelsStore.getChannelByActor(notification.actorId);

				if (channel) {
					channel.freeChat = null;

					MessengerChannelsStore.touchChannel(channel);
					MessengerChannelsStore.emitChannelChange(channel.id);
				}
				break;
			// no default
		}
	},

});

MessengerChannelsStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.SELECT_CHANNEL :
			selectChannel(action.channelId, parseInt(action.actorId), action.isRestored, action.shouldSelect);
			break;
		case ActionTypes.UNSELECT_CHANNEL :
			updateChannelToUnselect(function() {
				selectedChannelId = 0;
				MessengerChannelsStore.emitChannelsChange();
				MessengerChannelsStore.emitChannelSelected();
			});
			break;
		case ActionTypes.FILTER_ONLINE_CHANNELS :
			filterOnline = action.active;

			if (filterOnline && !inSearchMode) {
				setTimeout(function() {
					Flux.Messenger.loadOnlineChannels(true);
				}, 1);
			} else {
				if (!filterOnline) {
					setTimeout(function() {
						Flux.Messenger.loadOnlineChannels(false);
					}, 1);
				}
				MessengerChannelsStore.emitChannelsChange();
			}
			break;
		case ActionTypes.UPDATE_RECOMMENDED_ACTORS :
			getRecommendedActors(action.currentActorId, false, action.fsk);
			break;
		case ActionTypes.UPDATE_RECOMMENDED_ACTORS_GRID :
			getRecommendedActors(action.currentActorId, true);
			break;
		case ActionTypes.SET_ALL_MESSAGES_READ:
			setAllNavbarMessagesRead();
			break;
		case ActionTypes.SET_RESTORE_PREVIOUS_URL:
			setRestorePreviousUrl(action.restorePreviousUrl);
			break;
		case ActionTypes.SET_UNREAD_MESSAGES_COUNT:
			setUnreadMessagesCount(action.unreadCount);
			break;
		default:
	}
});

export default MessengerChannelsStore;
