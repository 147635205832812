'use strict';

const ChatExitCodes = {

	CLIENTCEXIT:        100,
	CLIENTLOGOUT:       101,
	CLIENTDESTRUCT:     102,
	CLIENTEXITWIN:      103,
	CLIENTUPGRADE:      104,
	CLIENTERROR:        105,
	CLIENTCHARGE:       106,
	CLIENTDISCONNECT:   107,
	CLIENTTIMEOUT:      108,
	CLIENTSTARTOFFLINE: 198,
	CLIENTSTARTTIMEOUT: 199,

	HOSTKICK:         201,
	HOSTSTOPSEND:     202,
	HOSTLOGOUT:       203,
	HOSTRELOGIN:      204,
	HOSTSENDFAIL:     205,
	HOSTDEVICEFAIL:   206,
	HOSTSTILLPICTURE: 207,
	HOSTCPUOVERLOAD:  208,

	ADMINKICK: 301,

	PREVIEW_LIMIT_HOST:    401,
	PREVIEW_LIMIT_HOUR:    402,
	PREVIEW_OFFLINE:       403,
	PREVIEW_SINGLE:        404,
	PREVIEW_FSK_DENIED:    405,
	PREVIEW_ACCESS_DENIED: 406,
	PREVIEW_DISABLED:      407,
	PREVIEW_GUEST_OFFLINE: 408,
	PREVIEW_START_OFFLINE: 498,
	PREVIEW_START_TIMEOUT: 499,

	CLIENT_EXIT_NOMONEY_LIMIT:   501,
	CLIENT_EXIT_TEXT_TIME_LIMIT: 502,
	CLIENT_EXIT_TIME_LIMIT:      503,

	FSKDENIED: 601,

	COMPATCLIENTLOGOUT: 901,
	CLIENTNEWCHAT:      902,
	VOICECALLHANGUP:    903,
	VOICECALLNEWCHAT:   904,
	HOSTSINGLE:         906,
	VIDEOFAILHOST:      907,
	VIDEOFAIL:          910,
	CLIENTLOCK:         920,
	HOSTLOCK:           921,
	TIMEOUT:            990,
	VIDEOTIMEOUTHOST:   996,
	VIDEOTIMEOUTGUEST:  997,
	LIVESHOWSTARTED:    908,
};

ChatExitCodes.isLivePreviewExitCode = function(code) {
	const livePreviewExitCodes = [
		ChatExitCodes.PREVIEW_ACCESS_DENIED,
		ChatExitCodes.PREVIEW_FSK_DENIED,
		ChatExitCodes.PREVIEW_LIMIT_HOST,
		ChatExitCodes.PREVIEW_LIMIT_HOUR,
		ChatExitCodes.PREVIEW_OFFLINE,
		ChatExitCodes.PREVIEW_SINGLE,
		ChatExitCodes.PREVIEW_DISABLED,
		ChatExitCodes.PREVIEW_GUEST_OFFLINE,
		ChatExitCodes.PREVIEW_START_OFFLINE,
		ChatExitCodes.PREVIEW_START_TIMEOUT,
	];

	return livePreviewExitCodes.indexOf(code) >= 0;
};

ChatExitCodes.isPreChatExitCode = function(code) {
	const preChatExitCodes = [
		ChatExitCodes.CLIENTCHARGE,
		ChatExitCodes.HOSTSINGLE,
		ChatExitCodes.FSKDENIED,
		ChatExitCodes.HOSTSENDFAIL,
		ChatExitCodes.HOSTDEVICEFAIL,
		ChatExitCodes.HOSTSTILLPICTURE,
		ChatExitCodes.HOSTCPUOVERLOAD,
		ChatExitCodes.VIDEOFAIL,
		ChatExitCodes.VIDEOFAILHOST,
		ChatExitCodes.CLIENTSTARTOFFLINE,
		ChatExitCodes.CLIENTSTARTTIMEOUT,
		ChatExitCodes.HOSTKICK,
		ChatExitCodes.HOSTSTOPSEND,
		ChatExitCodes.HOSTLOGOUT,
		ChatExitCodes.HOSTRELOGIN,
	];

	return preChatExitCodes.indexOf(code) >= 0;
};


export default ChatExitCodes;