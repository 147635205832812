import assign           from "object-assign";
import FluxEventEmitter from "../FluxEventEmitter";
import Flux             from "../Flux";

const VideoStarsStore = assign({}, FluxEventEmitter.prototype, {

	getOrLoadVideoStars(first = 100, callback) {
		const result = Flux.Vxql.getVideoStars(first);
		result.then(function(res) {
			if (res.data.videoStars) {
				callback(res.data.videoStars);
			}
		});
	},
});

export default VideoStarsStore;