'use strict';

import {EventEmitter} from 'events';
import assign         from 'object-assign';
import Routes         from '../../utils/Routes';
import Constants      from "../Constants";
import Dispatcher     from '../Dispatcher';
import {doFetch}      from "../../utils/CommonUtils";


const TVActionTypes = Constants.ActionTypes.TV;
const ChangeEvents  = {
	actorChanged:  'actorChanged',
	streamChanged: 'streamChanged',
};

let currentHosts           = [];
let storedHosts            = [];
let currentActors          = [];
let storedActors           = [];
const liveStreamInfoUrl    = 'https://tv-playout.visit-x.tv/items/current.json';
const premiumStreamInfoUrl = 'https://tv-playout.visit-x.tv/items/current-premium.json';
let infoUrl                = '';
const liveStreamData       = {};
const premiumStreamData    = {};

const TVLivestreamStore = assign({}, EventEmitter.prototype, {

	init: function(isPremium) {

		if (isPremium === false) {
			infoUrl = liveStreamInfoUrl;
		} else {
			infoUrl = premiumStreamInfoUrl;
		}
	},

	emitActorChange: function() {
		this.emit(ChangeEvents.actorChanged);
	},

	addActorChangeListener: function(callback) {
		this.on(ChangeEvents.actorChanged, callback);
	},

	removeActorChangeListener: function(callback) {
		this.removeListener(ChangeEvents.actorChanged, callback);
	},

	emitStreamChange: function(isPremium) {
		this.emit(ChangeEvents.streamChanged, isPremium);
	},

	addStreamChangeListener: function(callback) {
		this.on(ChangeEvents.streamChanged, callback);
	},

	removeStreamChangeListener: function(callback) {
		this.removeListener(ChangeEvents.streamChanged, callback);
	},

	getCurrentActors: function() {
		return currentActors;
	},

	getStreamData(isPremium) {
		if (isPremium) {
			return premiumStreamData;
		} else {
			return liveStreamData;
		}
	},

	hasStreamData(isPremium) {
		const data = this.getStreamData(isPremium);
		return data && data.data && data.data.streamUrl;
	},
});

function updateCurrentActors() {
	doFetch(infoUrl, null, Constants.HttpMethods.GET, false, {}, false, false)
		.then(function(data) {
			if (typeof data.hosts === 'object' && data.hosts !== null) {
				currentHosts = data.hosts;
			}

			if (JSON.stringify(currentHosts) !== JSON.stringify(storedHosts)) {
				if (currentHosts && currentHosts.length > 0) {
					currentActors = [];
					currentHosts.forEach(
						function(value) {
							doFetch(Routes.getRoute(Routes.Names.ACTOR_TV_LIVESTREAM_DATA_BY_ID, {actorId: value.uid}), null, Constants.HttpMethods.GET, true)
								.then(function(res) {
									currentActors.push(res);
									storedActors = currentActors;
									storedHosts  = currentHosts;

									TVLivestreamStore.emitActorChange();
								});
						}
					);
				}
			} else {
				currentActors = storedActors;
			}

			currentActors.sort(function(a, b) {
				return parseInt(a.id) - parseInt(b.id);
			});
		});
}

function loadProgramInfo(isPremium, limit) {
	doFetch(Routes.getRoute(Routes.Names.TV_PROGRAM_DATA, {'type': isPremium ? 'premium' : 'live'}), {limit: limit}, Constants.HttpMethods.GET, true).then((response) => {
		if (response.data) {
			if (isPremium) {
				premiumStreamData.program = response.data;
			} else {
				liveStreamData.program = response.data;
			}

			TVLivestreamStore.emitStreamChange(isPremium);
		}
	});
}

function loadStreamInfo(isPremium) {
	doFetch(Routes.getRoute(Routes.Names.TV_STREAM_DATA, {'type': isPremium ? 'premium' : 'live'}), null, Constants.HttpMethods.GET, true).then((response) => {
		if (response.data) {
			if (isPremium) {
				premiumStreamData.data = response.data;

				if (!premiumStreamData.program) {
					premiumStreamData.program = response.program;
				}
			} else {
				liveStreamData.data = response.data;

				if (!liveStreamData.program) {
					liveStreamData.program = response.program;
				}
			}

			TVLivestreamStore.emitStreamChange(isPremium);
		}
	});
}

TVLivestreamStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case TVActionTypes.CHECK_ACTORS:
			updateCurrentActors();
			break;
		case TVActionTypes.LOAD_PROGRAM_INFO:
			loadProgramInfo(action.premium, action.limit);
			break;
		case TVActionTypes.LOAD_STREAM_INFO:
			loadStreamInfo(action.premium);
			break;
		default:
	}
});

export default TVLivestreamStore;
